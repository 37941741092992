import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getUserStakings } from "../../services/apiStaking";
import { GetStakingListResponse } from "../../types/requestTypes";
import { StakingItem } from "../../types/stakingTypes";

export interface UserStakingState {
  stakingList: StakingItem[] | null;
  listFetching: boolean;
  totalPages: number;
}

const initialState: UserStakingState = {
  stakingList: null,
  listFetching: false,
  totalPages: 1,
};

export const getStakingList = createAsyncThunk(
  "userStaking/loadList",
  async (page?: number) => {
    const response = await getUserStakings(page);
    const { data } = response as GetStakingListResponse;
    return data;
  }
);

export const userStaking = createSlice({
  name: "userStaking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getStakingList.fulfilled,
      (
        state: UserStakingState,
        action: PayloadAction<{
          data: StakingItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.stakingList = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.listFetching = false;
      }
    );
    builder.addCase(getStakingList.pending, (state: UserStakingState) => {
      state.listFetching = true;
    });
  },
});

export default userStaking.reducer;
