import ApiClient from "./ApiClient";
import {
  RequestError,
  DefaultSuccessResponse,
  GetTransactionsListResponse,
} from "../types/requestTypes";
import { TransactionStatus } from "../types/transactionsTypes";

export const getUserTransctionsList = async (
  page?: number
): Promise<GetTransactionsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetTransactionsListResponse>(
      `/transactions?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getAdminTransctionsList = async (
  page?: number
): Promise<GetTransactionsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetTransactionsListResponse>(
      `/admin/transactions?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const confirmTransactions = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>(
      `/admin/transactions/${id}/set-status`,
      {
        status: TransactionStatus.paid,
      }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteTransactions = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<DefaultSuccessResponse>(
      `/admin/transactions/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const topUpBalance = async (
  method: string,
  amount: string,
  hash: string,
  img: File
): Promise<DefaultSuccessResponse | RequestError> => {
  const formData = new FormData();
  formData.append("image", img, img.name as string);
  formData.append("method", method);
  formData.append("amount", amount);
  formData.append("hash", hash);
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>(
      "/transactions",
      formData
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const setTransactionAmount = async (
  id: number,
  amount: string
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>(
      `/admin/transactions/${id}/set-amount`,
      {
        final_amount: amount,
      }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
