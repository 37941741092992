import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import uuid from "react-uuid";

import { CoinInfo } from "../../types/coinsType";
import { GetAdminCoinParamsResponse } from "../../types/requestTypes";
import { getCoinParams } from "../../services/apiAdminCoins";

import CoinsParamsForm from "../forms/CoinParamsForm";

interface Props {
  show: boolean;
  name: string;
  coinId: number;
  onHide: () => void;
}

const CoinsParamsModal: React.FC<Props> = ({ show, onHide, name, coinId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<CoinInfo[]>([] as CoinInfo[]);

  const loadParamsHandler = async (): Promise<void> => {
    setIsLoading(true);
    const result = await getCoinParams(coinId);
    if (result.status !== "error") {
      const { data } = result as GetAdminCoinParamsResponse;
      setFormData(data.parameters as CoinInfo[]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (show && coinId) {
      loadParamsHandler();
    }
    // eslint-disable-next-line
  }, [show, coinId]);

  const addNewParam = (): void => {
    setFormData([{ isNew: true, id: uuid() } as CoinInfo, ...formData]);
  };
  const removeParam = (id: number | string): void => {
    const result = formData.filter((el) => el.id !== id);
    setFormData(result);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="params-modal"
    >
      {isLoading ? (
        <Oval
          height={60}
          width={60}
          color="#f1a619"
          visible
          ariaLabel="oval-loading"
          secondaryColor="#ffffff"
          strokeWidth={2}
          strokeWidthSecondary={2}
          wrapperClass="global-loader"
        />
      ) : (
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Параметры монеты
            </Modal.Title>
            <div className="close-btn" onClick={onHide}>
              X
            </div>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-3">
              <b>{name}</b>
            </p>
            <button className="default-btn" type="button" onClick={addNewParam}>
              Добавить параметр
            </button>
            {formData.map((el) => (
              <CoinsParamsForm
                key={el.id}
                id={el.id}
                data={el}
                removeItem={removeParam}
                coinId={coinId}
                loadParams={loadParamsHandler}
              />
            ))}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default CoinsParamsModal;
