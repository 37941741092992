import { RequestError } from '../types/requestTypes';
import ApiClient from './ApiClient';

export const sendRequestFormData = async (
    data: FormData
  ): Promise<any> => {
    try {
      const result = await ApiClient.publicPost(
        '/request-form',
        data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return result;
    } catch (err) {
      return err as RequestError;
    }
  };