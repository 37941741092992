import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getUsersList } from "../../services/apiAdminUsers";
import { UserInfo } from "../../types/userDataTypes";
import { GetUsersListResponse } from "../../types/requestTypes";

export interface UsersListState {
  users: UserInfo[] | null;
  usersFetching: boolean;
  totalPages: number;
}

export interface GetRequestArgs {
  page?: number;
  search?: string;
}

const initialState: UsersListState = {
  users: null,
  usersFetching: false,
  totalPages: 1,
};

export const getUsersFunc = createAsyncThunk(
  "adminUsersList/loadList",
  async (args?: GetRequestArgs) => {
    const response = await getUsersList(args?.page, args?.search);
    const { data } = response as GetUsersListResponse;
    return data;
  }
);

export const adminUsersList = createSlice({
  name: "adminUsersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getUsersFunc.fulfilled,
      (
        state: UsersListState,
        action: PayloadAction<{
          data: UserInfo[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.users = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.usersFetching = false;
      }
    );
    builder.addCase(getUsersFunc.pending, (state: UsersListState) => {
      state.usersFetching = true;
    });
  },
});

export default adminUsersList.reducer;
