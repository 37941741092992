import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../../types/userDataTypes";
import ApiClient from "../../services/ApiClient";

export interface UserDataState {
  isLogged: boolean;
  userInfo: UserInfo;
  userToken: string;
}

const initialState: UserDataState = {
  isLogged: true,
  userInfo: {
    first_name: "",
    last_name: "",
    surname: "",
    email: "",
    phone_number: "",
    country: "",
  },
  userToken: "",
};

export const userData = createSlice({
  name: "user",
  initialState,
  reducers: {
    loggedAction: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
      localStorage.setItem("isLogged", JSON.stringify(action.payload));
    },
    setUserData: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
      localStorage.setItem("token", action.payload);
      ApiClient.setToken(action.payload);
    },
  },
});

export const { loggedAction, setUserData, setUserToken } = userData.actions;

export default userData.reducer;
