import React, { useState } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../../store/store";
import { getCoinsFunc } from "../../store/slices/adminCoins";
import { CoinItem } from "../../types/coinsType";
import { changeCoinStatus } from "../../services/apiAdminCoins";
import { RequestError } from "../../types/requestTypes";
import { handleNotifError } from "../../helpers/errorHandlers";

import DeleteCoinModal from "../modals/DeleteCoinModal";
import CoinsParamsModal from "../modals/CoinsParamsModal";

enum Statuses {
  active = "active",
  hidden = "hidden",
}

interface Props {
  showCoinModal: (e: boolean, data?: CoinItem) => void;
  activePage: number;
  setActivePage: (e: number) => void;
}

const AdminCoinsTab: React.FC<Props> = ({
  showCoinModal,
  activePage,
  setActivePage,
}) => {
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [paramsModalShow, setParamsModalShow] = useState<boolean>(false);
  const [coinName, setCoinName] = useState<string>("");
  const [coinId, setCoinId] = useState<number>();
  const [isStatusLoading, setStatusLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const coinsList = useSelector((state: RootState) => state.adminCoins.coins);
  const totalPages = useSelector(
    (state: RootState) => state.adminCoins.totalPages
  );
  const isLoading = useSelector(
    (state: RootState) => state.adminCoins.coinsFetching
  );

  const showDeleteModal = (id: number, name: string): void => {
    setDeleteModalShow(true);
    setCoinId(id);
    setCoinName(name);
  };
  const hideDeleteModal = (): void => {
    setDeleteModalShow(false);
    setCoinName("");
    setCoinId(undefined);
  };
  const showParamsModal = (name: string, id: number): void => {
    setParamsModalShow(true);
    setCoinName(name);
    setCoinId(id);
  };
  const hideParamsModal = (): void => {
    setParamsModalShow(false);
    setCoinName("");
    setCoinId(undefined);
  };

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getCoinsFunc(e + 1));
  };

  const handleSelectChange = async (id: number): Promise<void> => {
    setStatusLoading(true);
    const result = await changeCoinStatus(id);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
      setStatusLoading(false);
    } else {
      setStatusLoading(false);
    }
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Имя</b>
            </th>
            <th>
              <b>Символ</b>
            </th>
            <th>
              <b>Изображение</b>
            </th>
            <th>
              <b>Описание</b>
            </th>
            <th>
              <b>Статус</b>
            </th>
            <th>
              <b>Параметры</b>
            </th>
            {/* eslint-disable-next-line */}
          <th className="admin-table-btns" />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            coinsList &&
            coinsList?.map((el) => (
              <tr key={el.symbol}>
                <td>{el.id || ""}</td>
                <td>{el.name}</td>
                <td>{el.symbol}</td>
                <td>
                  <img
                    src={`${process.env.REACT_APP_IMG_URL as string}${
                      (el.image as string) || ""
                    }`}
                    alt="img"
                    width={40}
                  />
                </td>
                <td>{el.description}</td>
                <td>
                  <Form.Select
                    defaultValue={
                      el?.active && el?.active === 1
                        ? Statuses.active
                        : Statuses.hidden
                    }
                    size="sm"
                    disabled={isStatusLoading}
                    onChange={(): void => {
                      handleSelectChange((el?.id as number) || 0);
                    }}
                  >
                    <option value={Statuses.active}>Активна</option>
                    <option value={Statuses.hidden}>Скрыта</option>
                  </Form.Select>
                </td>
                <td>
                  <Button
                    variant="success"
                    onClick={(): void =>
                      showParamsModal(el.name, (el.id as number) || 0)
                    }
                  >
                    Показать параметры
                  </Button>
                </td>
                <td>
                  <Button
                    variant="warning"
                    onClick={(): void => showCoinModal(true, el)}
                  >
                    Редактировать
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={(): void => {
                      showDeleteModal((el?.id as number) || 0, el.name);
                    }}
                  >
                    Удалить
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      <DeleteCoinModal
        show={deleteModalShow}
        onHide={hideDeleteModal}
        name={coinName}
        id={coinId || 0}
        setActivePage={setActivePage}
      />
      <CoinsParamsModal
        show={paramsModalShow}
        onHide={hideParamsModal}
        name={coinName}
        coinId={coinId || 0}
      />
    </>
  );
};

export default AdminCoinsTab;
