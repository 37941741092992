import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import type { RootState, AppDispatch } from "../store/store";
import { getUsersFunc } from "../store/slices/adminUsersList";
import { getCoinsFunc } from "../store/slices/adminCoins";
import { getPaymentsFunc } from "../store/slices/adminPayments";
import { getTransactionsList } from "../store/slices/adminTransactions";
import { getStakingList } from "../store/slices/adminSteaking";
import { getPayoutsListFunc } from "../store/slices/adminPayouts";
import { getCallsFunc } from "../store/slices/adminCalls";
import { UserInfo, UserRoles } from "../types/userDataTypes";
import { CoinItem } from "../types/coinsType";

import UserModal from "../components/modals/UserModal";
import PaymentModal from "../components/modals/PaymentModal";
import CoinsModal from "../components/modals/CoinsModal";
import UsersTab from "../components/adminTabs/UsersTab";
import AdminTransactionsTab from "../components/adminTabs/AdminTransactionsTab";
import AdminCoinsTab from "../components/adminTabs/AdminCoinsTab";
import AdminStakingTab from "../components/adminTabs/AdminStakingTab";
import AdminPaymentTab from "../components/adminTabs/AdminPaymentTab";
import AdminPayoutsTab from "../components/adminTabs/AdminPayoutsTab";
import AdminCallsTab from "../components/adminTabs/AdminCallsTab";

enum TabsEnum {
  users = "users",
  transactions = "transactions",
  payouts = "payouts",
  coins = "coins",
  staking = "staking",
  payment = "payment",
  calls = "calls",
}

const AdminPanel: React.FC = () => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalPaymentShow, setModalPaymentShow] = useState<boolean>(false);
  const [modalData, setModalData] = useState<UserInfo>();
  const [modalCoinsShow, setModalCoinsShow] = useState<boolean>(false);
  const [modalCoinsData, setModalCoinsData] = useState<CoinItem>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCoinsEdit, setIsCoinsEdit] = useState<boolean>(false);
  const [activeUserPage, setActiveUserPage] = useState<number>(0);
  const [activeCoinPage, setActiveCoinPage] = useState<number>(0);
  const [activePaymentPage, setActivePaymentPage] = useState<number>(0);
  const [activeCallsPage, setActiveCallsPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const usersList = useSelector((state: RootState) => state.userList.users);
  const payoutsList = useSelector(
    (state: RootState) => state.adminPayouts.payoutsList
  );
  const coinsList = useSelector((state: RootState) => state.adminCoins.coins);
  const callsList = useSelector((state: RootState) => state.adminCalls.calls);
  const paymnetsList = useSelector(
    (state: RootState) => state.adminPayments.paymnetsList
  );
  const transactionsList = useSelector(
    (state: RootState) => state.adminTransactions.transactionsList
  );
  const stakingList = useSelector(
    (state: RootState) => state.adminSteaking.stakingList
  );
  const userRole = useSelector((state: RootState) => state.user.userInfo?.role);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userRole || userRole !== UserRoles.admin) {
      navigate("/");
    }
  }, [userRole, navigate]);

  const showModal = (e: boolean, data?: UserInfo): void => {
    setModalShow(true);
    setIsEdit(e);
    setModalData(data);
  };
  const hideModal = (): void => {
    setModalShow(false);
    setModalData(undefined);
  };

  const showCoinsModal = (e: boolean, data?: CoinItem): void => {
    setModalCoinsShow(true);
    setIsCoinsEdit(e);
    setModalCoinsData(data);
  };
  const hideCoinsModal = (): void => {
    setModalCoinsShow(false);
    setModalCoinsData(undefined);
  };

  const showPaymentModal = (): void => {
    setModalPaymentShow(true);
  };
  const hidePaymentModal = (): void => {
    setModalPaymentShow(false);
  };

  const tabsChangeHandler = (tab: string | null): void => {
    if (tab === TabsEnum.coins && !coinsList) {
      dispatch(getCoinsFunc());
    }
    if (tab === TabsEnum.staking && !stakingList) {
      dispatch(getStakingList());
    }
    if (tab === TabsEnum.transactions && !transactionsList) {
      dispatch(getTransactionsList());
    }
    if (tab === TabsEnum.payment && !paymnetsList) {
      dispatch(getPaymentsFunc());
    }
    if (tab === TabsEnum.payouts && !payoutsList) {
      dispatch(getPayoutsListFunc());
    }
    if (tab === TabsEnum.calls && !callsList) {
      dispatch(getCallsFunc());
    }
  };
  useEffect(() => {
    if (!usersList) {
      dispatch(getUsersFunc());
    }
  }, [usersList, dispatch]);

  const handleUsersPage = (e: number): void => {
    setActiveUserPage(e);
    dispatch(getUsersFunc({ page: e + 1 }));
  };
  const handleCoinPage = (e: number): void => {
    setActiveCoinPage(e);
    dispatch(getCoinsFunc(e + 1));
  };
  const handlePaymentPage = (e: number): void => {
    setActivePaymentPage(e);
    dispatch(getPaymentsFunc(e + 1));
  };
  const handleCallsPage = (e: number): void => {
    setActiveCallsPage(e);
    dispatch(getCallsFunc(e + 1));
  };

  const clearSearch = (): void => {
    setSearchValue("");
    dispatch(getUsersFunc());
    setActiveUserPage(0);
  };
  const handleSearch = (): void => {
    dispatch(getUsersFunc({ page: 0, search: searchValue }));
    setActiveUserPage(0);
  };

  return (
    <section className="admin-section">
      <div className="container">
        <h1>Панель администратора</h1>
        <Tabs
          defaultActiveKey={TabsEnum.users}
          className="mt-4"
          onSelect={(e): void => tabsChangeHandler(e)}
        >
          <Tab eventKey={TabsEnum.users} title="Пользователи">
            <button
              className="default-btn mt-3 mb-3"
              type="button"
              onClick={(): void => showModal(false)}
            >
              Добавить пользователя
            </button>
            <UsersTab
              showUserModal={showModal}
              activePage={activeUserPage}
              setActivePage={handleUsersPage}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              clearSearch={clearSearch}
              handleSearch={handleSearch}
            />
          </Tab>
          <Tab eventKey={TabsEnum.transactions} title="Транзакции">
            <AdminTransactionsTab />
          </Tab>
          <Tab eventKey={TabsEnum.payouts} title="Выводы">
            <AdminPayoutsTab />
          </Tab>
          <Tab eventKey={TabsEnum.coins} title="Монеты">
            <button
              className="default-btn mt-3 mb-3"
              type="button"
              onClick={(): void => showCoinsModal(false)}
            >
              Добавить монету
            </button>
            <AdminCoinsTab
              showCoinModal={showCoinsModal}
              activePage={activeCoinPage}
              setActivePage={handleCoinPage}
            />
          </Tab>
          <Tab eventKey={TabsEnum.staking} title="Стейкинг">
            <AdminStakingTab />
          </Tab>
          <Tab eventKey={TabsEnum.payment} title="Методы оплаты">
            <button
              className="default-btn mt-3 mb-3"
              type="button"
              onClick={showPaymentModal}
            >
              Добавить метод оплаты
            </button>
            <AdminPaymentTab
              activePage={activePaymentPage}
              setActivePage={handlePaymentPage}
            />
          </Tab>
          <Tab eventKey={TabsEnum.calls} title="Обратная связь">
            <AdminCallsTab
              activePage={activeCallsPage}
              setActivePage={handleCallsPage}
            />
          </Tab>
        </Tabs>
      </div>
      <UserModal
        show={modalShow}
        isEdit={isEdit}
        onHide={hideModal}
        data={modalData}
        activePage={activeUserPage}
      />
      <CoinsModal
        show={modalCoinsShow}
        isEdit={isCoinsEdit}
        onHide={hideCoinsModal}
        activePage={activeCoinPage}
        data={modalCoinsData}
      />
      <PaymentModal
        show={modalPaymentShow}
        onHide={hidePaymentModal}
        activePage={activePaymentPage}
      />
    </section>
  );
};

export default AdminPanel;
