// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./Components.scss";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./Pages.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html {\n  overflow-y: auto !important;\n}\n\nbody {\n  max-height: 100vh;\n  overflow-y: auto !important;\n}\nbody.modal-open {\n  padding: 0 !important;\n  overflow-y: hidden !important;\n  overflow-x: hidden !important;\n}\n\n.container {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.boxed {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.iconbox-content {\n  min-height: 150px;\n}", "",{"version":3,"sources":["webpack://./src/styles/App.scss"],"names":[],"mappings":"AAGA;EACE,2BAAA;AAAF;;AAEA;EACE,iBAAA;EACA,2BAAA;AACF;AAAE;EACE,qBAAA;EACA,6BAAA;EACA,6BAAA;AAEJ;;AACA;EACE,iBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAGF;;AADA;EACE,iBAAA;AAIF","sourcesContent":["@import url(\"./Components.scss\");\n@import url(\"./Pages.scss\");\n\nhtml {\n  overflow-y: auto !important;\n}\nbody {\n  max-height: 100vh;\n  overflow-y: auto !important;\n  &.modal-open {\n    padding: 0 !important;\n    overflow-y: hidden !important;\n    overflow-x: hidden !important;\n  }\n}\n.container {\n  margin-left: auto;\n  margin-right: auto;\n}\n.boxed {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.iconbox-content {\n  min-height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
