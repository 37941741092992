import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

const TermsForUse: React.FC = () => {
  const navigate = useNavigate();
  const [isAgree, setAgreeStatus] = useState<null | boolean>(false);

  const handleSubmit = (): void => {
    navigate("/request-form");
    document.body.scrollTo(0, 0);
  };

  return (
    <div className="terms-for-use-wrapper">
      <div className="terms-for-use-content">
        <div className="breadcrumbs">
          <NavLink className="link-selected" to="/altcoins-hub" end>
            AltcoinsHub Legal
          </NavLink>

          <span>{">"}</span>

          <span>Условия использования AltcoinsHub</span>
        </div>

        <h1 className="title">
          Портал судебных постановлений AltcoinsHub
          <br />
          (для постановлений о замораживании и раскрытии информации)
        </h1>

        <p>
          Используйте эту страницу, только если у вас есть постановление суда
        </p>

        <h2 className="sub-title">
          Постановления о замораживании и раскрытии информации
        </h2>

        <p>
          Приветствуем вас на портале AltcoinsHub для судебных постановлений о
          замораживании и раскрытии информации. Законные представители могут
          использовать этот портал для подачи распоряжений о замораживании и
          раскрытии информации, касающихся учетных записей пользователей
          криптовалютной биржи AltcoinsHub.com. Пожалуйста, убедитесь, что ваше
          постановление относится к пользователю данной платформы, а не к
          отдельной бирже, такой как AltcoinsHub.US, или AltcoinsHub.TR (и
          т.д.).
          <br />
          <br />
          Пожалуйста, заполните требуемую информацию в форме на следующей
          странице и отправьте подтверждающие документы.
        </p>

        <h2 className="sub-title">
          Постановления о замораживании и раскрытии информации
        </h2>

        <ul className="list">
          <li>
            Поскольку за предоставление информации на этом портале отвечает
            определенная команда AltcoinsHub, его можно использовать только для
            отправки окончательных постановлений о замораживании и раскрытии
            информации. Любые другие судебные постановления или судебные
            разбирательства не должны подаваться через данный специализированный
            портал, а должны направляться в зарегистрированный офис
            соответствующего подразделения AltcoinsHub. AltcoinsHub не будет
            просматривать или принимать любые другие материалы, представленные
            на этом портале.
          </li>
          <li>
            Пожалуйста, убедитесь, что ваша заявка подтверждена как полученная,
            а вы получили ссылку на заявку. AltcoinsHub рассмотрит каждое
            успешно отправленное дело, но не будет нести ответственности, если
            вы не проверите эту информацию.
          </li>
          <li>
            Пожалуйста, подавайте только одну заявку на соответствующее
            постановление. Повторная отправка увеличит срок рассмотрения вашей
            заявки.
          </li>
          <li>
            Делая этот портал доступным, AltcoinsHub не берет на себя
            обязательства выполнять все присланные постановления о замораживании
            и раскрытии информации. Возможность AltcoinsHub замораживать ID
            пользователей (учетные записи) или предоставлять третьим лицам
            информацию в отношении ID пользователей (учетных записей)
            регулируется действующим законодательством и нормативными актами, а
            также правами пользователей AltcoinsHub (и обязанностями AltcoinsHub
            перед ними). AltcoinsHub рассмотрит любой запрос на замораживание
            или раскрытие информации, если такой запрос сделан через этот
            портал, чтобы определить, можем ли мы выполнить данный запрос.
            AltcoinsHub оставляет за собой любое доступное право отклонить любой
            запрос на замораживание или раскрытие информации.
          </li>
          <li>
            В зависимости от суда и вовлеченных сторон AltcoinsHub может иметь
            право возражать против юрисдикции соответствующего суда, вынесшего
            постановление о замораживании или раскрытии информации. Делая этот
            портал доступным для загрузки любого постановления, AltcoinsHub не
            подчиняется юрисдикции соответствующего суда и не отказывается от
            каких-либо прав, которые она имеет или может иметь для возражения
            против этого.
          </li>
          <li>
            Если AltcoinsHub в состоянии выполнить постановление о замораживании
            и/или раскрытии информации и данное постановление может быть
            рассмотрено без привлечения внешних юристов, AltcoinsHub, как
            правило, взимает разумную комиссию за соблюдение требований в
            размере 500 долларов США за каждый ID пользователя (учетную запись),
            включенный в постановление о замораживании, а также 500 долларов США
            за каждый ID пользователя (учетную запись), включенный в
            постановление о раскрытии информации. Если один и тот же ID
            пользователя (учетная запись) добавлен в оба типа постановлений или
            в комбинированное постановление, разумные расходы AltcoinsHub на
            соблюдение требований по этому ID пользователя (учетной записи)
            составят 1 000 долларов США. Отправляя постановление о замораживании
            и/или раскрытии информации через этот портал, вы соглашаетесь
            покрыть расходы AltcoinsHub на соблюдение соответствующих требований
            (без ущерба для права AltcoinsHub требовать возмещения юридических
            расходов, если AltcoinsHub по своему усмотрению сочтет необходимым
            получить юридический совет у сторонней организации).
          </li>
        </ul>

        <p>
          Мы согласны с условиями использования этого Портала, изложенными выше
          (периодически мы можем издавать рекомендации, касающиеся условий по
          работе с ордерами).
        </p>

        <div className="agreement">
          <Form.Control
            className="agreement-checkbox"
            onChange={(): void => setAgreeStatus(!isAgree)}
            type="checkbox"
          />

          <Form.Label>
            Мы согласны с условиями использования этого Портала, изложенными
            выше (периодически мы можем издавать рекомендации, касающиеся
            условий по работе с ордерами).
          </Form.Label>
        </div>

        <Button
          onClick={handleSubmit}
          disabled={!isAgree}
          className="agreement-button"
          variant="secondary"
        >
          Я принимаю и начинаю создавать запрос
        </Button>
      </div>
    </div>
  );
};

export default TermsForUse;
