import React from "react";
import linkArrow from "../images/icon/linkArrow.svg";
import ball from "../images/icon/ball.svg";
import file from "../images/icon/file.svg";
import shield from "../images/icon/shield.svg";
import message from "../images/icon/message.svg";
import settings from "../images/icon/settings.svg";
import team_feedback from "../images/icon/team_feedback.svg";
import product_improvement from "../images/icon/product_improvement.svg";
import give_feedback from "../images/icon/give_feedback.svg";
import stay_up from "../images/icon/stay_up.svg";
import review_banner from "../images/icon/review_banner.svg";

const SupportPage: React.FC = () => {
  const handleClick = (): void => {
    window.open("https://web.telegram.org/k/#@AltcoinsHubSupport", "_blank");
  };

  return (
    <div className="review_page">
      <div className="review_block">
        <div className="container review_block_center">
          <div className="form">
            <h1>Нам важно ваше мнение</h1>

            <p>
              Представители всегда рады услышать ваше мнение. Это поможет
              сделать наше сообщество еще лучше
            </p>

            <button type="button" className="form_button">
              КНОПКА
            </button>
          </div>
          <img src={review_banner} alt="review_banner" />
        </div>
      </div>

      <div className="center faq">
        <h1 className="title">Отправить отзыв</h1>

        <div className="container">
          <div className="review_faq_wrapper">
            <div className="review_faq" onClick={handleClick}>
              <div className="review_faq_description">
                <div className="review_faq_description_title">
                  <img src={ball} alt="ball" />

                  <p>Локализация и язык</p>
                </div>

                <span>
                  Локализация и язык Сообщите об орфографических или
                  грамматических ошибках, а также о вводящем в заблуждение
                  контенте.
                </span>
              </div>

              <img className="review_faq_arrow" src={linkArrow} alt="arrow" />
            </div>

            <div className="review_faq" onClick={handleClick}>
              <div className="review_faq_description">
                <div className="review_faq_description_title">
                  <img src={file} alt="file" />
                  <p>Дизайн и вид</p>
                </div>

                <span>
                  Поделитесь советом об улучшении внешнего вида или удобстве
                  использования.
                </span>
              </div>

              <img className="review_faq_arrow" src={linkArrow} alt="arrow" />
            </div>

            <div className="review_faq" onClick={handleClick}>
              <div className="review_faq_description">
                <div className="review_faq_description_title">
                  <img src={shield} alt="shield" />
                  <p>Уязвимости в безопасности</p>
                </div>

                <span>
                  Сообщите нам о нарушениях безопасности или уязвимостях.
                </span>
              </div>

              <img className="review_faq_arrow" src={linkArrow} alt="arrow" />
            </div>

            <div className="review_faq" onClick={handleClick}>
              <div className="review_faq_description">
                <div className="review_faq_description_title">
                  <img src={message} alt="message" />
                  <p>Предложения по продукту</p>
                </div>

                <span>
                  У вас есть отличная идея, как улучшить функциональность
                  продукта?
                </span>
              </div>

              <img className="review_faq_arrow" src={linkArrow} alt="arrow" />
            </div>
          </div>

          <div className="review_faq full" onClick={handleClick}>
            <div className="review_faq_description">
              <div className="review_faq_description_title">
                <img src={settings} alt="settings" />

                <p>
                  Поделитесь своим мнением о любом проекте, представленном на
                  AltcoinsHub
                </p>
              </div>

              <span>
                Недостоверная информация об общем предложении токенов, проблемы
                с участниками проекта, юридические проблемы, мошенничество и
                т. д.
              </span>
            </div>

            <img className="review_faq_arrow" src={linkArrow} alt="arrow" />
          </div>
        </div>
      </div>

      <div className="container center">
        <h1 className="title">Как это работает?</h1>

        <div className="how_its_work_block">
          <div className="list_item">
            <img src={give_feedback} alt="give_feedback" />

            <span>Оставить отзыв</span>
          </div>

          <div className="divider" />

          <div className="list_item">
            <img src={team_feedback} alt="team_feedback" />

            <span>Отзыв команды</span>
          </div>

          <div className="divider" />

          <div className="list_item">
            <img src={product_improvement} alt="product_improvement" />

            <span>Улучшение существующих продуктов</span>
          </div>

          <div className="divider" />

          <div className="list_item">
            <img src={stay_up} alt="stay_up" />

            <span>Будьте в курсе!</span>
          </div>
        </div>

        <div className="footer_text">
          Безопасность продукта — основополагающая задача нашей команды. Мы
          стремимся максимизировать уровень безопасности и совершенствуемся
          каждый день благодаря вашей поддержке. Продолжайте помогать нам в
          выявлении потенциальных уязвимостей и не стесняйтесь предлагать новые
          идеи.
        </div>

        <div className="footer_text">
          Давайте начнем! Нажмите здесь, чтобы отправить предложение или отзыв
          <a href="/" className="review_link">
            Отправить отзыв
          </a>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
