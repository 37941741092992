import { Store } from "react-notifications-component";
import { RequestError } from "../types/requestTypes";

export const handleFormError = (error: RequestError): string => {
  if (error.message && typeof error.message === "string") {
    return error.message;
  }
  if (error.message) {
    const message: string[] = [];
    Object.keys(error.message).forEach((el) => {
      // @ts-ignore
      // eslint-disable-next-line
        message.push(...error.message[el]);
    });
    return message.join(" ");
  }
  if (error?.data) {
    const message: string[] = [];
    // eslint-disable-next-line
      Object.keys(error?.data).forEach((el) => {
      // @ts-ignore
      // eslint-disable-next-line
        message.push(...error.data[el]);
    });

    return message.join(" ");
  }
  return "Request error";
};

export const handleNotifError = (error: RequestError): void => {
  if (error.message && typeof error.message === "string") {
    Store.addNotification({
      message: error.message,
      type: "danger",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
    return;
  }
  if (error.message) {
    const message: string[] = [];
    Object.keys(error.message).forEach((el) => {
      // @ts-ignore
      // eslint-disable-next-line
        message.push(...error.message[el]);
    });
    Store.addNotification({
      message: message.join(" "),
      type: "danger",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
    return;
  }
  if (error?.data) {
    const message: string[] = [];
    // eslint-disable-next-line
    Object.keys(error?.data).forEach((el) => {
      // @ts-ignore
      // eslint-disable-next-line
        message.push(...error.data[el]);
    });

    Store.addNotification({
      message: message.join(" "),
      type: "danger",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });

    return;
  }
  Store.addNotification({
    message: "Request error",
    type: "danger",
    insert: "top",
    container: "top-right",
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};
