import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../../store/store";
import { getPayoutsListFunc } from "../../store/slices/userPayouts";

const PayoutsTab: React.FC = () => {
  const [activePage, setActivePage] = useState<number>(0);

  const payoutsList = useSelector(
    (state: RootState) => state.userPayouts.payoutsList
  );
  const isLoading = useSelector(
    (state: RootState) => state.userPayouts.listFetching
  );
  const totalPages = useSelector(
    (state: RootState) => state.userPayouts.totalPages
  );

  const dispatch = useDispatch<AppDispatch>();

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getPayoutsListFunc(e + 1));
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Дата</b>
            </th>
            <th>
              <b>Метод</b>
            </th>
            <th>
              <b>Сумма</b>
            </th>
            <th>
              <b>Кошелек</b>
            </th>
            <th>
              <b>Статус</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            payoutsList &&
            payoutsList?.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.created_at}</td>
                <td>{el.name}</td>
                <td>{el.amount}</td>
                <td>{el.wallet}</td>
                <td>{el.status}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </>
  );
};

export default PayoutsTab;
