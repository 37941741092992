import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";

import { deleteCall } from "../../services/apiAdminCalls";
import { RequestError } from "../../types/requestTypes";
import { handleNotifError } from "../../helpers/errorHandlers";

interface Props {
  show: boolean;
  name: string;
  id: number;
  onHide: () => void;
  setActivePage: (e: number) => void;
}

const DeleteCallModal: React.FC<Props> = ({
  show,
  onHide,
  name,
  id,
  setActivePage,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true);
    const result = await deleteCall(id);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
      setIsLoading(false);
    } else {
      Store.addNotification({
        message: "Запрос обратной связи успешно удален",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      setActivePage(0);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Удаление запроса обратной связи
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">Вы уверены что хотите удалить запрос от {name}?</p>
        <div className="btns-group">
          <Button
            variant="success"
            onClick={(): void => {
              handleDelete();
            }}
          >
            {isLoading ? (
              <Oval
                height={30}
                width={30}
                color="#ffffff"
                visible
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Удалить"
            )}
          </Button>
          <Button variant="danger" onClick={onHide}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteCallModal;
