import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { CirclesWithBar, Oval } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../../store/store";
import { getPayoutsListFunc } from "../../store/slices/adminPayouts";
import { changePayoutStatus } from "../../services/apiWithdrawal";
import { handleNotifError } from "../../helpers/errorHandlers";
import { RequestError } from "../../types/requestTypes";

const AdminPayoutsTab: React.FC = () => {
  const [activePage, setActivePage] = useState<number>(0);
  const [isConfirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [isRejectLoading, setRejectLoading] = useState<boolean>(false);

  const payoutsList = useSelector(
    (state: RootState) => state.adminPayouts.payoutsList
  );
  const isLoading = useSelector(
    (state: RootState) => state.adminPayouts.listFetching
  );
  const totalPages = useSelector(
    (state: RootState) => state.adminPayouts.totalPages
  );

  const dispatch = useDispatch<AppDispatch>();

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getPayoutsListFunc(e + 1));
  };

  const changeStatusHandler = async (
    id: number,
    status: string
  ): Promise<void> => {
    if (status === "success") {
      setConfirmLoading(true);
    } else {
      setRejectLoading(true);
    }

    const result = await changePayoutStatus(id, status);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
    } else {
      dispatch(getPayoutsListFunc(activePage + 1));
    }

    if (status === "success") {
      setConfirmLoading(false);
    } else {
      setRejectLoading(false);
    }
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Пользователь</b>
            </th>
            <th>
              <b>Дата</b>
            </th>
            <th>
              <b>Метод</b>
            </th>
            <th>
              <b>Сумма</b>
            </th>
            <th>
              <b>Кошелек</b>
            </th>
            <th>
              <b>Статус</b>
            </th>
            {/* eslint-disable-next-line */}
            <th />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            payoutsList &&
            payoutsList?.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>
                  {el.user_first_name} {el.user_last_name} {el.user_surname} (ID
                  {el.user_id})
                </td>
                <td>{el.created_at}</td>
                <td>{el.payment_method_name}</td>
                <td>{el.amount}</td>
                <td>{el.wallet}</td>
                <td>{el.status}</td>
                <td>
                  <Button
                    variant="success"
                    disabled={isConfirmLoading}
                    onClick={(): void => {
                      changeStatusHandler(el.id, "success");
                    }}
                  >
                    {" "}
                    {isConfirmLoading ? (
                      <Oval
                        height={30}
                        width={30}
                        color="#ffffff"
                        visible
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Подтвердить"
                    )}
                  </Button>
                  <Button
                    variant="danger"
                    disabled={isRejectLoading}
                    onClick={(): void => {
                      changeStatusHandler(el.id, "rejected");
                    }}
                  >
                    {" "}
                    {isRejectLoading ? (
                      <Oval
                        height={30}
                        width={30}
                        color="#ffffff"
                        visible
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Отклонить"
                    )}
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </>
  );
};

export default AdminPayoutsTab;
