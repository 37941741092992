export const titleTranslate = (defString: string): string => {
  switch (defString) {
    case "Steaking Cancelled":
      return "Стейкинг отменен";
    case "Payout Request":
      return "Запрос на выплату";
    case "New Transaction":
      return "Новая транзакция";
    case "Transaction status updated":
      return "Статус транзакции обновлен";
    case "Payout History Status Changed":
      return "Статус истории выплат изменен";

    default:
      return defString;
  }
};

export const contentTranslate = (defString: string): string => {
  const number = defString?.match(/\d/g)?.join("") || "";
  switch (defString) {
    case "Your steaking has been cancelled":
      return "Ваш стейкинг был отменен";
    case `Your payout request of ${number}  has been received. It will be processed shortly.`:
      return `Ваш запрос на выплату ${number} получен. Он будет обработан в ближайшее время.`;
    case "You have a new transaction pending approval":
      return "У вас есть новая транзакция, ожидающая одобрения";
    case `Your transaction with the ID ${number} has been updated to paid`:
      // eslint-disable-next-line
      return `Ваша транзакция с ID ${number} обновлена ​​до статуса paid`;
    case "Your payout history status has been changed to success.":
      return "Статус истории выплат изменен на «Успешно».";
    case "Your payout history status has been changed to rejected.":
      return "Статус вашей истории выплат был изменен на «Отклонен».";

    default:
      return defString;
  }
};
