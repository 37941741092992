import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import type { AppDispatch, RootState } from "../store/store";
import { getStakingList } from "../store/slices/userStaking";
import { getPayoutsListFunc } from "../store/slices/userPayouts";
import { getTransactionsList } from "../store/slices/userTransactions";

import InfoTab from "../components/profileTabs/InfoTab";
import TransactionsTab from "../components/profileTabs/TransactionsTab";
import PayoutsTab from "../components/profileTabs/PayoutsTab";
import StakingTab from "../components/profileTabs/StakingTab";

enum TabsEnum {
  info = "info",
  transactions = "transactions",
  payouts = "payouts",
  staking = "staking",
}

const Profile: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isUserLogged = useSelector((state: RootState) => state.user.isLogged);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserLogged) {
      navigate("/");
    }
  }, [isUserLogged, navigate]);

  const tabsChangeHandler = (tab: string | null): void => {
    if (tab === TabsEnum.staking) {
      dispatch(getStakingList());
    }
    if (tab === TabsEnum.transactions) {
      dispatch(getTransactionsList());
    }
    if (tab === TabsEnum.payouts) {
      dispatch(getPayoutsListFunc());
    }
  };
  return (
    <section className="profile-section">
      <div className="container">
        <h1>Мой профиль</h1>
        <Tabs
          defaultActiveKey={TabsEnum.info}
          className="mt-4"
          onSelect={(e): void => tabsChangeHandler(e)}
        >
          <Tab eventKey={TabsEnum.info} title="Инфо">
            <InfoTab />
          </Tab>
          <Tab eventKey={TabsEnum.transactions} title="Транзакции">
            <TransactionsTab />
          </Tab>
          <Tab eventKey={TabsEnum.payouts} title="Выводы">
            <PayoutsTab />
          </Tab>
          <Tab eventKey={TabsEnum.staking} title="Стейкинг">
            <StakingTab />
          </Tab>
        </Tabs>
      </div>
    </section>
  );
};

export default Profile;
