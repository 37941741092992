import React, { useState, useEffect } from "react";
import { CirclesWithBar } from "react-loader-spinner";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import { CoinRateInfo } from "../types/coinsType";
import type { RootState, AppDispatch } from "../store/store";
import { getCoinsFunc } from "../store/slices/homeCoins";

const RateTable: React.FC = () => {
  const [rateData, setRateData] = useState<CoinRateInfo[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const coinsList = useSelector((state: RootState) => state.homeCoins.coins);

  const dispatch = useDispatch<AppDispatch>();

  const getImg = (symbol: string): string => {
    const result = coinsList?.filter((el) => {
      return symbol.includes(el.symbol);
    })[0].image;
    return result || "";
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const getData = async (): Promise<void> => {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`
    );
    const symbols: string[] = [];
    coinsList?.forEach((item) => {
      symbols.push(item.symbol);
    });
    const result: CoinRateInfo[] = [];
    if (response.data) {
      const filtered = response.data.filter((el: any) => {
        return symbols.includes(el.symbol.toUpperCase() as string);
      });
      filtered.forEach((el: any) => {
        result.push({
          image: getImg(el.symbol.toUpperCase() as string),
          name: el.name,
          symbol: el.symbol,
          lastPrice: formatter.format(el.current_price as number),
          priceChangePercent: el.price_change_percentage_24h.toString(),
          volume: formatter.format(el.market_cap as number),
        });
      });
    }
    setRateData(result);
    setLoading(false);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (coinsList) {
      setLoading(true);
      getData();
      interval = setInterval(() => {
        getData();
      }, 30000);
    } else {
      dispatch(getCoinsFunc());
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [coinsList, dispatch]);

  return (
    <section className="flat-price-coin bg-browse">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="top-title center">
              <h2>Актуальные курсы</h2>
            </div>
            <div className="table-price">
              {isLoading ? (
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              ) : (
                <table className="mb-3">
                  <thead>
                    <tr>
                      <th>
                        <b>Имя</b>
                      </th>
                      <th>
                        <b>Последняя цена</b>
                      </th>
                      <th>
                        <b>Изменение цены</b>
                      </th>
                      <th>
                        <b>Рыночная капитализация</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rateData.map((el) => (
                      <tr key={el.symbol}>
                        <td className="name-cell">
                          <img
                            src={`${process.env.REACT_APP_IMG_URL as string}${
                              (el.image as string) || ""
                            }`}
                            alt="img"
                            width={40}
                          />
                          {el.name}
                          <span>{el.symbol}</span>
                        </td>
                        <td>{el.lastPrice}</td>
                        <td
                          className={
                            el.priceChangePercent.startsWith("-")
                              ? "red"
                              : "green"
                          }
                        >
                          {el.priceChangePercent}%
                        </td>
                        <td>{el.volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RateTable;
