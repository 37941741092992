import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

import type { RootState } from "../../store/store";
import { PaymentItem } from "../../types/paymentTypes";

import PaymentModal from "../modals/PaymentModal";
import DeletePaymentModal from "../modals/DeletePaymentModal";

interface Props {
  activePage: number;
  setActivePage: (e: number) => void;
}

const AdminPaymentTab: React.FC<Props> = ({ activePage, setActivePage }) => {
  const [isEditShow, setEditShow] = useState<boolean>(false);
  const [isDeleteShow, setDeleteShow] = useState<boolean>(false);
  const [data, setData] = useState<PaymentItem>();
  const [activeId, setActiveId] = useState<number>(0);

  const paymnetsList = useSelector(
    (state: RootState) => state.adminPayments.paymnetsList
  );
  const totalPages = useSelector(
    (state: RootState) => state.adminPayments.totalPages
  );
  const isLoading = useSelector(
    (state: RootState) => state.adminPayments.paymentsFetching
  );

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
  };

  const handleEditShow = (currentData: PaymentItem): void => {
    setData(currentData);
    setEditShow(true);
  };
  const handleEditHide = (): void => {
    setEditShow(false);
    setData(undefined);
  };
  const handleDeleteShow = (id: number): void => {
    setDeleteShow(true);
    setActiveId(id);
  };
  const handleDeleteHide = (): void => {
    setDeleteShow(false);
    setActiveId(0);
  };
  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Имя</b>
            </th>
            <th>
              <b>Кошелек</b>
            </th>
            <th>
              <b>Дата создания</b>
            </th>
            {/* eslint-disable-next-line */}
            <th className="admin-table-btns" />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={5}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            paymnetsList &&
            paymnetsList.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.name}</td>
                <td>{el.wallet}</td>
                <td>{el.created_at.split("T")[0]}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={(): void => {
                      handleEditShow(el);
                    }}
                  >
                    Редактировать
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={(): void => {
                      handleDeleteShow(el.id);
                    }}
                  >
                    Удалить
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      <PaymentModal
        show={isEditShow}
        onHide={handleEditHide}
        activePage={activePage}
        isEdit
        data={data}
      />
      <DeletePaymentModal
        show={isDeleteShow}
        onHide={handleDeleteHide}
        setActivePage={handlePaginationChange}
        id={activeId}
      />
    </>
  );
};

export default AdminPaymentTab;
