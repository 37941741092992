import { useEffect, useState } from "react";

const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const updatePosition = (): void => {
    setScrollPosition(document.body.scrollTop);
  };

  useEffect(() => {
    document.body.addEventListener("scroll", updatePosition);

    return () => document.body.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
