import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getPayoutsList } from "../../services/apiWithdrawal";
import { PayoutItem } from "../../types/payoutTypes";
import { GetPayoutsListResponse } from "../../types/requestTypes";

export interface UserPayoutsState {
  payoutsList: PayoutItem[] | null;
  listFetching: boolean;
  totalPages: number;
}

const initialState: UserPayoutsState = {
  payoutsList: null,
  listFetching: false,
  totalPages: 1,
};

export const getPayoutsListFunc = createAsyncThunk(
  "userPayouts/loadList",
  async (page?: number) => {
    const response = await getPayoutsList(page);
    const { data } = response as GetPayoutsListResponse;
    return data;
  }
);

export const userPayouts = createSlice({
  name: "userPayouts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPayoutsListFunc.fulfilled,
      (
        state: UserPayoutsState,
        action: PayloadAction<{
          data: PayoutItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.payoutsList = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.listFetching = false;
      }
    );
    builder.addCase(getPayoutsListFunc.pending, (state: UserPayoutsState) => {
      state.listFetching = true;
    });
  },
});

export default userPayouts.reducer;
