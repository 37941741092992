import ApiClient from "./ApiClient";
import {
  RequestError,
  GetAdminCoinsListResponse,
  GetAdminCoinParamsResponse,
} from "../types/requestTypes";

export const getCoinsList = async (
  page?: number
): Promise<GetAdminCoinsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetAdminCoinsListResponse>(
      `/crypto-coins?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getCoinParams = async (
  id: number
): Promise<GetAdminCoinParamsResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetAdminCoinParamsResponse>(
      `/crypto-coins/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
