import ApiClient from "./ApiClient";
import {
  RequestError,
  GetAdminCoinParamsResponse,
  StakeSuccessResponse,
  GetStakingListResponse,
  DefaultSuccessResponse,
} from "../types/requestTypes";

export const makeStaking = async (
  coinId: number,
  paramId: number,
  amount: number,
  isEarly: boolean
): Promise<StakeSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<StakeSuccessResponse>("/steaking", {
      crypto_coin_id: coinId,
      crypto_coin_parameter_id: paramId,
      amount,
      early_repayment: isEarly,
    });
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const setAutoInvest = async (
  id: number
): Promise<GetAdminCoinParamsResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetAdminCoinParamsResponse>(
      `/steaking/${id}/autoinvesting`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getUserStakings = async (
  page?: number
): Promise<GetStakingListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetStakingListResponse>(
      `/steaking?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteUserStaking = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<DefaultSuccessResponse>(
      `/steaking/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getAdminStakings = async (
  page?: number
): Promise<GetStakingListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetStakingListResponse>(
      `/admin/steakings?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteAdminStaking = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<DefaultSuccessResponse>(
      `/admin/steakings/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
