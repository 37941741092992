import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";
import PhoneInput from "react-phone-number-input";

import { RequestError } from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";
import { sendMessage } from "../../services/apiLogin";

interface Props {
  show: boolean;
  onHide: () => void;
}

const ContactModal: React.FC<Props> = ({ show, onHide }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  // Form handlers start
  const handleError = (error: RequestError): void => {
    const errorString = handleFormError(error);
    setErrorMsg(errorString);
    setIsLoading(false);
  };

  const handleFormSubmit = async (): Promise<void> => {
    if (
      !email.toLowerCase().match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg("Введите валидную почту");
      return;
    }
    setIsLoading(true);
    const result = await sendMessage(email, phone, name, message);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      onHide();
      Store.addNotification({
        message: "Сообщение успешно отправлено",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    setIsLoading(false);
  };
  // Form handlers end

  // Check is form button disabled
  const isSubmitDisabled = (): boolean => {
    if (!email || !phone || !name || !message) {
      return true;
    }
    return false;
  };

  const setNameWithValidation = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    const formattedValue = value.match(/^[a-zA-Zа-яЯ-я\s]+/gi)?.join("") || "";

    setName(formattedValue.trimStart());
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Связаться</Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="login-form">
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Почта:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Почта"
              value={email || ""}
              onChange={(e): void => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Телефон:</Form.Label>

            <PhoneInput
              international
              smartCaret={false}
              defaultCountry="UA"
              countryCallingCodeEditable={false}
              placeholder="Телефон"
              value={phone || ""}
              onChange={(value: string): void => {
                setPhone(value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Имя:</Form.Label>
            <Form.Control
              maxLength={20}
              type="text"
              placeholder="Имя"
              value={name || ""}
              onChange={setNameWithValidation}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Сообщение:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Сообщение"
              value={message || ""}
              onChange={(e): void => setMessage(e.target.value)}
            />
          </Form.Group>
        </div>
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          onClick={(): void => {
            handleFormSubmit();
          }}
          disabled={isSubmitDisabled() || isLoading}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
