import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import type { RootState } from "../store/store";

import CoinsTable from "../components/CoinsTable";

const StakingPage: React.FC = () => {
  const isUserLogged = useSelector((state: RootState) => state.user.isLogged);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserLogged) {
      navigate("/");
    }
  }, [isUserLogged, navigate]);
  return <CoinsTable />;
};

export default StakingPage;
