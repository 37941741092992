import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";

import { CoinInfo } from "../../types/coinsType";
import {
  addCoinParams,
  editCoinParams,
  deleteCoinParams,
} from "../../services/apiAdminCoins";
import { handleFormError } from "../../helpers/errorHandlers";
import { RequestError } from "../../types/requestTypes";

interface Props {
  data: CoinInfo;
  id: string | number;
  coinId: number;
  removeItem: (id: string | number) => void;
  loadParams: () => Promise<void>;
}

const CoinsParamsForm: React.FC<Props> = ({
  data,
  id,
  removeItem,
  coinId,
  loadParams,
}) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [formData, setFormData] = useState<CoinInfo>({} as CoinInfo);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  // Form handlers start
  const handleFormChange = (key: string, value: string): void => {
    setErrorMsg("");
    setFormData({ ...formData, [key]: value });
  };
  const showSuccessMessage = (msg: string): void => {
    Store.addNotification({
      message: msg,
      type: "success",
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  const handleFormAddSubmit = async (): Promise<void> => {
    if (
      !formData.limit ||
      !formData.max_amount ||
      !formData.min_amount ||
      !formData.percent ||
      !formData.period
    ) {
      setErrorMsg("Please fill all fields");
      return;
    }
    setIsLoading(true);
    const result = await addCoinParams(coinId, {
      limit: parseInt(formData.limit as string, 10),
      max_amount: parseInt(formData.max_amount as string, 10),
      min_amount: parseInt(formData.min_amount as string, 10),
      percent: parseInt(formData.percent as string, 10),
      period: parseInt(formData.period as string, 10),
      interest_for_early_repayment: parseInt(
        formData.interest_for_early_repayment as string,
        10
      ),
    } as CoinInfo);
    if (result.status === "error") {
      setErrorMsg(handleFormError(result as RequestError));
      setIsLoading(false);
    } else {
      showSuccessMessage("Параметр успешно добавлен");
      setIsLoading(false);
      loadParams();
    }
  };
  const handleFormEditSubmit = async (): Promise<void> => {
    setIsLoading(true);
    const result = await editCoinParams(
      {
        limit: parseInt(formData.limit as string, 10),
        max_amount: parseInt(formData.max_amount as string, 10),
        min_amount: parseInt(formData.min_amount as string, 10),
        percent: parseInt(formData.percent as string, 10),
        period: parseInt(formData.period as string, 10),
        interest_for_early_repayment: parseInt(
          formData.interest_for_early_repayment as string,
          10
        ),
      } as CoinInfo,
      parseInt(formData.id as string, 10)
    );
    if (result.status === "error") {
      setErrorMsg(handleFormError(result as RequestError));
      setIsLoading(false);
    } else {
      showSuccessMessage("Параметр успешно обновлен");
      setIsLoading(false);
      setEdit(false);
    }
  };
  const handleFormDeleteSubmit = async (): Promise<void> => {
    setIsLoading(true);
    const result = await deleteCoinParams(parseInt(formData.id as string, 10));
    if (result.status === "error") {
      setErrorMsg(handleFormError(result as RequestError));
      setIsLoading(false);
    } else {
      showSuccessMessage("Параметр успешно удален");
      setIsLoading(false);
      setEdit(false);
      removeItem(formData.id as number);
    }
  };
  // Form handlers end

  const toggleEdit = (): void => {
    setEdit(!isEdit);
  };

  return (
    <div className="params-wrap">
      <Form.Group className="mb-3 mt-3">
        <Form.Label>Период(дней):</Form.Label>
        <Form.Control
          type="number"
          placeholder="Период"
          min={1}
          value={formData.period || ""}
          disabled={formData?.isNew ? false : !isEdit}
          onChange={(e): void => handleFormChange("period", e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ARP(проценты):</Form.Label>
        <Form.Control
          type="number"
          placeholder="Проценты"
          min={1}
          value={formData.percent || ""}
          disabled={formData?.isNew ? false : !isEdit}
          onChange={(e): void => handleFormChange("percent", e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ARP при досрочном снятии:</Form.Label>
        <Form.Control
          type="number"
          placeholder="Проценты"
          min={1}
          value={formData.interest_for_early_repayment || ""}
          disabled={formData?.isNew ? false : !isEdit}
          onChange={(e): void =>
            handleFormChange("interest_for_early_repayment", e.target.value)
          }
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Лимит:</Form.Label>
        <Form.Control
          type="number"
          placeholder="Лимит"
          min={1}
          value={formData.limit || ""}
          disabled={formData?.isNew ? false : !isEdit}
          onChange={(e): void => handleFormChange("limit", e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Минимальная сумма:</Form.Label>
        <Form.Control
          type="number"
          placeholder="Минимальная сумма"
          min={1}
          value={formData.min_amount || ""}
          disabled={formData?.isNew ? false : !isEdit}
          onChange={(e): void => handleFormChange("min_amount", e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Максимальная суммаt:</Form.Label>
        <Form.Control
          type="number"
          placeholder="Максимальная сумма"
          min={1}
          value={formData.max_amount || ""}
          disabled={formData?.isNew ? false : !isEdit}
          onChange={(e): void => handleFormChange("max_amount", e.target.value)}
        />
      </Form.Group>
      {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
      <div className="btns-group">
        {data.isNew && (
          <>
            <Button
              variant="success"
              disabled={isLoading}
              onClick={(): void => {
                handleFormAddSubmit();
              }}
            >
              {isLoading ? (
                <Oval
                  height={30}
                  width={30}
                  color="#ffffff"
                  visible
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "Добавить"
              )}
            </Button>
            <Button variant="danger" onClick={(): void => removeItem(id)}>
              Удалить
            </Button>
          </>
        )}
        {!data.isNew &&
          (isEdit ? (
            <>
              <Button
                variant="success"
                disabled={isLoading}
                onClick={(): void => {
                  handleFormEditSubmit();
                }}
              >
                {isLoading ? (
                  <Oval
                    height={30}
                    width={30}
                    color="#ffffff"
                    visible
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Отправить"
                )}
              </Button>
              <Button
                variant="danger"
                onClick={(): void => {
                  handleFormDeleteSubmit();
                }}
              >
                {isLoading ? (
                  <Oval
                    height={30}
                    width={30}
                    color="#ffffff"
                    visible
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  "Удалить"
                )}
              </Button>
            </>
          ) : (
            <Button variant="warning" onClick={toggleEdit}>
              Редактировать
            </Button>
          ))}
      </div>
    </div>
  );
};

export default CoinsParamsForm;
