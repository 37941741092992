import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";

import { addNewWithdrawalRequest } from "../../services/apiWithdrawal";
import { getUserPaymentsList } from "../../services/apiPayment";
import {
  RequestError,
  GetPaymentsMethodsResponse,
} from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";
import { PaymentItem } from "../../types/paymentTypes";

interface Props {
  show: boolean;
  onHide: () => void;
}

const WithdrawalModal: React.FC<Props> = ({ show, onHide }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [method, setMethod] = useState<string>();
  const [amount, setAmount] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");
  const [id, setId] = useState<number>();
  const [options, setOptions] = useState<string[]>([]);
  const [optionsItems, setOptionsItems] = useState<PaymentItem[]>([]);

  const getId = (): void => {
    const filtered = optionsItems.filter((el) => {
      return el.name === method;
    });
    setId(filtered[0].id);
  };

  useEffect(() => {
    if (method) getId();
    // eslint-disable-next-line
  }, [method]);

  const handleSentForm = async (): Promise<void> => {
    setIsLoading(true);
    const result = await addNewWithdrawalRequest(amount, id || 0, wallet);
    if (result.status === "error") {
      setErrorMsg(handleFormError(result as RequestError));
    } else {
      Store.addNotification({
        message: "Запрос на вывод средств успешно отправлен",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
    }
    setIsLoading(false);
  };

  const loadOptions = async (): Promise<void> => {
    const result = await getUserPaymentsList();
    const optionsData: string[] = [];
    if (result.status !== "error") {
      const { data } = result as GetPaymentsMethodsResponse;
      setOptionsItems(data as PaymentItem[]);
      setMethod(data[0].name as string);
      data.forEach((el) => {
        optionsData.push(el.name as string);
      });
    }
    setOptions(optionsData);
  };

  useEffect(() => {
    loadOptions();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Вывод средств
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="login-form">
          <Form.Group className="mb-3 mt-3">
            <Form.Label htmlFor="typeSelect">Тип платежа:</Form.Label>
            <Form.Select
              id="typeSelect"
              onChange={(e): void => {
                setMethod(e.target.value);
              }}
              value={method}
            >
              {options.map((el, idx) => (
                <option key={`${el}${idx + 1}`} value={el}>
                  {el}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Сумма:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Amount"
              onChange={(e): void => setAmount(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Кошелек:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Кошелек"
              onChange={(e): void => setWallet(e.target.value)}
            />
          </Form.Group>
        </div>
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          disabled={!amount || !wallet || !id}
          onClick={(): void => {
            handleSentForm();
          }}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawalModal;
