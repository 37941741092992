import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import type { RootState } from "../store/store";

import { UserRoles } from "../types/userDataTypes";

// import logo from "../images/logo-ft.png";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const userRole = useSelector((state: RootState) => state.user.userInfo?.role);

  const handleClick = (path = "https://www.altcoinshub.ru/"): void => {
    document.body.scrollTo(0, 0);

    navigate(path);
  };

  return (
    <footer id="footer_background">
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-widgets">
              <div className="col-md-12">
                <div className="widget widget-about">
                  <div className="widget-text">
                    <div id="logo-ft">
                      <NavLink to="/">
                        altcoins<span>hub</span>
                        {/* <img src={logo} alt="logo Finance Business" /> */}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="widget widget_nav_menu">
                  <h3 className="widget-title">О нас</h3>
                  <ul className="menu-footer">
                    <li>
                      <span
                        onClick={(): void => handleClick("/about")}
                        title=""
                      >
                        <i className="fa fa-angle-double-right" /> О нас{" "}
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={(): void => handleClick("/community")}
                        title=""
                      >
                        <i className="fa fa-angle-double-right" /> Сообщество{" "}
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={(): void => handleClick("/altcoins-hub")}
                        title=""
                      >
                        <i className="fa fa-angle-double-right" /> Блог
                        Altcoinshub{" "}
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={(): void => handleClick("/general-risk")}
                        title=""
                      >
                        <i className="fa fa-angle-double-right" />
                        Основные риски
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={(): void =>
                          handleClick("/terms-and-conditions")
                        }
                        title=""
                      >
                        <i className="fa fa-angle-double-right" /> Правила и
                        Условия
                      </span>
                    </li>
                  </ul>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="widget widget_nav_menu">
                  <h3 className="widget-title">Служба поддержки</h3>
                  <ul className="menu-footer">
                    <li>
                      <span
                        onClick={(): void => handleClick("/support")}
                        title=""
                      >
                        <i className="fa fa-angle-double-right" /> Центр
                        поддержки
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={(): void => handleClick("/legal-and-policy")}
                        title=""
                      >
                        <i className="fa fa-angle-double-right" /> Система
                        запросов для правоохранительных органов
                      </span>
                    </li>
                  </ul>

                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="widget widget_contact">
                  <h3 className="widget-title">Контакты</h3>
                  <ul className="contact-list">
                    <li>
                      {" "}
                      Адрес:{" "}
                      <span>Киевское ш., д. 1, Москва, Россия, 108811</span>
                    </li>
                    <li>
                      {" "}
                      Телефон: <span>+7 462 855-31-22</span>
                    </li>
                    <li>
                      {" "}
                      Почта: <span>beststaking@gmail.com</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="menu-ft-bottom">
                <li>
                  <NavLink
                    className={({ isActive }): string | undefined =>
                      isActive ? "active" : undefined
                    }
                    to="/"
                    end
                  >
                    Главная
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }): string | undefined =>
                      isActive ? "active" : undefined
                    }
                    to="/about"
                    end
                  >
                    О Нас
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }): string | undefined =>
                      isActive ? "active" : undefined
                    }
                    to="/community"
                    end
                  >
                    Сообщество
                  </NavLink>
                </li>
                {isLogged && (
                  <>
                    <li>
                      <NavLink
                        className={({ isActive }): string | undefined =>
                          isActive ? "active" : undefined
                        }
                        to="/staking"
                      >
                        Стейкинг
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }): string | undefined =>
                          isActive ? "active" : undefined
                        }
                        to="/profile"
                      >
                        Профиль
                      </NavLink>
                    </li>
                  </>
                )}
                {userRole && userRole === UserRoles.admin && isLogged && (
                  <li>
                    <NavLink
                      className={({ isActive }): string | undefined =>
                        isActive ? "active" : undefined
                      }
                      to="/admin-panel"
                    >
                      Админ панель
                    </NavLink>
                  </li>
                )}
              </ul>
              {/* <div className="copyright">
                <p>
                  © Copyright{" "}
                  <span href="#/" title="">
                    Coinjet
                  </span>{" "}
                  Cryptocurrency Theme by{" "}
                  <span
                    href="https://themeforest.net/user/themesflat/portfolio"
                    title=""
                  >
                    Themesflat
                  </span>{" "}
                  2018{" "}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
