import ApiClient from "./ApiClient";
import {
  RequestError,
  DefaultSuccessResponse,
  GetPaymentsListResponse,
  GetPaymentsMethodsResponse,
} from "../types/requestTypes";

export const getAdminPaymentsList = async (
  page?: number
): Promise<GetPaymentsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetPaymentsListResponse>(
      `/admin/payment-methods?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getUserPaymentsList = async (): Promise<
  GetPaymentsMethodsResponse | RequestError
> => {
  try {
    const result = await ApiClient.get<GetPaymentsMethodsResponse>(
      `/payment-methods`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const addNewPayment = async (
  name: string,
  wallet: string
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>(
      "/admin/payment-methods",
      { name, wallet }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const editPayment = async (
  name: string,
  wallet: string,
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.patch<DefaultSuccessResponse>(
      `/admin/payment-methods/${id}`,
      { name, wallet }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deletePayment = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<DefaultSuccessResponse>(
      `/admin/payment-methods/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
