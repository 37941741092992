import React, { useState, MouseEvent, useEffect, useRef } from "react";
import { Container, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { CirclesWithBar } from "react-loader-spinner";
import { useDetectClickOutside } from "react-detect-click-outside";
import moment from "moment";

import type { RootState } from "../store/store";
import { loggedAction } from "../store/slices/userData";
import { UserRoles } from "../types/userDataTypes";
import {
  getNotificationsList,
  clearNotifications,
} from "../services/apiNotifications";
import { NotificationItem } from "../types/notificationTypes";
import { GetNotificationsListResponse } from "../types/requestTypes";
import {
  titleTranslate,
  contentTranslate,
} from "../helpers/notificationTranslate";
import LoginModal from "./modals/LoginModal";
import useOutsideClick from "../helpers/useOutsideClick";

const Header: React.FC = () => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [notifShow, setNotifShow] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldClear, setShouldClear] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const { ref: menuRef, isVisible, setIsVisible } = useOutsideClick(false);

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const userRole = useSelector((state: RootState) => state.user.userInfo?.role);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const balance = useSelector(
    (state: RootState) => state.user.userInfo?.balance
  );
  const dispatch = useDispatch();

  const wrapperRef = useRef<any>();

  const handleLogin = (e: boolean): void => {
    dispatch(loggedAction(e));
  };

  const toggleMenu = (): void => {
    setIsVisible(!isVisible);
  };

  const showModal = (e: MouseEvent): void => {
    e.preventDefault();
    setModalShow(true);
  };
  const hideModal = (): void => {
    setModalShow(false);
  };

  const loadNotifications = async (page: number): Promise<void> => {
    setIsLoading(true);
    const result = await getNotificationsList(page);
    if (result.status !== "error") {
      const { data } = result as GetNotificationsListResponse;
      setNotifications([
        ...notifications,
        ...(data.data as NotificationItem[]),
      ]);
      setNotificationsCount((data.total as number) || 0);
      setShouldClear(false);
      if (data.data.length < 7) {
        setHasMore(false);
        setShouldClear(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLogged) loadNotifications(1);
    // eslint-disable-next-line
  }, [isLogged]);
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!notifShow && isLogged) {
      interval = setInterval(() => {
        loadNotifications(1);
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [notifShow, isLogged]);

  const showNotif = (): void => {
    setNotifShow(true);
  };
  const hideNotif = (): void => {
    if (notifShow) {
      setNotifShow(false);
      setActivePage(1);
      setHasMore(true);
      if (shouldClear && notificationsCount > 0) {
        setNotifications([]);
        setShouldClear(false);
        clearNotifications();
        setNotificationsCount(0);
      }
    }
  };
  const ref = useDetectClickOutside({ onTriggered: hideNotif });

  const handleScroll = (): void => {
    const isBottom =
      wrapperRef.current.scrollHeight - wrapperRef.current.scrollTop - 50 <=
      wrapperRef.current.clientHeight;
    if (isBottom && hasMore && !isLoading) {
      setActivePage(activePage + 1);
      loadNotifications(activePage + 1);
    }
  };

  return (
    <>
      <div className="header-section-wrap">
        <div className="themesflat-top">
          <Container>
            <ul className="box-account">
              {userInfo && isLogged && (
                <li className="name-widget">
                  <NavLink to="/profile">
                    {userInfo.first_name} {userInfo.last_name}
                  </NavLink>
                </li>
              )}
              {balance && isLogged && (
                <li className="balance-widget">
                  <NavLink to="/profile">Баланс: {balance}$</NavLink>
                </li>
              )}
              {isLogged ? (
                <li className="sign-in logout-btn">
                  <span onClick={(): void => handleLogin(false)}>Выйти</span>
                </li>
              ) : (
                <li className="login">
                  <a href="#/" title="" onClick={showModal}>
                    Войти
                  </a>
                </li>
              )}
              {isLogged && (
                <li className="notifications-widget" ref={ref}>
                  <div className="notification-icon" onClick={showNotif} />
                  {notificationsCount > 0 && (
                    <div className="notification-badge">
                      {notificationsCount}
                    </div>
                  )}
                  {notifShow && (
                    <div
                      className="notifications-wrap"
                      ref={wrapperRef}
                      onScroll={handleScroll}
                    >
                      {notifications.map((el) => {
                        return (
                          <Card style={{ width: "200px" }} key={el.id}>
                            <Card.Body>
                              <Card.Title>
                                {titleTranslate(el.title)}{" "}
                                <span>
                                  {moment(el.created_at).format(
                                    "YYYY-MM-DD HH:MM"
                                  )}
                                </span>
                              </Card.Title>
                              <Card.Text>{contentTranslate(el.body)}</Card.Text>
                            </Card.Body>
                          </Card>
                        );
                      })}
                      {!notifications.length && (
                        <p className="no-items">У вас нет новых уведомлений</p>
                      )}
                      {hasMore && notifications.length > 0 && (
                        <CirclesWithBar
                          height="50"
                          width="50"
                          color="#f1a619"
                          wrapperStyle={{ justifyContent: "center" }}
                          visible
                          ariaLabel="circles-with-bar-loading"
                        />
                      )}
                    </div>
                  )}
                </li>
              )}
            </ul>
            <div className="clearfix" />
          </Container>
        </div>
        <header id="header" className="header bg-color">
          <Container>
            <div className="header-wrap">
              <div id="logo" className="logo">
                <NavLink to="/">
                  altcoins<span>hub</span>
                </NavLink>
              </div>
              <div className="nav-wrap">
                <div
                  className={isVisible ? "btn-menu active" : "btn-menu"}
                  onClick={toggleMenu}
                >
                  <span />
                </div>
                <nav id="mainnav" className="mainnav">
                  <ul className="menu">
                    <li>
                      <NavLink
                        className={({ isActive }): string | undefined =>
                          isActive ? "active" : undefined
                        }
                        to="/"
                        end
                      >
                        Главная
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }): string | undefined =>
                          isActive ? "active" : undefined
                        }
                        to="/about"
                        end
                      >
                        О Нас
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className={({ isActive }): string | undefined =>
                          isActive ? "active" : undefined
                        }
                        to="/community"
                        end
                      >
                        Сообщество
                      </NavLink>
                    </li>
                    {isLogged && (
                      <>
                        <li>
                          <NavLink
                            className={({ isActive }): string | undefined =>
                              isActive ? "active" : undefined
                            }
                            to="/staking"
                          >
                            Стейкинг
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }): string | undefined =>
                              isActive ? "active" : undefined
                            }
                            to="/profile"
                          >
                            Профиль
                          </NavLink>
                        </li>
                      </>
                    )}
                    {userRole && userRole === UserRoles.admin && isLogged && (
                      <li>
                        <NavLink
                          className={({ isActive }): string | undefined =>
                            isActive ? "active" : undefined
                          }
                          to="/admin-panel"
                        >
                          Админ панель
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </Container>
        </header>
        <nav
          ref={menuRef}
          id="mainnav-mobi"
          className={isVisible ? "mainnav active" : "mainnav"}
          onClick={toggleMenu}
        >
          <ul className="menu">
            <li>
              <NavLink
                className={({ isActive }): string | undefined =>
                  isActive ? "active" : undefined
                }
                to="/"
                end
              >
                Главная
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }): string | undefined =>
                  isActive ? "active" : undefined
                }
                to="/about"
                end
              >
                О Нас
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }): string | undefined =>
                  isActive ? "active" : undefined
                }
                to="/community"
                end
              >
                Сообщество
              </NavLink>
            </li>
            {isLogged && (
              <>
                <li>
                  <NavLink
                    className={({ isActive }): string | undefined =>
                      isActive ? "active" : undefined
                    }
                    to="/staking"
                  >
                    Стейкинг
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }): string | undefined =>
                      isActive ? "active" : undefined
                    }
                    to="/profile"
                  >
                    Профиль
                  </NavLink>
                </li>
              </>
            )}
            {userRole && userRole === UserRoles.admin && isLogged && (
              <li>
                <NavLink
                  className={({ isActive }): string | undefined =>
                    isActive ? "active" : undefined
                  }
                  to="/admin-panel"
                >
                  Админ панель
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <LoginModal
        show={modalShow}
        onHide={hideModal}
        handleLogin={handleLogin}
      />
    </>
  );
};

export default Header;
