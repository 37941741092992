import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getAdminPaymentsList } from "../../services/apiPayment";
import { PaymentItem } from "../../types/paymentTypes";
import { GetPaymentsListResponse } from "../../types/requestTypes";

export interface AdminPaymentsState {
  paymnetsList: PaymentItem[] | null;
  paymentsFetching: boolean;
  totalPages: number;
}

const initialState: AdminPaymentsState = {
  paymnetsList: null,
  paymentsFetching: false,
  totalPages: 1,
};

export const getPaymentsFunc = createAsyncThunk(
  "adminPayments/loadList",
  async (page?: number) => {
    const response = await getAdminPaymentsList(page);
    const { data } = response as GetPaymentsListResponse;
    return data;
  }
);

export const adminPayments = createSlice({
  name: "adminPayments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getPaymentsFunc.fulfilled,
      (
        state: AdminPaymentsState,
        action: PayloadAction<{
          data: PaymentItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.paymnetsList = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.paymentsFetching = false;
      }
    );
    builder.addCase(getPaymentsFunc.pending, (state: AdminPaymentsState) => {
      state.paymentsFetching = true;
    });
  },
});

export default adminPayments.reducer;
