import ApiClient from "./ApiClient";
import { RequestError, RegisterResponse } from "../types/requestTypes";

export const sendMail = async (
  email: string
): Promise<RegisterResponse | RequestError> => {
  try {
    const result = await ApiClient.publicPost<RegisterResponse>(
      "/auth/recovery/send-email",
      { email }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const sendCode = async (
  email: string,
  code: string
): Promise<RegisterResponse | RequestError> => {
  try {
    const result = await ApiClient.post<RegisterResponse>(
      "/auth/recovery/send-email",
      { email, code }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const sendPassword = async (
  email: string,
  password: string,
  code: string
): Promise<RegisterResponse | RequestError> => {
  try {
    const result = await ApiClient.post<RegisterResponse>(
      "/auth/recovery/send-email",
      { email, code, password }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
