import ApiClient from "./ApiClient";
import { UserInfo } from "../types/userDataTypes";
import {
  RequestError,
  RegisterResponse,
  LoginData,
  GetUserDataResponse,
  DefaultSuccessResponse,
} from "../types/requestTypes";

export const registration = async (
  data: UserInfo
): Promise<RegisterResponse | RequestError> => {
  try {
    const result = await ApiClient.publicPost<RegisterResponse>(
      "/auth/register",
      data
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const login = async (
  data: LoginData
): Promise<RegisterResponse | RequestError> => {
  try {
    const result = await ApiClient.publicPost<RegisterResponse>(
      "/auth/login",
      data
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getUserData = async (): Promise<
  GetUserDataResponse | RequestError
> => {
  try {
    const result = await ApiClient.post<GetUserDataResponse>("/auth/me", {});
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const tokenRefresh = async (): Promise<
  RegisterResponse | RequestError
> => {
  try {
    const result = await ApiClient.post<RegisterResponse>("/auth/refresh", {});
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const sendMessage = async (
  email: string,
  phone: string,
  name: string,
  message: string
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.publicPost<DefaultSuccessResponse>(
      "/call-request",
      { email, phone, name, message }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
