import React from "react";
import joinImg from "../images/join_banner.svg";
import quoteImg from "../images/quote_icon.svg";
import discordIcon from "../images/social/discord.svg";
import telegramIcon from "../images/social/telegram.svg";
import fbIcon from "../images/social/fb.svg";
import twitterIcon from "../images/social/twitter.svg";
import redditIcon from "../images/social/reddit.svg";
import vkIcon from "../images/social/vk.svg";

const CommunityPage: React.FC = () => {
  return (
    <div className="about-page-wrap">
      <div className="container">
        <div className="join-section">
          <div className="join-content">
            <div className="join-title">
              Присоединяйтесь к сообществу AltcoinsHub
            </div>
            <p>
              AltcoinsHub - это больше, чем крипто экосистема. Это динамичное
              глобальное сообщество, поддерживаемое пользователями из всех слоев
              общества, которые собираются в реальной жизни и в
              онлайн-пространстве, чтобы поделиться своими идеями и
              способствовать развитию криптовалюты.
            </p>
          </div>
          <img src={joinImg} alt="join" />
        </div>
        <div className="quote-section">
          <img src={quoteImg} alt="quote" />
          <p>
            Сообщество AltcoinsHub каждый день вдохновляет нас. Его участники
            поддерживают и мотивируют к достижению высоких целей. Сообщество
            AltcoinsHub - самое опытное и осведомленное в мире. Я люблю
            встречаться и общаться в чате с участниками нашего сообщества.
          </p>
          <p>Спасибо за вашу постоянную помощь и поддержку </p>
        </div>
        <div className="social-section">
          <div className="social-title">Социальные сети</div>
          <div className="social-row">
            <a href="https://www.altcoinshub.ru/">
              <img src={discordIcon} alt="" />
              <span>Discord</span>
            </a>
            <a href="https://www.altcoinshub.ru/">
              <img src={telegramIcon} alt="" />
              <span>Telegram</span>
            </a>
            <a href="https://www.altcoinshub.ru/">
              <img src={fbIcon} alt="" />
              <span>Facebook</span>
            </a>
            <a href="https://www.altcoinshub.ru/">
              <img src={twitterIcon} alt="" />
              <span>Twitter</span>
            </a>
            <a href="https://www.altcoinshub.ru/">
              <img src={redditIcon} alt="" />
              <span>Reddit</span>
            </a>
            <a href="https://www.altcoinshub.ru/">
              <img src={vkIcon} alt="" />
              <span>VK</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityPage;
