import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";

import { deleteUserStaking } from "../../services/apiStaking";
import type { AppDispatch, RootState } from "../../store/store";
import { RequestError } from "../../types/requestTypes";
import { handleNotifError } from "../../helpers/errorHandlers";
import { setUserData } from "../../store/slices/userData";

interface Props {
  show: boolean;
  id: number;
  amount: string;
  onHide: () => void;
  setActivePage: (e: number) => void;
}

const RefuseStakingModal: React.FC<Props> = ({
  show,
  onHide,
  id,
  setActivePage,
  amount,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true);
    const result = await deleteUserStaking(id);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
      setIsLoading(false);
    } else {
      Store.addNotification({
        message: "Накопления были успешно выведены",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      setActivePage(0);
      if (userInfo?.balance) {
        const newBalance =
          parseInt(userInfo.balance, 10) - parseInt(amount, 10);
        dispatch(
          setUserData({
            ...userInfo,
            balance: newBalance.toString(),
          })
        );
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Снятие накоплений
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">
          Вы уверены что хотите досрочно снять накопления стейкинга?
        </p>
        <div className="btns-group">
          <Button
            variant="success"
            onClick={(): void => {
              handleDelete();
            }}
          >
            {isLoading ? (
              <Oval
                height={30}
                width={30}
                color="#ffffff"
                visible
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Подтвердить"
            )}
          </Button>
          <Button variant="danger" onClick={onHide}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RefuseStakingModal;
