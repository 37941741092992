import ApiClient from "./ApiClient";
import {
  RequestError,
  GetUsersListResponse,
  AddNewUserResponse,
} from "../types/requestTypes";
import { UserInfo } from "../types/userDataTypes";

export const getUsersList = async (
  page?: number,
  search?: string
): Promise<GetUsersListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetUsersListResponse>(
      `/admin/users?page=${page || 1}&search=${search || ""}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const addNewUser = async (
  data: UserInfo
): Promise<AddNewUserResponse | RequestError> => {
  try {
    const result = await ApiClient.post<AddNewUserResponse>(
      "/admin/users",
      data
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const editUser = async (
  data: UserInfo,
  id: string
): Promise<AddNewUserResponse | RequestError> => {
  try {
    const result = await ApiClient.patch<AddNewUserResponse>(
      `/admin/users/${id}`,
      data
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteUser = async (
  id: string
): Promise<AddNewUserResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<AddNewUserResponse>(
      `/admin/users/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
