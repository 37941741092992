import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../../store/store";
import { getStakingList } from "../../store/slices/adminSteaking";

import DeleteStakingModal from "../modals/DeleteStakingModal";

const AdminStakingTab: React.FC = () => {
  const [activePage, setActivePage] = useState<number>(0);
  const [activeId, setActiveId] = useState<number>(0);
  const [isModalShow, setModalShow] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const stakingList = useSelector(
    (state: RootState) => state.adminSteaking.stakingList
  );
  const isLoading = useSelector(
    (state: RootState) => state.adminSteaking.listFetching
  );
  const totalPages = useSelector(
    (state: RootState) => state.adminSteaking.totalPages
  );

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getStakingList(e + 1));
  };

  const showModal = (): void => {
    setModalShow(true);
  };
  const hideModal = (): void => {
    setModalShow(false);
    setActiveId(0);
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Сумма</b>
            </th>
            <th>
              <b>Пользователь</b>
            </th>
            <th>
              <b>Проценты</b>
            </th>
            <th>
              <b>Дата начала</b>
            </th>
            <th>
              <b>Дата окончания</b>
            </th>
            <th>
              <b>Статус</b>
            </th>
            {/* eslint-disable-next-line */}
            <th />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            stakingList &&
            stakingList.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>
                  {el.amount} {el?.symbol || ""}
                </td>
                <td>
                  {el.first_name} {el.last_name} {el.surname} (ID{el.user_id})
                </td>
                <td>{el.percent}</td>
                <td>{el?.created_at}</td>
                <td>{el.completion_date}</td>
                <td>{el.completed ? "Завершен" : "Активен"}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={(): void => {
                      showModal();
                      setActiveId(el.id);
                    }}
                  >
                    Удалить
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      <DeleteStakingModal
        show={isModalShow}
        onHide={hideModal}
        id={activeId}
        activePage={activePage}
      />
    </>
  );
};

export default AdminStakingTab;
