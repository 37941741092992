import ApiClient from "./ApiClient";
import {
  RequestError,
  DefaultSuccessResponse,
  GetNotificationsListResponse,
} from "../types/requestTypes";

export const getNotificationsList = async (
  page?: number
): Promise<GetNotificationsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetNotificationsListResponse>(
      `/notifications?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const clearNotifications = async (): Promise<
  DefaultSuccessResponse | RequestError
> => {
  try {
    const result = await ApiClient.get<DefaultSuccessResponse>(
      `/notifications/read`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
