import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Store } from "react-notifications-component";
import { getCountries } from "react-phone-number-input";
import labels from "react-phone-number-input/locale/ru.json";
import downloadIcon from "../../images/icon/download.svg";
import { sendRequestFormData } from "../../services/apiRequestForm";
import { FormDataProps } from "../../types/requestForm";

const RequestForm: React.FC = () => {
  const [formData, setFormDataField] = useState<FormDataProps>({
    problem: "",
    email: "",
    name: "",
    company: "",
    country: "",
    court: "",
    date: "",
    lawsuit: "",
    account: "",
    confidentiality_order: "",
    title: "",
    content: "",
    "files[]": null,
  });

  const handleChange = (
    event: React.ChangeEvent<Pick<HTMLInputElement, "name" | "value">>
  ): void => {
    const { name, value } = event.target;

    setFormDataField({ ...formData, [name]: value.trimStart() });
  };

  const handleTextChange = (
    event: React.ChangeEvent<Pick<HTMLInputElement, "name" | "value">>
  ): void => {
    const { name, value } = event.target;

    const formattedValue = value.match(/^[a-zA-Zа-яЯ-я\s]+/gi)?.join("") || "";

    setFormDataField({ ...formData, [name]: formattedValue.trimStart() });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { files } = event.target;

    // @ts-ignore
    setFormDataField({ ...formData, "files[]": files });
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const response = await sendRequestFormData(data);
    console.log(response);

    if (response.code === 200) {
      Store.addNotification({
        message: response.message,
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    setFormDataField({
      problem: "",
      email: "",
      name: "",
      company: "",
      country: "",
      court: "",
      date: "",
      lawsuit: "",
      account: "",
      confidentiality_order: "",
      title: "",
      content: "",
      "files[]": null,
    });

    event.currentTarget.reset();
  };

  return (
    <div className="request_form_page">
      <div className="request_form_page_center">
        <h1 className="title">Отправить запрос</h1>

        <Form className="form" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              <sup>*</sup>
              Выберите проблему (юридическую)
            </Form.Label>

            <Form.Select
              required
              placeholder="Период"
              name="problem"
              value={formData.problem}
              onChange={handleTextChange}
            >
              <option
                defaultChecked
                value={undefined}
                label="Выберите проблему"
              />

              <option value="Постановление о замораживании">
                Постановление о замораживании
              </option>
              <option value="Постановление о раскрытии информации">
                Постановление о раскрытии информации
              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Ваш электронный адрес
            </Form.Label>

            <Form.Control
              required
              name="email"
              type="email"
              placeholder="Ваш электронный адрес"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Ваше имя
            </Form.Label>

            <Form.Control
              required
              type="text"
              name="name"
              placeholder="Ваше имя"
              value={formData.name}
              onChange={handleTextChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Название вашей компании
            </Form.Label>

            <Form.Control
              required
              type="text"
              name="company"
              placeholder="Название компании"
              value={formData.company}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Страна вынесения решения
            </Form.Label>

            <Form.Select
              required
              placeholder="Страна"
              name="country"
              value={formData.country}
              onChange={handleTextChange}
            >
              <option
                defaultChecked
                value={undefined}
                label="Выберите страну"
              />

              {getCountries().map((country) => (
                <option key={country} value={labels[country]}>
                  {labels[country]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Суд, вынесший постановление
            </Form.Label>

            <Form.Control
              required
              type="text"
              name="court"
              placeholder="Суд"
              value={formData.court}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Дата повторного слушания с участием обеих сторон (при
              необходимости)
            </Form.Label>

            <Form.Control
              required
              name="date"
              type="date"
              placeholder="Суд"
              value={formData.date}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Номер иска / ссылка
            </Form.Label>

            <Form.Control
              required
              type="text"
              name="lawsuit"
              placeholder="Номер иска / ссылка"
              value={formData.lawsuit}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Учетная запись, подлежащая замораживанию (имя
              владельца / UID / адрес электронной почты)
            </Form.Label>

            <Form.Control
              required
              type="text"
              name="account"
              placeholder="Учетная запись"
              value={formData.account}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Приказ о конфиденциальности судебного разбирательства
            </Form.Label>

            <div className="radio_wrapper">
              <Form.Check
                className="radio_input"
                required
                name="confidentiality_order"
                type="radio"
                label="Да"
                value="Да"
                checked={formData.confidentiality_order === "Да"}
                onChange={handleChange}
              />

              <Form.Check
                className="radio_input"
                required
                name="confidentiality_order"
                type="radio"
                label="Нет"
                value="Нет"
                checked={formData.confidentiality_order === "Нет"}
                onChange={handleChange}
              />
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Тема
            </Form.Label>

            <Form.Control
              required
              type="text"
              name="title"
              placeholder="Тема"
              value={formData.title}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <sup>*</sup>Описание
            </Form.Label>

            <Form.Control
              required
              name="content"
              as="textarea"
              rows={5}
              placeholder="Описание"
              value={formData.content}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="file">
            <Form.Label>
              <sup>*</sup>Вложения
            </Form.Label>

            <Button className="file_button">
              <Form.Label htmlFor="file">
                <img src={downloadIcon} alt="downloadIcon" />
                <span>Загрузить файл</span>
              </Form.Label>

              <input
                id="file"
                required
                multiple
                className="file_input"
                type="file"
                name="files[]"
                onChange={handleFileChange}
              />
            </Button>

            <div className="files_name_wrapper">
              {formData["files[]"] &&
                Array.from(formData["files[]"]).map((file, index) => (
                  <div key={index} className="file_name">
                    {/* @ts-ignore */}
                    <p>{file?.name}</p>
                  </div>
                ))}
            </div>
          </Form.Group>

          <p>
            В настоящее время к нам поступает большое количество запросов. Не
            отправляйте запрос повторно, чтобы ускорить решение проблемы.
            Благодарим вас за сотрудничество
          </p>

          <Button type="submit" className="submit_button">
            Отправить
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default RequestForm;
