import ApiClient from "./ApiClient";
import {
  RequestError,
  DefaultSuccessResponse,
  GetPayoutsListResponse,
} from "../types/requestTypes";

export const getPayoutsList = async (
  page?: number
): Promise<GetPayoutsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetPayoutsListResponse>(
      `/payouts?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getAdminPayoutsList = async (
  page?: number
): Promise<GetPayoutsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetPayoutsListResponse>(
      `/admin/payouts?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const changePayoutStatus = async (
  id: number,
  status: string
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>(
      `/admin/payouts/${id}/set-status`,
      {
        status,
      }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const addNewWithdrawalRequest = async (
  amount: string,
  payment_method_id: number,
  wallet: string
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>("/payouts", {
      amount,
      payment_method_id,
      wallet,
    });
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
