import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Oval } from "react-loader-spinner";

interface Props {
  show: boolean;
  amount?: string;
  isConfirmFetching: boolean;
  onHide: () => void;
  handleConfirm: (amount: string) => void;
}

const TransactionConfirmModal: React.FC<Props> = ({
  show,
  onHide,
  amount,
  isConfirmFetching,
  handleConfirm,
}) => {
  const [currentAmount, setAmount] = useState<string>("");

  useEffect(() => {
    if (amount) setAmount(amount);
  }, [amount]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Подтвердить транзакцию
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>Вы уверены что хотите подтвердить транзакцию?</p>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Сумма:</Form.Label>
          <Form.Control
            type="number"
            placeholder="Сумма"
            value={currentAmount}
            onChange={(e): void => setAmount(e.target.value)}
          />
        </Form.Group>
        <Button
          variant="success"
          className="mr-3"
          disabled={isConfirmFetching}
          onClick={(): void => {
            handleConfirm(currentAmount);
          }}
        >
          {isConfirmFetching ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "Подтвердить"
          )}
        </Button>
        <Button
          variant="danger"
          onClick={(): void => {
            onHide();
          }}
        >
          Отменить
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionConfirmModal;
