import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCoinsList } from "../../services/apiAdminCoins";
import { CoinItem } from "../../types/coinsType";
import { GetAdminCoinsListResponse } from "../../types/requestTypes";

export interface AdminCoinsState {
  coins: CoinItem[] | null;
  coinsFetching: boolean;
  totalPages: number;
}

const initialState: AdminCoinsState = {
  coins: null,
  coinsFetching: false,
  totalPages: 1,
};

export const getCoinsFunc = createAsyncThunk(
  "adminCoins/loadList",
  async (page?: number) => {
    const response = await getCoinsList(page);
    const { data } = response as GetAdminCoinsListResponse;
    return data;
  }
);

export const adminCoins = createSlice({
  name: "adminCoins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCoinsFunc.fulfilled,
      (
        state: AdminCoinsState,
        action: PayloadAction<{
          data: CoinItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.coins = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.coinsFetching = false;
      }
    );
    builder.addCase(getCoinsFunc.pending, (state: AdminCoinsState) => {
      state.coinsFetching = true;
    });
  },
});

export default adminCoins.reducer;
