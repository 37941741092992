import React from "react";
import ListItem from "../components/profileTabs/ListItem";

const LegalPolicyPage: React.FC = () => {
  return (
    <div className="legal-policy-wrapper">
      <div className="container legal-policy-wrapper-content">
        <h1 className="legal-policy-wrapper-title">Legal</h1>

        <div className="legal-info">
          <ListItem
            label="Licenses, Registrations and Other Legal Matters"
            path=""
          />

          <ListItem label="AltcoinsHub Terms of Use" path="/terms-for-use" />

          <ListItem label="AltcoinsHub Privacy Notice" path="" />

          <ListItem label="General Risk Warning" path="/general-risk" />
        </div>

        <h1 className="legal-policy-wrapper-title">Policy</h1>

        <div className="policy-info">
          <ListItem
            label="December 2022: AltcoinsHub provides response to Monetary Authority of Singapore consultation on Stablecoin-Related Activities"
            path=""
          />
          <ListItem
            label="AltcoinsHub provides consultation response to 
            the Financial Stability Board's proposed framework for international regulation of crypto-activities"
            path=""
          />
          <ListItem
            label="AltcoinsHub Response to Australian Treasury 
            consultation on  Crypto asset secondary service providers: Licensing and custody requirements >"
            path=""
          />
          <ListItem
            label="Second Consultation Response for Bank for International Settlements Prudential treatment of crypto asset exposures"
            path=""
          />
          <ListItem
            label="AltcoinsHub Response to UK HM Treasury Consultation: 
            Managing the failure of systemic Digital Settlement Asset (including stablecoin) firms consultation"
            path=""
          />
          <ListItem
            label="Amendments to Crypto-Asset (CRA) Module for the Central Bank of Bahrain"
            path=""
          />
          <ListItem
            label="OMFIF AltcoinsHub Contribution to the Digital Assets Regulation Report (Oct 2022)"
            path=""
          />
          <ListItem
            label="AltcoinsHub participated in the European Forum for Innovation Facilitators October 2022 EFIF meeting to discuss NFTs within the EU"
            path=""
          />
          <ListItem
            label="Reykjavik Global Forum Women Political Leaders panel on Reimagining Finance"
            path=""
          />
          <ListItem
            label="Stanford Global Crypto Policy Conference invited participation from AltcoinsHub"
            path=""
          />
          <ListItem
            label="Op-ed on Web3, Sensible Regulation, and Asia Pacific's Global Competitiveness for Milken"
            path=""
          />
          <ListItem
            label="AltcoinsHub Contribution to the OMFIF Future of Payments Report"
            path=""
          />
        </div>
      </div>
    </div>
  );
};

export default LegalPolicyPage;
