// Library
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactNotifications, Store } from "react-notifications-component";
// Types, data, services
import {
  loggedAction,
  setUserToken,
  setUserData,
} from "./store/slices/userData";
import type { RootState } from "./store/store";
import { tokenRefresh, getUserData } from "./services/apiLogin";
import { RegisterResponse, GetUserDataResponse } from "./types/requestTypes";
import { UserInfo } from "./types/userDataTypes";
// Styles
import "react-notifications-component/dist/theme.css";
import "./styles/template/bootstrap.css";
import "./styles/App.scss";
import "./styles/Responsive.scss";
import "./styles/template/style.css";
import "./styles/template/responsive.css";
// Components
import HomePage from "./pages/Home";
import CommunityPage from "./pages/CommunityPage";
import AboutPage from "./pages/AboutPage";
import StakingPage from "./pages/StakingPage";
import PasswordRecovery from "./pages/PasswordRecovery";
import Profile from "./pages/Profile";
import AdminPanel from "./pages/AdminPanel";
import TermsAndConditions from "./pages/TermsAndConditions";
import GeneralRiskWarningPage from "./pages/GeneralRiskWarningPage";
import AltcoinsHubPage from "./pages/AltcoinsHubPage";
import TermsForUse from "./pages/TermsForUse";
import LegalPolicyPage from "./pages/LegalPolicyPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SupportPage from "./pages/SupportPage";
import RequestForm from "./components/forms/RequestForm";

const App: React.FC = () => {
  const [isPageReady, setPageReady] = useState<boolean>(false);

  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const isUserLogged = useSelector((state: RootState) => state.user.isLogged);

  const handleLoadData = async (): Promise<void> => {
    const res = await getUserData();
    if (res.status !== "error") {
      const { data } = res as GetUserDataResponse;
      dispatch(setUserData(data as UserInfo));
      dispatch(loggedAction(true));
    } else {
      Store.addNotification({
        message: "Loading user data error",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      dispatch(loggedAction(false));
    }
  };
  const handleLoadToken = async (): Promise<void> => {
    const token = localStorage.getItem("token");
    dispatch(setUserToken(token || ""));
    const res = await tokenRefresh();
    if (res.status !== "error") {
      const regResult = res as RegisterResponse;
      dispatch(setUserToken(regResult.data.access_token as string));
      if (!userInfo.email) {
        await handleLoadData();
      }
    } else {
      Store.addNotification({
        message: "Loading user data error",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      dispatch(loggedAction(false));
    }
    setPageReady(true);
  };

  useEffect(() => {
    const isLogged = localStorage.getItem("isLogged");
    if (isLogged && JSON.parse(isLogged)) {
      handleLoadToken();
    }
    if (!isLogged || JSON.parse(isLogged) === false) {
      setPageReady(true);
      dispatch(loggedAction(false));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isUserLogged) {
      interval = setInterval(() => {
        handleLoadToken();
      }, 1000 * 60 * 50);
    }
    if (
      !isUserLogged &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/community" &&
      window.location.pathname !== "/about"
    ) {
      window.location.replace("/");
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [isUserLogged]);

  return (
    <BrowserRouter>
      {isPageReady ? (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/password-recovery" element={<PasswordRecovery />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin-panel" element={<AdminPanel />} />
            <Route path="/staking" element={<StakingPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/general-risk" element={<GeneralRiskWarningPage />} />
            <Route path="/altcoins-hub" element={<AltcoinsHubPage />} />
            <Route path="/terms-for-use" element={<TermsForUse />} />
            <Route path="/legal-and-policy" element={<LegalPolicyPage />} />
            <Route path="/support" element={<SupportPage />} />
            <Route path="/request-form" element={<RequestForm />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <div className="loader" />
      )}
      <ReactNotifications />
    </BrowserRouter>
  );
};

export default App;
