import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CirclesWithBar } from "react-loader-spinner";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../store/store";
import { getCoinsFunc } from "../store/slices/homeCoins";
import { CoinInfo } from "../types/coinsType";

import StakingModal from "./modals/StakingModal";
import CoinTableRow from "./CoinTableRow";

const CoinsTable: React.FC = () => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(0);
  const [activeName, setActiveName] = useState<string>("");
  const [activeId, setActiveId] = useState<number>();
  const [isAutoInvest, setAutoInvest] = useState<boolean>(false);
  const [paramsList, setParamsList] = useState<CoinInfo[]>([] as CoinInfo[]);

  const dispatch = useDispatch<AppDispatch>();

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const coinsList = useSelector((state: RootState) => state.homeCoins.coins);
  const isLoading = useSelector(
    (state: RootState) => state.homeCoins.coinsFetching
  );
  const totalPages = useSelector(
    (state: RootState) => state.homeCoins.totalPages
  );

  const showModal = (
    name: string,
    id: number,
    list: CoinInfo[],
    isAuto: boolean
  ): void => {
    setActiveName(name);
    setActiveId(id);
    setParamsList(list);
    setAutoInvest(isAuto);
    setModalShow(true);
  };
  const hideModal = (): void => {
    setModalShow(false);
    setActiveName("");
    setActiveId(undefined);
    setParamsList([]);
    setAutoInvest(false);
  };

  useEffect(() => {
    if (isLogged) dispatch(getCoinsFunc());
  }, [dispatch, isLogged]);

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getCoinsFunc(e + 1));
  };

  const toggleAutoinvest = (): void => {
    setAutoInvest(!isAutoInvest);
  };

  const changeActiveId = (id: number): void => {
    setActiveId(id);
  };

  return (
    <>
      <section className="flat-price-coin bg-browse">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="table-price">
                {isLoading ? (
                  <CirclesWithBar
                    height="100"
                    width="100"
                    color="#f1a619"
                    wrapperStyle={{ justifyContent: "center" }}
                    visible
                    ariaLabel="circles-with-bar-loading"
                  />
                ) : (
                  <table className="mb-3">
                    <thead>
                      <tr>
                        <th>
                          <b>Монета</b>
                        </th>
                        <th>
                          <b>Описание</b>
                        </th>
                        <th>
                          <b>APR (проценты)</b>
                        </th>
                        <th>
                          <b>Период (дней)</b>
                        </th>
                        <th>
                          <b>Автопродление</b>
                        </th>
                        {/* eslint-disable-next-line */}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {coinsList?.map((el) => (
                        <CoinTableRow
                          key={el.id}
                          item={el}
                          showModal={showModal}
                          name={el.name}
                        />
                      ))}
                    </tbody>
                  </table>
                )}
                {totalPages && totalPages > 1 && (
                  <div className="pagination-wrap">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="след >"
                      pageRangeDisplayed={5}
                      pageCount={totalPages}
                      previousLabel="< пред"
                      forcePage={activePage}
                      onPageChange={(e): void => {
                        handlePaginationChange(e.selected);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <StakingModal
        show={modalShow}
        onHide={hideModal}
        coin={activeName}
        id={activeId || 0}
        paramsList={paramsList}
        isAutoInvest={isAutoInvest}
        toggleAutoinvest={toggleAutoinvest}
        changeActiveId={changeActiveId}
      />
    </>
  );
};

export default CoinsTable;
