import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";

import type { AppDispatch } from "../../store/store";
import { getPaymentsFunc } from "../../store/slices/adminPayments";
import { addNewPayment, editPayment } from "../../services/apiPayment";
import { RequestError } from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";
import { PaymentItem } from "../../types/paymentTypes";

interface Props {
  show: boolean;
  isEdit?: boolean;
  data?: PaymentItem;
  activePage: number;
  onHide: () => void;
}

const PaymentModal: React.FC<Props> = ({
  show,
  onHide,
  activePage,
  isEdit,
  data,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditStage, setIsEditStage] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [wallet, setWallet] = useState<string>("");

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (data) {
      setWallet(data.wallet);
      setName(data.name);
    }
    if (isEdit) setIsEditStage(isEdit);
  }, [data, isEdit]);

  // Form handlers start
  const handleNameChange = (value: string): void => {
    setErrorMsg("");
    setName(value);
  };
  const handleWalletChange = (value: string): void => {
    setErrorMsg("");
    setWallet(value);
  };
  const handleError = (error: RequestError): void => {
    const errorString = handleFormError(error);
    setErrorMsg(errorString);
    setIsLoading(false);
  };
  const handleAddPayment = async (): Promise<void> => {
    const result =
      isEdit && data
        ? await editPayment(name, wallet, data.id)
        : await addNewPayment(name, wallet);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      Store.addNotification({
        message:
          isEdit && data
            ? "Платежный метод успешно обновлен"
            : "Платежный метод успешно добавлен",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      dispatch(getPaymentsFunc(activePage + 1));
    }
  };
  const handleFormSubmit = (): void => {
    handleAddPayment();
  };
  // Form handlers end

  // Check is form button disabled
  const isSubmitDisabled = (): boolean => {
    if (!name || !wallet) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Метод оплаты
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3 mt-3">
          <Form.Label>Название метода:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Название"
            value={name}
            disabled={isEditStage}
            onChange={(e): void => handleNameChange(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Кошелек:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Кошелек"
            value={wallet}
            disabled={isEditStage}
            onChange={(e): void => handleWalletChange(e.target.value)}
          />
        </Form.Group>
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        {!isEdit || (isEdit && !isEditStage) ? (
          <button
            className="default-btn"
            type="button"
            disabled={isSubmitDisabled() || isLoading}
            onClick={handleFormSubmit}
          >
            {isLoading ? (
              <Oval
                height={30}
                width={30}
                color="#ffffff"
                visible
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "отправить"
            )}
          </button>
        ) : (
          <button
            type="button"
            className="default-btn"
            onClick={(): void => {
              setIsEditStage(false);
            }}
          >
            Редактировать
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
