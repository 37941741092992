export interface TransactionItem {
  created_at: string;
  id: number;
  image: string;
  method: string;
  status: TransactionStatus;
  first_name: string;
  last_name: string;
  surname: string;
  amount: string;
  hash: string;
  user_id: number;
}

export enum TransactionStatus {
  paid = "paid",
  pending = "pending",
}
