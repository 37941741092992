import ApiClient from "./ApiClient";
import {
  RequestError,
  GetAdminCoinsListResponse,
  DefaultSuccessResponse,
  GetAdminCoinParamsResponse,
} from "../types/requestTypes";
import { CoinItem, CoinInfo } from "../types/coinsType";

export const getCoinsList = async (
  page?: number
): Promise<GetAdminCoinsListResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetAdminCoinsListResponse>(
      `/admin/crypto-coins?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const addNewCoin = async (
  data: CoinItem
): Promise<GetAdminCoinsListResponse | RequestError> => {
  const formData = new FormData();
  if (data.imgFile) {
    // eslint-disable-next-line
    formData.append("image", data.imgFile, data.imgFile.name as string);
  }
  formData.append("name", data.name);
  formData.append("symbol", data.symbol);
  formData.append("description", data.description);
  try {
    const result = await ApiClient.post<GetAdminCoinsListResponse>(
      "/admin/crypto-coins",
      formData
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const editCoin = async (
  data: CoinItem,
  id: number
): Promise<GetAdminCoinsListResponse | RequestError> => {
  try {
    const result = await ApiClient.patch<GetAdminCoinsListResponse>(
      `/admin/crypto-coins/${id}`,
      data
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteCoin = async (
  id: number
): Promise<GetAdminCoinsListResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<GetAdminCoinsListResponse>(
      `/admin/crypto-coins/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const getCoinParams = async (
  id: number
): Promise<GetAdminCoinParamsResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetAdminCoinParamsResponse>(
      `/admin/crypto-coins/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const addCoinParams = async (
  id: number,
  params: CoinInfo
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.post<DefaultSuccessResponse>(
      `/admin/crypto-coin-parameters`,
      { crypto_coin_id: id, params: [params] }
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const editCoinParams = async (
  data: CoinInfo,
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.patch<DefaultSuccessResponse>(
      `/admin/crypto-coin-parameters/${id}`,
      data
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteCoinParams = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<DefaultSuccessResponse>(
      `/admin/crypto-coin-parameters/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const changeCoinStatus = async (
  id: number
): Promise<GetAdminCoinParamsResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetAdminCoinParamsResponse>(
      `/admin/crypto-coins/${id}/active`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
