import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { RequestError } from "../../types/requestTypes";
import { handleNotifError } from "../../helpers/errorHandlers";
import { setAutoInvest } from "../../services/apiStaking";

interface Props {
  isAuto: number;
  id: number;
}

const InvestingCheckbox: React.FC<Props> = ({ isAuto, id }) => {
  const [isAutoinvest, setIsAutoinvest] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuto) {
      setIsAutoinvest(!!isAuto);
    }
    // eslint-disable-next-line
  }, [isAuto]); 

  const handleAutoInvest = async (): Promise<void> => {
    setIsLoading(true);
    const result = await setAutoInvest(id);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
    }
    setIsLoading(false);
  };

  const toggleAutoinvest = (): void => {
    setIsAutoinvest(!isAutoinvest);
    handleAutoInvest();
  };

  return (
    <Form.Check
      type="switch"
      className="param-ckeckbox"
      onChange={toggleAutoinvest}
      checked={isAutoinvest}
      disabled={isLoading}
    />
  );
};

export default InvestingCheckbox;
