import React, { useMemo } from "react";
import HomeInfo from "../components/HomeInfo";
import RateTable from "../components/RateTable";
import useScrollPosition from "../helpers/useScrollPosition";
import arrowUp from "../images/icon/up-arrow-icon.svg";

const HomePage: React.FC = () => {
  const scroll = useScrollPosition();

  const scrollToTop = (): void => {
    document.body.scrollTo(0, 0);
  };

  const isVisible = useMemo(() => {
    return scroll > 500;
  }, [scroll]);

  return (
    <>
      <HomeInfo />
      <RateTable />

      {isVisible && (
        <div onClick={scrollToTop} className="anchor">
          <img src={arrowUp} alt="arrowUp" />
        </div>
      )}
    </>
  );
};

export default HomePage;
