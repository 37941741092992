import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../../store/store";
import { getCoinsFunc } from "../../store/slices/adminCoins";

import { CoinItem } from "../../types/coinsType";
import { addNewCoin, editCoin } from "../../services/apiAdminCoins";
import { RequestError } from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";

interface Props {
  show: boolean;
  isEdit: boolean;
  data?: CoinItem;
  onHide: () => void;
  activePage: number;
}

const CoinsModal: React.FC<Props> = ({
  show,
  onHide,
  isEdit,
  data,
  activePage,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [formData, setFormData] = useState<CoinItem>({} as CoinItem);
  const [imgFile, setImgFile] = useState<File>();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setFormData(data || ({} as CoinItem));
  }, [data]);

  // Form handlers start
  const handleFormChange = (key: string, value: string, file?: File): void => {
    setErrorMsg("");
    setFormData({ ...formData, [key]: value });
    if (file) {
      setImgFile(file);
    }
  };
  const handleError = (error: RequestError): void => {
    const errorString = handleFormError(error);
    setErrorMsg(errorString);
    setIsLoading(false);
  };
  const addNewCoinHandler = async (): Promise<void> => {
    const result = await addNewCoin({
      imgFile,
      name: formData.name,
      symbol: formData.symbol,
      description: formData.description,
    });
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      Store.addNotification({
        message: "Монета была успешно добавлена",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      dispatch(getCoinsFunc(activePage + 1));
    }
  };
  const editCoinHandler = async (): Promise<void> => {
    const result = await editCoin(
      {
        name: formData.name,
        symbol: formData.symbol,
        description: formData.description,
      },
      (data?.id as number) || 0
    );
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      Store.addNotification({
        message: "Монета была успешно обновлена",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      dispatch(getCoinsFunc(activePage + 1));
    }
  };
  const handleFormSubmit = (): void => {
    setIsLoading(true);
    if (!isEdit) {
      addNewCoinHandler();
    } else {
      editCoinHandler();
    }
  };
  // Form handlers start

  // Check is form button disabled
  const isSubmitDisabled = (): boolean => {
    if (
      !isEdit &&
      (!formData.name || !formData.symbol || !formData.description || !imgFile)
    ) {
      return true;
    }
    return false;
  };
  const handleFileCancel = (): void => {
    setImgFile(undefined);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {isEdit ? "Редактировать монету" : "Добавить монету"}
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="login-form">
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Имя:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Имя"
              value={formData.name || ""}
              onChange={(e): void => handleFormChange("name", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Символ:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Символ"
              value={formData.symbol || ""}
              onChange={(e): void => handleFormChange("symbol", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Изображение:</Form.Label>
            <Form.Control
              type="file"
              placeholder="Изображение"
              onFocus={handleFileCancel}
              onChange={(e): void =>
                handleFormChange(
                  "img",
                  e.target.value,
                  // @ts-ignore
                  e.target.files[0] as File
                )
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Описание:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Описание"
              value={formData.description || ""}
              onChange={(e): void =>
                handleFormChange("description", e.target.value)
              }
            />
          </Form.Group>
        </div>
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          disabled={isSubmitDisabled() || isLoading}
          onClick={handleFormSubmit}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default CoinsModal;
