import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userData";
import adminUserListReducer from "./slices/adminUsersList";
import adminCoinsReducer from "./slices/adminCoins";
import homeCoinsReducer from "./slices/homeCoins";
import userStakingReducer from "./slices/userStaking";
import adminStakingReducer from "./slices/adminSteaking";
import userTransactionsReducer from "./slices/userTransactions";
import adminTransactionsReducer from "./slices/adminTransactions";
import adminPaymentsReducer from "./slices/adminPayments";
import userPayoutsReducer from "./slices/userPayouts";
import adminPayoutsReducer from "./slices/adminPayouts";
import adminCallsReducer from "./slices/adminCalls";

export const store = configureStore({
  reducer: {
    user: userReducer,
    userList: adminUserListReducer,
    adminCoins: adminCoinsReducer,
    homeCoins: homeCoinsReducer,
    userStaking: userStakingReducer,
    adminSteaking: adminStakingReducer,
    userTransactions: userTransactionsReducer,
    adminTransactions: adminTransactionsReducer,
    adminPayments: adminPaymentsReducer,
    userPayouts: userPayoutsReducer,
    adminPayouts: adminPayoutsReducer,
    adminCalls: adminCallsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
