import React, { useState } from "react";
import { Table, Button, Form } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState } from "../../store/store";
import { UserInfo } from "../../types/userDataTypes";

import DeleteUserModal from "../modals/DeleteUserModal";

interface Props {
  showUserModal: (e: boolean, data?: UserInfo) => void;
  activePage: number;
  setActivePage: (e: number) => void;
  searchValue: string;
  setSearchValue: (e: string) => void;
  clearSearch: () => void;
  handleSearch: () => void;
}

const UserTab: React.FC<Props> = ({
  showUserModal,
  activePage,
  setActivePage,
  searchValue,
  setSearchValue,
  clearSearch,
  handleSearch,
}) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [userIdDelete, setUserIdDelete] = useState<string>("");

  const usersList = useSelector((state: RootState) => state.userList.users);
  const totalPages = useSelector(
    (state: RootState) => state.userList.totalPages
  );
  const userId = useSelector((state: RootState) => state.user.userInfo?.id);
  const isLoading = useSelector(
    (state: RootState) => state.userList.usersFetching
  );

  const showModal = (id: string, name: string): void => {
    setModalShow(true);
    setUserIdDelete(id);
    setUserName(name);
  };
  const hideModal = (): void => {
    setModalShow(false);
    setUserIdDelete("");
    setUserName("");
  };

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
  };

  return (
    <>
      <Form className="d-flex mb-2">
        <Form.Control
          type="search"
          placeholder="Поиск"
          className="me-2 mr-2"
          value={searchValue}
          onChange={(e): void => {
            setSearchValue(e.target.value);
            if (e.target.value.length === 0) setActivePage(0);
          }}
          aria-label="Поиск"
        />
        <Button variant="outline-warning" onClick={handleSearch}>
          Поиск
        </Button>
        {searchValue && (
          <Button
            variant="outline-danger"
            className="ml-2"
            onClick={clearSearch}
          >
            Очистить
          </Button>
        )}
      </Form>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Имя</b>
            </th>
            <th>
              <b>Фамилия</b>
            </th>
            <th>
              <b>Отчество</b>
            </th>
            <th>
              <b>Почта</b>
            </th>
            <th>
              <b>Страна</b>
            </th>
            <th>
              <b>Телефон</b>
            </th>
            <th>
              <b>Баланс</b>
            </th>
            {/* eslint-disable-next-line */}
          <th className="admin-table-btns" />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            usersList &&
            usersList.map((el) => (
              <tr key={el.email}>
                <td>{el.id}</td>
                <td>{el.first_name}</td>
                <td>{el.last_name}</td>
                <td>{el.surname}</td>
                <td>{el.email}</td>
                <td>{el.country}</td>
                <td>{el.phone_number}</td>
                <td>{el.balance || ""}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={(): void => showUserModal(true, el)}
                  >
                    Редактировать
                  </Button>{" "}
                  {userId !== el.id && (
                    <Button
                      variant="danger"
                      onClick={(): void =>
                        showModal(el.id?.toString() || "", el.first_name)
                      }
                    >
                      Удалить
                    </Button>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      <DeleteUserModal
        name={userName}
        id={userIdDelete}
        show={modalShow}
        onHide={hideModal}
        setActivePage={setActivePage}
      />
    </>
  );
};

export default UserTab;
