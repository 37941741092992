import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCoinsList } from "../../services/apiHomeCoins";
import { CoinItem } from "../../types/coinsType";
import { GetAdminCoinsListResponse } from "../../types/requestTypes";

export interface HomeCoinsState {
  coins: CoinItem[] | null;
  coinsFetching: boolean;
  totalPages: number;
}

const initialState: HomeCoinsState = {
  coins: null,
  coinsFetching: false,
  totalPages: 1,
};

export const getCoinsFunc = createAsyncThunk(
  "homeCoins/loadList",
  async (page?: number) => {
    const response = await getCoinsList(page);
    const { data } = response as GetAdminCoinsListResponse;
    return data;
  }
);

export const homeCoins = createSlice({
  name: "homeCoins",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCoinsFunc.fulfilled,
      (
        state: HomeCoinsState,
        action: PayloadAction<{
          data: CoinItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.coins = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.coinsFetching = false;
      }
    );
    builder.addCase(getCoinsFunc.pending, (state: HomeCoinsState) => {
      state.coinsFetching = true;
    });
  },
});

export default homeCoins.reducer;
