import React from "react";
import welcomeImg from "../images/welcome_banner.svg";
import protectImg from "../images/protection_banner.svg";
import supportImg from "../images/support_icon.svg";
import icon1 from "../images/icon/security-1.png";
import icon2 from "../images/icon/mobile-app-1.png";
import icon3 from "../images/icon/wallet-1.png";
import icon4 from "../images/icon/insurance-1.png";
import icon5 from "../images/icon/exchange-1.png";
import icon6 from "../images/icon/buying.png";

const AboutPage: React.FC = () => {
  return (
    <div className="about-page-wrap">
      <div className="welcome-section">
        <div className="container">
          <div className="welcome-row">
            <div className="welcome-content">
              <div className="welcome-title">
                Добро пожаловать на AltcoinsHub
              </div>
              <p>
                В AltcoinsHub мы считаем, что каждый имеет право зарабатывать,
                хранить, тратить и отправлять свои средства, независимо от того,
                кто вы и откуда.
              </p>
              <p>
                Наша платформа работает круглосуточно и обеспечивает надёжность
                и безопасность проведения торговых операций. Мы обеспечиваем
                беспрерывную работу службы поддержки и осуществляем свою
                деятельность в соответствии с требованиями международных
                финансовых регуляторов.
              </p>
              <div className="welcome-contact">
                <img src={supportImg} alt="icon" />
                <div className="welcome-contact--txt">
                  <span>Поддержка клиентов</span>
                  <div>24/7</div>
                </div>
              </div>
            </div>
            <img src={welcomeImg} alt="banner" />
          </div>
          <div className="flat-counter">
            <div className="wrap-counter">
              <div className="row">
                <div className="col-md-3 col-6">
                  <div className="square center">
                    <div className="counter-box">
                      <div
                        className="numb-count"
                        data-from="0"
                        data-to="50"
                        data-speed="2000"
                        data-waypoint-active="yes"
                      >
                        50
                      </div>
                      <div className="text color-default"> ПРОЕКТОВ </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="square center">
                    <div className="counter-box">
                      <div
                        className="numb-count"
                        data-from="0"
                        data-to="4000"
                        data-speed="2000"
                        data-waypoint-active="yes"
                      >
                        4000
                      </div>
                      <div className="text color-default"> КЛИЕНТОВ </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="square center">
                    <div className="counter-box">
                      <div
                        className="numb-count"
                        data-from="0"
                        data-to="20"
                        data-speed="2000"
                        data-waypoint-active="yes"
                      >
                        20
                      </div>
                      <div className="text"> БЛОКЧЕЙНОВ </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="square center">
                    <div className="counter-box">
                      <div
                        className="numb-count"
                        data-from="0"
                        data-to="10000"
                        data-speed="2000"
                        data-waypoint-active="yes"
                      >
                        10000
                      </div>
                      <div className="text"> ТРАНЗАКЦИЙ </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mission-section">
        <div className="container">
          <div className="mission-title">Наша миссия</div>
          <div className="mission-txt">
            Инвестирование криптовалют и создание возможности заработка для
            наших клиентов
          </div>
        </div>
      </div>
      <section className="flat-why-choose style1 bg-browse">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top-title center">
                <h2>Наши преимущества</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon1} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>
                      Увеличения прибыли путём инвестирования в блокчейн
                    </span>
                  </h4>
                  <p>
                    Staking на нашем сайте является популярным инвестиционным
                    направлением.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon2} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Осуществления операций без комиссии</span>
                  </h4>
                  <p>
                    Платформа не берёт комиссию по инвестированию в блокчейн
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon3} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Проведения безопасных торговых операций</span>
                  </h4>
                  <p>
                    Деятельность нашей компании соответствует требованиям
                    международных финансовых регуляторов.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon4} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Реализации разных потребностей пользователя</span>
                  </h4>
                  <p>
                    При регистрации профиля возможно оформление различных видов
                    обслуживания.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon5} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Упрощения процедуры внесения и вывода средств</span>
                  </h4>
                  <p>
                    Платформа предоставляет возможность выбрать наиболее удобный
                    способ проведения операций со счётом.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon6} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Выберите удобный способ вноса средств</span>
                  </h4>
                  <p>
                    Выбирай оптимальный вариант вноса средств из 20 предлагаемых
                    на нашей платфоре
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="protect-section">
        <div className="container">
          <div className="protect-row">
            <div className="protect-content">
              <div className="protect-title">Защита пользователей</div>
              <p>
                Пользователь для нас всегда на первом месте. С самого начала
                защита пользователей была нашим главным приоритетом. Вот почему
                мы внедрили самые передовые инструменты безопасности и контроля
                конфиденциальности данных во всей экосистеме AltcoinsHub.
              </p>
            </div>
            <img src={protectImg} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
