import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getCallsList } from "../../services/apiAdminCalls";
import { CallItem } from "../../types/callsType";
import { GetCallsResponse } from "../../types/requestTypes";

export interface AdminCallsState {
  calls: CallItem[] | null;
  callsFetching: boolean;
  totalPages: number;
}

const initialState: AdminCallsState = {
  calls: null,
  callsFetching: false,
  totalPages: 1,
};

export const getCallsFunc = createAsyncThunk(
  "adminCalls/loadList",
  async (page?: number) => {
    const response = await getCallsList(page);
    const { data } = response as GetCallsResponse;
    return data;
  }
);

export const adminCalls = createSlice({
  name: "adminCalls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getCallsFunc.fulfilled,
      (
        state: AdminCallsState,
        action: PayloadAction<{
          data: CallItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.calls = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.callsFetching = false;
      }
    );
    builder.addCase(getCallsFunc.pending, (state: AdminCallsState) => {
      state.callsFetching = true;
    });
  },
});

export default adminCalls.reducer;
