import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import type { RootState } from "../store/store";

import ContactModal from "./modals/ContactModal";

import banner from "../images/banner_header-v2.svg";
import laptopImg from "../images/page/laptop-1.png";
import icon1 from "../images/icon/security-1.png";
import icon2 from "../images/icon/mobile-app-1.png";
import icon3 from "../images/icon/wallet-1.png";
import icon4 from "../images/icon/insurance-1.png";
import icon5 from "../images/icon/exchange-1.png";
import icon6 from "../images/icon/buying.png";
import iconHow1 from "../images/icon/account-1.png";
import iconHow2 from "../images/icon/payment-1.png";
import iconHow3 from "../images/icon/orders-1.png";
import howImg from "../images/banner_how-it-works.svg";

const HomeInfo: React.FC = () => {
  const isLogged = useSelector((state: RootState) => state.user.isLogged);

  const [showModal, setShowModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleModal = (): void => {
    setShowModal(!showModal);
  };

  const startBtnClick = (): void => {
    if (isLogged) {
      navigate("/staking");
    } else {
      // @ts-ignore
      document.querySelector(".login > a").click();
    }
  };
  return (
    <>
      <div
        className="main-banner"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="main-banner-container">
          <h1 className="main-banner-title">Начни зарабатывать уже сегодня</h1>
          <div className="main-banner-subtitle">
            Создай аккаунт, заполни информацию в личном кабинете и инвестируй,
            получая прибыль.
          </div>
          <div className="main-banner-btns">
            <button
              className="default-btn black-btn"
              type="button"
              onClick={startBtnClick}
            >
              начать
            </button>
            <button
              className="default-btn light"
              type="button"
              onClick={toggleModal}
            >
              связаться
            </button>
          </div>
        </div>
      </div>
      <section className="flat-recent-market">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="wrap-recent-text">
                <h2 className="title">О нас</h2>
                <div className="content-text">
                  <p>
                    Наша платформа работает круглосуточно и обеспечивает
                    надёжность и безопасность проведения торговых операций. Мы
                    обеспечиваем беспрерывную работу службы поддержки и
                    осуществляем свою деятельность в соответствии с требованиями
                    международных финансовых регуляторов.
                  </p>
                  <button
                    className="default-btn"
                    type="button"
                    onClick={startBtnClick}
                  >
                    начать
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-image center">
                <img src={laptopImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flat-why-choose style1 bg-browse">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top-title center">
                <h2>Почему стоит выбрать нас?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon1} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>
                      Увеличения прибыли путём инвестирования в блокчейн
                    </span>
                  </h4>
                  <p>
                    Staking на нашем сайте является популярным инвестиционным
                    направлением.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon2} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Осуществления операций без комиссии</span>
                  </h4>
                  <p>
                    Платформа не берёт комиссию по инвестированию в блокчейн
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon3} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Проведения безопасных торговых операций</span>
                  </h4>
                  <p>
                    Деятельность нашей компании соответствует требованиям
                    международных финансовых регуляторов.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon4} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Реализации разных потребностей пользователя</span>
                  </h4>
                  <p>
                    При регистрации профиля возможно оформление различных видов
                    обслуживания.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon5} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Упрощения процедуры внесения и вывода средств</span>
                  </h4>
                  <p>
                    Платформа предоставляет возможность выбрать наиболее удобный
                    способ проведения операций со счётом.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="iconbox style3 center">
                <div className="icon">
                  <img src={icon6} alt="" />
                </div>
                <div className="iconbox-content">
                  <h4>
                    <span>Выберите удобный способ вноса средств</span>
                  </h4>
                  <p>
                    Выбирай оптимальный вариант вноса средств из 20 предлагаемых
                    на нашей платфоре
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flat-our-work">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="top-title center">
                <h2>Как это работает</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="wrap-iconbox">
                <div className="iconbox inline-left style4">
                  <div className="icon">
                    <img src={iconHow1} alt="" />
                  </div>
                  <div className="number"> 1 </div>
                  <div className="iconbox-content">
                    <h4>
                      <a href="#/" title="">
                        Зарегистрируй профиль
                      </a>
                    </h4>
                    <p>
                      Заполни персональные данные в специальном регистрационном
                      окне.
                    </p>
                  </div>
                </div>
                <div className="iconbox inline-left style4">
                  <div className="icon">
                    <img src={iconHow2} alt="" />
                  </div>
                  <div className="number"> 2 </div>
                  <div className="iconbox-content">
                    <h4>
                      <a href="#/" title="">
                        Открой инвестиционный депозит
                      </a>
                    </h4>
                    <p>
                      Внеси первые инвестиции на личный счёт удобным для тебя
                      способом.
                    </p>
                  </div>
                </div>
                <div className="iconbox inline-left style4">
                  <div className="icon">
                    <img src={iconHow3} alt="" />
                  </div>
                  <div className="number"> 3 </div>
                  <div className="iconbox-content">
                    <h4>
                      <a href="#/" title="">
                        Осуществи первые торговые операции
                      </a>
                    </h4>
                    <p>
                      Заходи на нашу платформу, выбирай подходящий инструмент и
                      начинай зарабатывать.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-image">
                <img src={howImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flat-counter">
        <div className="container">
          <div className="wrap-counter">
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="square center">
                  <div className="counter-box">
                    <div
                      className="numb-count"
                      data-from="0"
                      data-to="50"
                      data-speed="2000"
                      data-waypoint-active="yes"
                    >
                      50
                    </div>
                    <div className="text color-default"> ПРОЕКТОВ </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="square center">
                  <div className="counter-box">
                    <div
                      className="numb-count"
                      data-from="0"
                      data-to="4000"
                      data-speed="2000"
                      data-waypoint-active="yes"
                    >
                      4000
                    </div>
                    <div className="text color-default"> КЛИЕНТОВ </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="square center">
                  <div className="counter-box">
                    <div
                      className="numb-count"
                      data-from="0"
                      data-to="20"
                      data-speed="2000"
                      data-waypoint-active="yes"
                    >
                      20
                    </div>
                    <div className="text"> БЛОКЧЕЙНОВ </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="square center">
                  <div className="counter-box">
                    <div
                      className="numb-count"
                      data-from="0"
                      data-to="10000"
                      data-speed="2000"
                      data-waypoint-active="yes"
                    >
                      10000
                    </div>
                    <div className="text"> ТРАНЗАКЦИЙ </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactModal show={showModal} onHide={toggleModal} />
    </>
  );
};

export default HomeInfo;
