import axios from "axios";

class ApiClient {
  private token = "";

  readonly baseURL: string = "";

  constructor() {
    this.baseURL = this.getBaseUrl();
  }

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string {
    return this.token;
  }

  async post<T>(
    url: string,
    dataProp: unknown,
    props: Record<string, unknown> = {}
  ): Promise<T> {
    const { data } = await axios.post<T>(url, dataProp, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async patch<T>(
    url: string,
    dataProp: unknown,
    props: Record<string, unknown> = {}
  ): Promise<T> {
    const { data } = await axios.put<T>(url, dataProp, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async publicPost<T>(
    url: string,
    dataProp: unknown,
    props: Record<string, unknown> = {}
  ): Promise<T> {
    const { data } = await axios.post<T>(url, dataProp, {
      headers: {
        Authorization: "",
        ...(props.headers ? { ...props.headers } : undefined)
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async get<T>(url: string, props?: Record<string, unknown>): Promise<T> {
    const { data } = await axios.get<T>(url, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      ...props,
    });

    return data;
  }

  async delete<T>(url: string, dataProp?: unknown): Promise<T> {
    const { data } = await axios({
      method: "DELETE",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      data: dataProp,
    });

    return data;
  }

  async put<T>(url: string, body: string | FormData): Promise<T> {
    const { data } = await axios({
      method: "PUT",
      url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.token}`,
      },
      baseURL: this.baseURL,
      data: typeof body === "string" ? JSON.parse(body) : body,
    });
    return data;
  }

  async getPublic<T>(url: string, props?: Record<string, unknown>): Promise<T> {
    const { data } = await axios.get<T>(url, {
      ...props,
      baseURL: this.baseURL,
    });

    return data;
  }

  private getBaseUrl(): string {
    return process.env.REACT_APP_API_URL as string;
  }
}

export default new ApiClient();
