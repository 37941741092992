import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import type { RootState, AppDispatch } from "../../store/store";
import { setUserData } from "../../store/slices/userData";
import { getStakingList } from "../../store/slices/userStaking";
import { makeStaking, setAutoInvest } from "../../services/apiStaking";
import { RequestError, StakeSuccessResponse } from "../../types/requestTypes";
import { handleNotifError, handleFormError } from "../../helpers/errorHandlers";
import { CoinInfo } from "../../types/coinsType";

interface Props {
  show: boolean;
  coin: string;
  id: number;
  paramsList: CoinInfo[];
  onHide: () => void;
  toggleAutoinvest: () => void;
  changeActiveId: (id: number) => void;
  isAutoInvest: boolean;
}

const StakingModal: React.FC<Props> = ({
  coin,
  show,
  onHide,
  id,
  paramsList,
  isAutoInvest,
  toggleAutoinvest,
  changeActiveId,
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isEarlyRepayment, setEarlyRepayment] = useState<boolean>(false);
  const [params, setParams] = useState<CoinInfo[]>();
  const [amount, setAmount] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [activeParam, setActiveParam] = useState<CoinInfo>();

  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  useEffect(() => {
    if (paramsList) {
      setParams(paramsList);
    }
    // eslint-disable-next-line
  }, [paramsList]);

  const changeActiveParam = (): void => {
    const filtered = paramsList.filter((el) => {
      return el.id === id;
    });
    setActiveParam(filtered[0]);
  };

  useEffect(() => {
    if (id) {
      changeActiveParam();
    }
    // eslint-disable-next-line
  }, [id]);
  const updateUserBalance = (): void => {
    if (userInfo?.balance) {
      const newBalance = parseInt(userInfo.balance, 10) - parseInt(amount, 10);
      dispatch(
        setUserData({
          ...userInfo,
          balance: newBalance.toString(),
        })
      );
    }
  };

  const handleAutoInvest = async (stakeId: number): Promise<void> => {
    const result = await setAutoInvest(stakeId);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
    }
  };

  // Form handlers start
  const toggleRepayment = (): void => {
    setEarlyRepayment(!isEarlyRepayment);
  };
  const handleAmountChange = (data: string): void => {
    setErrorMsg("");
    setAmount(data);
  };
  const handleMakeStake = async (): Promise<void> => {
    setIsFetching(true);
    const result = await makeStaking(
      (activeParam?.crypto_coin_id as number) || 0,
      (activeParam?.id as number) || 0,
      parseInt(amount, 10),
      isEarlyRepayment
    );
    if (result.status === "error") {
      setErrorMsg(handleFormError(result as RequestError));
    } else {
      onHide();
      Store.addNotification({
        message: "Запрос на стейкинг успешно принят",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      updateUserBalance();
      const { data } = result as StakeSuccessResponse;
      if (isAutoInvest && data.id) {
        handleAutoInvest(data.id as number);
      }
      dispatch(getStakingList());
    }
    setIsFetching(false);
  };
  // Form handlers end

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="staking-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Стейкинг {coin}
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="staking-form-wrap">
          <div className="staking-form-block">
            <div className="periods-wrap">
              <div className="period-title mb-2">
                <b>Период(дней):</b>
              </div>
              <div className="periods-row">
                {params?.map((el) => (
                  <div
                    key={el.id}
                    className={
                      activeParam?.id === el.id
                        ? "period-item active"
                        : "period-item"
                    }
                    onClick={(): void => {
                      changeActiveId(el.id as number);
                    }}
                  >
                    {el.period}
                  </div>
                ))}
              </div>
            </div>
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="mb-2">
                <b>Сумма:</b>
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Введите сумму"
                min={1}
                onChange={(e): void => handleAmountChange(e.target.value)}
                value={amount}
              />
            </Form.Group>
            <b className="mb-2">Лимиты: </b>
            <div className="limits-row mb-3">
              <div className="limits-item">
                Мин:
                <b>{activeParam?.min_amount}$</b>
              </div>
              <div className="limits-item">
                Макс:
                <b>{activeParam?.max_amount}$</b>
              </div>
            </div>
            <Form.Check
              type="switch"
              label="Автопродление"
              className="param-ckeckbox"
              onChange={toggleAutoinvest}
              checked={isAutoInvest}
            />
            <Form.Check
              type="switch"
              label="Досрочное снятие"
              className="param-ckeckbox"
              onChange={toggleRepayment}
              checked={isEarlyRepayment}
            />
            {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
          </div>
          <div className="staking-form-block">
            <b className="mb-3">Результат</b>
            <div className="dates-block">
              <div className="dates-item">
                Дата начала:
                <b>{moment().format("YYYY-MM-DD HH:MM")}</b>
              </div>
              <div className="dates-item">
                Дата окончания:
                <b>
                  {moment()
                    .add(activeParam?.period, "days")
                    .format("YYYY-MM-DD HH:MM")}
                </b>
              </div>
              {isAutoInvest && (
                <div className="dates-item">
                  Дата следующего взноса:
                  <b>
                    {moment()
                      .add(activeParam?.period, "days")
                      .format("YYYY-MM-DD HH:MM")}
                  </b>
                </div>
              )}
            </div>
            <div className="dates-item mt-3">
              ARP(проценты):
              <b>
                {isEarlyRepayment
                  ? activeParam?.interest_for_early_repayment
                  : activeParam?.percent}
                %
              </b>
            </div>
            <button
              className="default-btn mt-3"
              type="button"
              onClick={(): void => {
                handleMakeStake();
              }}
            >
              {isFetching ? (
                <Oval
                  height={30}
                  width={30}
                  color="#ffffff"
                  visible
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "отправить"
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default StakingModal;
