import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";

import { topUpBalance } from "../../services/apiTransactions";
import { getUserPaymentsList } from "../../services/apiPayment";
import {
  RequestError,
  GetPaymentsMethodsResponse,
} from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";
import { PaymentItem } from "../../types/paymentTypes";

interface Props {
  show: boolean;
  onHide: () => void;
}

const BalanceModal: React.FC<Props> = ({ show, onHide }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [hash, setHash] = useState<string>("");
  const [imgFile, setImgFile] = useState<File>();
  const [method, setMethod] = useState<PaymentItem>();
  const [options, setOptions] = useState<PaymentItem[]>([]);

  const handleFileCancel = (): void => {
    setImgFile(undefined);
  };
  const handleSentForm = async (): Promise<void> => {
    if (method && imgFile) {
      setIsLoading(true);
      const result = await topUpBalance(method.name, amount, hash, imgFile);
      if (result.status === "error") {
        setErrorMsg(handleFormError(result as RequestError));
      } else {
        Store.addNotification({
          message: "Запрос на пополнение баланса успешно отправлен",
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        onHide();
      }
      setIsLoading(false);
    }
  };

  const loadOptions = async (): Promise<void> => {
    const result = await getUserPaymentsList();
    const optionsData: PaymentItem[] = [];
    if (result.status !== "error") {
      const { data } = result as GetPaymentsMethodsResponse;
      setMethod(data[0]);
      data.forEach((el) => {
        optionsData.push(el);
      });
    }
    setOptions(optionsData);
  };

  useEffect(() => {
    loadOptions();
  }, []);

  const handleMethodChange = (id: string): void => {
    const filtered = options.filter((el) => {
      return el.id === parseInt(id, 10);
    });
    setMethod(filtered[0]);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Пополнение баланса
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="login-form">
          <Form.Group className="mb-3 mt-3">
            <Form.Label htmlFor="typeSelect">Метод платежа:</Form.Label>
            <Form.Select
              id="typeSelect"
              onChange={(e): void => {
                handleMethodChange(e.target.value);
              }}
            >
              {options.map((el, idx) => (
                <option key={`${el.id}${idx + 1}`} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {method && (
            <p className="mb-3">
              <b>Кошелек: </b>
              {method.wallet}
            </p>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Сумма:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Сумма"
              value={amount}
              onChange={(e): void => setAmount(e.target.value)}
            />
            <Form.Group className="mb-3 mt-3">
              <Form.Label>Хеш:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Хеш"
                value={hash}
                onChange={(e): void => setHash(e.target.value)}
              />
            </Form.Group>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Скриншот:</Form.Label>
            <Form.Control
              type="file"
              placeholder="Скриншот"
              onFocus={handleFileCancel}
              onChange={(e): void =>
                setImgFile(
                  // @ts-ignore
                  e.target.files[0] as File
                )
              }
            />
          </Form.Group>
        </div>
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          disabled={!method || !imgFile}
          onClick={(): void => {
            handleSentForm();
          }}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default BalanceModal;
