import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getAdminTransctionsList } from "../../services/apiTransactions";
import { TransactionItem } from "../../types/transactionsTypes";
import { GetTransactionsListResponse } from "../../types/requestTypes";

export interface AdminTransactionsState {
  transactionsList: TransactionItem[] | null;
  listFetching: boolean;
  totalPages: number;
}

const initialState: AdminTransactionsState = {
  transactionsList: null,
  listFetching: false,
  totalPages: 1,
};

export const getTransactionsList = createAsyncThunk(
  "adminTransactions/loadList",
  async (page?: number) => {
    const response = await getAdminTransctionsList(page);
    const { data } = response as GetTransactionsListResponse;
    return data;
  }
);

export const adminTransactions = createSlice({
  name: "adminTransactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getTransactionsList.fulfilled,
      (
        state: AdminTransactionsState,
        action: PayloadAction<{
          data: TransactionItem[];
          current_page: number;
          last_page: number;
        }>
      ) => {
        state.transactionsList = action.payload.data;
        state.totalPages = action.payload.last_page;
        state.listFetching = false;
      }
    );
    builder.addCase(
      getTransactionsList.pending,
      (state: AdminTransactionsState) => {
        state.listFetching = true;
      }
    );
  },
});

export default adminTransactions.reducer;
