import React from "react";
import { useNavigate } from "react-router-dom";
import linkArrow from "../../images/icon/linkArrow.svg";

interface ListItemProps {
  label: string;
  path: string;
}

const ListItem: React.FC<ListItemProps> = ({ label, path }) => {
  const navigate = useNavigate();

  const handleClick = (): void => {
    document.body.scrollTo(0, 0);

    navigate(path);
  };

  return (
    <div className="list-item" onClick={handleClick}>
      <span>{label}</span>

      <img src={linkArrow} alt="arrow" />
    </div>
  );
};

export default ListItem;
