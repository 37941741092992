import { RefObject, useEffect, useRef, useState } from "react";

const useOutsideClick = (initialIsVisible: boolean): {ref: RefObject<any>, isVisible: boolean, setIsVisible: (value: boolean) => void} => {
    const [isVisible, setIsVisible] = useState(initialIsVisible);
    const ref = useRef(null);
  
    const handleClickOutside = (event: MouseEvent): void => {
      const element = ref as RefObject<HTMLElement>;
  
      if (element.current && !element.current.contains(event.target as Node)) {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
  
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);
  
    return { ref, isVisible, setIsVisible };
  };

  export default useOutsideClick;