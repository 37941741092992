import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";
import { useDispatch } from "react-redux";

import type { AppDispatch } from "../../store/store";
import { getUsersFunc } from "../../store/slices/adminUsersList";
import { addNewUser, editUser } from "../../services/apiAdminUsers";
import { UserInfo } from "../../types/userDataTypes";
import { RequestError } from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";

interface Props {
  show: boolean;
  isEdit: boolean;
  data?: UserInfo;
  activePage: number;
  onHide: () => void;
}

const UserModal: React.FC<Props> = ({
  show,
  onHide,
  isEdit,
  data,
  activePage,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [formData, setFormData] = useState<UserInfo>({} as UserInfo);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setFormData(data || ({} as UserInfo));
  }, [data]);

  // Form handlers start
  const handleFormChange = (key: string, value: string): void => {
    setErrorMsg("");
    setFormData({ ...formData, [key]: value });
  };
  const handleError = (error: RequestError): void => {
    const errorString = handleFormError(error);
    setErrorMsg(errorString);
    setIsLoading(false);
  };
  const handleAddUser = async (): Promise<void> => {
    const result = await addNewUser(formData);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      Store.addNotification({
        message: "Пользователь успешно добавлен",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      dispatch(getUsersFunc({ page: activePage + 1 }));
    }
  };
  const handleEditUser = async (): Promise<void> => {
    const result = await editUser(formData, data?.id?.toString() || "");
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      Store.addNotification({
        message: "Пользователь успешно обновлен",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      onHide();
      setIsLoading(false);
      dispatch(getUsersFunc({ page: activePage + 1 }));
    }
  };
  const handleFormSubmit = (): void => {
    if (
      !formData.email.toLowerCase().match(
        // eslint-disable-next-line
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg("Введите валидную почту");
      return;
    }
    setIsLoading(true);
    if (!isEdit) {
      handleAddUser();
    } else {
      handleEditUser();
    }
  };
  // Form handlers end

  // Check is form button disabled
  const isSubmitDisabled = (): boolean => {
    if (
      !isEdit &&
      (!formData.email ||
        !formData.password ||
        !formData.country ||
        !formData.first_name ||
        !formData.last_name ||
        !formData.phone_number ||
        !formData.surname)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {isEdit ? "Изменение пользователяr" : "Добавление пользователя"}
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="login-form">
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Почта:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Почта"
              value={formData.email || ""}
              onChange={(e): void => handleFormChange("email", e.target.value)}
            />
          </Form.Group>
          {!isEdit && (
            <Form.Group className="mb-3">
              <Form.Label>Пароль:</Form.Label>
              <Form.Control
                type="password"
                placeholder="Пароль"
                value={formData.password || ""}
                onChange={(e): void =>
                  handleFormChange("password", e.target.value)
                }
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Имя:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Имя"
              value={formData.first_name || ""}
              onChange={(e): void =>
                handleFormChange("first_name", e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Фамилия:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Фамилия"
              value={formData.last_name || ""}
              onChange={(e): void =>
                handleFormChange("last_name", e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Отчество:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Отчество"
              value={formData.surname || ""}
              onChange={(e): void =>
                handleFormChange("surname", e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Телефон:</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Телефон"
              value={formData.phone_number || ""}
              onChange={(e): void =>
                handleFormChange("phone_number", e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Страна:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Страна"
              value={formData.country || ""}
              onChange={(e): void =>
                handleFormChange("country", e.target.value)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Баланс:</Form.Label>
            <Form.Control
              type="number"
              placeholder="Баланс"
              min={0}
              value={formData.balance || ""}
              onChange={(e): void =>
                handleFormChange("balance", e.target.value)
              }
            />
          </Form.Group>
        </div>
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          disabled={isSubmitDisabled() || isLoading}
          onClick={handleFormSubmit}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
