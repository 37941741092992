import React, { useState } from "react";
import { useSelector } from "react-redux";
import Table from "react-bootstrap/Table";

import type { RootState } from "../../store/store";

import BalanceModal from "../modals/BalanceModal";
import WithdrawalModal from "../modals/WithdrawalModal";

const InfoTab: React.FC = () => {
  const [isModalShow, setModalShow] = useState<boolean>(false);
  const [isWithModalShow, setWithModalShow] = useState<boolean>(false);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const showModal = (): void => {
    setModalShow(true);
  };
  const hideModal = (): void => {
    setModalShow(false);
  };
  const showWithModal = (): void => {
    setWithModalShow(true);
  };
  const hideWithModal = (): void => {
    setWithModalShow(false);
  };

  return (
    <>
      <Table bordered responsive>
        <tbody>
          <tr>
            <td>
              <b>Имя:</b>
            </td>
            <td>{userInfo.first_name}</td>
          </tr>
          <tr>
            <td>
              <b>Фамилия:</b>
            </td>
            <td>{userInfo.last_name}</td>
          </tr>
          <tr>
            <td>
              <b>Отчество:</b>
            </td>
            <td>{userInfo.surname}</td>
          </tr>
          <tr>
            <td>
              <b>Почта</b>
            </td>
            <td>{userInfo.email}</td>
          </tr>
          <tr>
            <td>
              <b>Телефон:</b>
            </td>
            <td>{userInfo.phone_number}</td>
          </tr>
          <tr>
            <td>
              <b>Страна:</b>
            </td>
            <td>{userInfo.country}</td>
          </tr>
          <tr>
            <td>
              <b>Баланс:</b>
            </td>
            <td>{userInfo.balance || 0}$</td>
          </tr>
        </tbody>
      </Table>
      <button className="default-btn mr-2" type="button" onClick={showModal}>
        пополнить баланс
      </button>
      <button className="default-btn" type="button" onClick={showWithModal}>
        вывести средства
      </button>
      <BalanceModal show={isModalShow} onHide={hideModal} />
      <WithdrawalModal show={isWithModalShow} onHide={hideWithModal} />
    </>
  );
};

export default InfoTab;
