import React from "react";

const GeneralRiskWarningPage: React.FC = () => (
  <div className="termsWrapper">
    <div className="terms_content">
      <h1>General Risk Warning</h1>

      <div>
        <h2 className="blockTitle">A. How to interpret this Risk Warning</h2>
        <br />

        <div>
          All terms used in this notice, which are defined in the AltcoinsHub
          Terms of Use (the “Terms of Use”), have the same meaning and
          construction as in the Terms of Use.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">B. AltcoinsHub Services</h2>
        <br />

        <div>
          This notice provides you with information about the risks associated
          with AltcoinsHub Services. Each AltcoinsHub Service has its own
          distinct risks. This notice provides a general description of the
          risks when you use AltcoinsHub Services,
          <br />
          <br />
          This notice does not explain all of the risks or how such risks relate
          to your personal circumstances. It is important that you fully
          understand the risks involved before making a decision to use
          AltcoinsHub Services.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">C. No Personal Advice</h2>
        <br />

        <div>
          We do not provide personal advice in relation to our products or
          services. We sometimes provide factual information, information about
          transaction procedures and information about the potential risks.
          However, any decision to use our products or services is made by you.
          No communication or information provided to you by AltcoinsHub is
          intended as, or shall be considered or construed as, investment
          advice, financial advice, trading advice, or any other sort of advice.
          You are solely responsible for determining whether any investment,
          investment strategy or related transaction is appropriate for you
          according to your personal investment objectives, financial
          circumstances and risk tolerance.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">D. No Monitoring</h2>
        <br />

        <div>
          AltcoinsHub is not your broker, intermediary, agent, or advisor and
          has no fiduciary relationship or obligation to you in connection with
          any trades or other decisions or activities undertaken by you using
          AltcoinsHub Services. We do not monitor whether your use of
          AltcoinsHub Services is consistent with your financial goals and
          objectives. It is up to you to assess whether your financial resources
          are adequate for your financial activity with us, and to your risk
          appetite in the products and services you use.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">E. No Tax, Regulatory or Legal Advice</h2>
        <br />

        <div>
          The taxation of Digital Assets is uncertain, and you are responsible
          for determining what taxes you might be liable to, and how they apply,
          when transacting through the AltcoinsHub Services. It is your
          responsibility to report and pay any taxes that may arise from
          transacting on the AltcoinsHub Services, and you acknowledge that
          AltcoinsHub does not provide legal or tax advice relative to these
          transactions. If you have any doubts about your tax status or
          obligations when using AltcoinsHub Services, or with respect to the
          Digital Assets held to the credit of your AltcoinsHub account, you may
          wish to seek independent advice.
          <br />
          <br />
          You acknowledge that, when, where and as required by applicable
          legislation, AltcoinsHub shall report information regarding your
          transactions, transfers, distributions or payments to tax or other
          public authorities. Similarly, when, where and as required by
          applicable law, AltcoinsHub shall withhold taxes related to your
          transactions, transfers, distributions or payments. Applicable
          legislation could also prompt AltcoinsHub to request you for
          additional tax information, status, certificates or documentation. You
          acknowledge that failure to answer these requests within the timeframe
          defined, may result in withholding taxes by AltcoinsHub, to be
          remitted to tax authorities as defined by relevant law. You are
          encouraged to seek professional and personal tax advice regarding the
          above and before making any digital asset transaction.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">F. Market Risks</h2>
        <br />

        <div>
          Digital Asset trading is subject to high market risk and price
          volatility. Changes in value may be significant and may occur rapidly
          and without warning. Past performance is not a reliable indicator of
          future performance. The value of an investment and any returns can go
          down as well as up, and you may not get back the amount you had
          invested.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">G. Liquidity risk</h2>
        <br />

        <div>
          Digital Assets may have limited liquidity which may make it difficult
          or impossible for you to sell or exit a position when you wish to do
          so. This may occur at any time, including at times of rapid price
          movements.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">H. Fees & Charges</h2>
        <br />

        <div>
          Our fees and charges are set out here. AltcoinsHub may, in its
          discretion, update the fees & charges from time to time. Please be
          aware of all costs and charges that apply to you, because such costs
          and charges will affect the gains you generate from using AltcoinsHub
          Services.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">I. Availability Risk</h2>
        <br />

        <div>
          We do not guarantee that the AltcoinsHub Services will be available at
          any particular time or that AltcoinsHub Services will not be subject
          to unplanned service outages or network congestion. It may not be
          possible for you to buy, sell, store, transfer, send or receive
          Digital Assets when you wish to do so.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">J. Third Party Risk</h2>
        <br />

        <div>
          Third parties, such as payment providers, custodians, and banking
          partners may be involved in the provision of AltcoinsHub Services. You
          may be subject to the terms & conditions of these third parties, and
          AltcoinsHub may not be responsible for any loss that these third
          parties may cause to you.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">K. Security Risk</h2>
        <br />

        <div>
          It is not possible for AltcoinsHub to eliminate all security risks.
          You are responsible for keeping your AltcoinsHub Account password
          safe, and you may be responsible for all the transactions under your
          AltcoinsHub Account, whether you authorised them or not. Transactions
          in Digital Assets may be irreversible, and losses due to fraudulent or
          unauthorised transactions may not be recoverable.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">L. Risks related to Digital Assets</h2>
        <br />

        <div>
          Given the nature of Digital Assets and their underlying technologies,
          there are a number of intrinsic risks, including but not limited to:
          <br />
          <br />
          a. faults, defects, hacks, exploits, errors, protocol failures or
          unforeseen circumstances occurring in respect of a Digital Asset or
          the technologies or economic systems on which the Digital Asset rely;
          <br />
          <br />
          b. transactions in Digital Assets being irreversible. Consequently,
          losses due to fraudulent or accidental transactions may not be
          recoverable;
          <br />
          <br />
          c. technological development leading to the obsolescence of a Digital
          Asset;
          <br />
          <br />
          d. delays causing a transactions not be settled on the scheduled
          delivery date; and
          <br />
          <br />
          e. attacks on the protocol or technologies on which a Digital Asset
          depends, including, but not limited to: i. distributed denial of
          service; ii. sybil attacks; iii. phishing; iv. social engineering; v.
          hacking; vi. smurfing; vii. malware; viii. double spending; ix.
          majority-mining, consensus-based or other mining attacks; x.
          misinformation campaigns; xi. forks; and xii. spoofing.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">M. Monitoring Risks</h2>
        <br />

        <div>
          Digital Asset markets are open 24 hours a day, 7 days a week. Rapid
          price changes may occur at any time, including outside of normal
          business hours.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">N. Communication Risks</h2>
        <br />

        <div>
          When you communicate with us via electronic communication, you should
          be aware that electronic communications can fail, can be delayed, may
          not be secure and/or may not reach the intended destination.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">O. Currency</h2>
        <br />

        <div>
          Currency exchange fluctuations will impact your gains and losses.
        </div>
      </div>
      <div>
        <h2 className="blockTitle">P. Legal Risk</h2>
        <br />

        <div>
          Changes in laws and regulations may materially affect the value of
          Digital Assets. This risk is unpredictable and may vary from market to
          market.
        </div>
      </div>
    </div>
  </div>
);

export default GeneralRiskWarningPage;
