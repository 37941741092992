import ApiClient from "./ApiClient";
import {
  RequestError,
  GetCallsResponse,
  DefaultSuccessResponse,
} from "../types/requestTypes";

export const getCallsList = async (
  page?: number
): Promise<GetCallsResponse | RequestError> => {
  try {
    const result = await ApiClient.get<GetCallsResponse>(
      `/admin/call-requests?page=${page || 1}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};

export const deleteCall = async (
  id: number
): Promise<DefaultSuccessResponse | RequestError> => {
  try {
    const result = await ApiClient.delete<DefaultSuccessResponse>(
      `/admin/call-requests/${id}`
    );
    return result;
  } catch (err) {
    return err as RequestError;
  }
};
