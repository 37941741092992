import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Oval } from "react-loader-spinner";

interface Props {
  show: boolean;
  isLoading: boolean;
  onHide: () => void;
  onSubmit: () => Promise<void>;
}

const DeleteTransactionModal: React.FC<Props> = ({
  show,
  onHide,
  isLoading,
  onSubmit,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Удаление транзакции
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">Вы уверены что хотите удалить транзакцию?</p>
        <div className="btns-group">
          <Button
            variant="success"
            onClick={(): void => {
              onSubmit();
            }}
          >
            {isLoading ? (
              <Oval
                height={30}
                width={30}
                color="#ffffff"
                visible
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              "Подтвердить"
            )}
          </Button>
          <Button variant="danger" onClick={onHide}>
            Отмена
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteTransactionModal;
