import React from "react";
import { NavLink } from "react-router-dom";
import joinImg from "../images/join_banner.svg";

const AltcoinsHubPage: React.FC = () => {
  return (
    <>
      <div className="altcoins-hub-wrapper">
        <div className="container">
          <div className="join-section">
            <div className="join-content">
              <div className="join-title">AltcoinsHub Legal</div>

              <p>
                Мы предвидим и решаем проблемы, связанные с системами
                криптовалюты и блокчейна, устанавливая стандарты для следующего
                этапа более широкого внедрения этих технологий.
              </p>
            </div>
            <img src={joinImg} alt="join" />
          </div>
        </div>
      </div>

      <div className="container altcoins-link-wrapper">
        <ul className="link-list">
          <li>
            <NavLink to="/terms-for-use" end>
              Постановления о замораживании и раскрытии информации
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AltcoinsHubPage;
