import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FallingLines } from "react-loader-spinner";

import { CoinItem, CoinInfo } from "../types/coinsType";
import { GetAdminCoinParamsResponse } from "../types/requestTypes";
import { getCoinParams } from "../services/apiHomeCoins";

interface Props {
  item: CoinItem;
  name: string;
  showModal: (
    name: string,
    id: number,
    list: CoinInfo[],
    isAutoInvest: boolean
  ) => void;
}

const CoinTableRow: React.FC<Props> = ({ item, name, showModal }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAutoInvest, setAutoInvest] = useState<boolean>(false);
  const [paramsList, setParamsList] = useState<CoinInfo[]>([] as CoinInfo[]);
  const [activeParam, setActiveParam] = useState<CoinInfo>();

  const loadParamsHandler = async (): Promise<void> => {
    setIsLoading(true);
    const result = await getCoinParams(item.id || 0);
    if (result.status !== "error") {
      const { data } = result as GetAdminCoinParamsResponse;
      setParamsList(data.parameters as CoinInfo[]);
      setActiveParam(data.parameters[0]);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (item.id) {
      loadParamsHandler();
    }
    // eslint-disable-next-line
  }, [item]);

  const changeActiveParam = (id: number): void => {
    const filtered = paramsList.filter((el) => {
      return el.id === id;
    });
    setActiveParam(filtered[0]);
  };

  const showClickHandler = (): void => {
    showModal(name, activeParam?.id as number, paramsList, isAutoInvest);
  };

  const toggleInvest = (): void => {
    setAutoInvest(!isAutoInvest);
  };

  return isLoading ? (
    <tr>
      <td colSpan={5}>
        <FallingLines color="#f1a619" width="100" visible />
      </td>
    </tr>
  ) : (
    <tr>
      <td className="name-cell">
        <img
          src={`${process.env.REACT_APP_IMG_URL as string}${
            (item.image as string) || ""
          }`}
          alt="img"
          width={40}
        />
        {item.symbol}
      </td>
      <td>{item.description}</td>
      <td>{activeParam?.percent}%</td>
      <td className="period-cell">
        <div className="periods-row">
          {paramsList.map((el) => (
            <div
              key={el.id}
              className={
                activeParam?.id === el.id ? "period-item active" : "period-item"
              }
              onClick={(): void => {
                changeActiveParam(el.id as number);
              }}
            >
              {el.period}
            </div>
          ))}
        </div>
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <Form.Check
          type="switch"
          className="param-ckeckbox"
          checked={isAutoInvest}
          onChange={toggleInvest}
        />
      </td>
      <td>
        {paramsList.length > 0 && (
          <button
            className="default-btn"
            type="button"
            onClick={showClickHandler}
          >
            Стейкинг
          </button>
        )}
      </td>
    </tr>
  );
};

export default CoinTableRow;
