import React, { useState, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { Store } from "react-notifications-component";

import {
  sendMail,
  sendCode,
  sendPassword,
} from "../services/apiPassworRecovery";
import { RequestError } from "../types/requestTypes";
import { handleFormError } from "../helpers/errorHandlers";

enum Stages {
  mail = "mail",
  code = "code",
  password = "password",
}

const PasswordRecovery: React.FC = () => {
  const [currentStage, setCurrentStage] = useState<Stages>(Stages.mail);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();

  const handleFormChange = (value: string): void => {
    setErrorMsg("");
    switch (currentStage) {
      case Stages.code:
        setCode(value);
        break;
      case Stages.password:
        setPassword(value);
        break;
      case Stages.mail:
      default:
        setEmail(value);
        break;
    }
  };

  const renderContent = (): ReactElement => {
    switch (currentStage) {
      case Stages.code:
        return (
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Код верификации:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Код верификации"
              onChange={(e): void => handleFormChange(e.target.value)}
              value={code}
            />
          </Form.Group>
        );
      case Stages.password:
        return (
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Пароль:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Введите новый пароль"
              onChange={(e): void => handleFormChange(e.target.value)}
              value={password}
            />
          </Form.Group>
        );
      case Stages.mail:
      default:
        return (
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Почта:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Почта"
              onChange={(e): void => handleFormChange(e.target.value)}
              value={email}
            />
          </Form.Group>
        );
    }
  };

  const handleError = (error: RequestError): void => {
    const errorString = handleFormError(error);
    setErrorMsg(errorString);
    setIsLoading(false);
  };
  const sendMailFunc = async (): Promise<void> => {
    const result = await sendMail(email);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      setCurrentStage(Stages.code);
      setIsLoading(false);
    }
  };
  const sendCodeFunc = async (): Promise<void> => {
    const result = await sendCode(email, code);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      setCurrentStage(Stages.password);
      setIsLoading(false);
    }
  };
  const sendPasswordFunc = async (): Promise<void> => {
    const result = await sendPassword(email, code, password);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      Store.addNotification({
        message: "Ваш пароль успешно обновлен",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      navigate("/");
      setIsLoading(false);
    }
  };
  const handleBtnClick = (): void => {
    switch (currentStage) {
      case Stages.code:
        if (!code) {
          setErrorMsg("Введите код");
          return;
        }
        setIsLoading(true);
        sendCodeFunc();
        break;
      case Stages.password:
        if (!password) {
          setErrorMsg("Введите пароль");
          return;
        }
        setIsLoading(true);
        sendPasswordFunc();
        break;
      case Stages.mail:
      default:
        if (!email) {
          setErrorMsg("Введите почту");
          return;
        }
        if (
          !email.toLowerCase().match(
            // eslint-disable-next-line
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          setErrorMsg("Введите валидную почту");
          return;
        }
        setIsLoading(true);
        sendMailFunc();
        break;
    }
  };

  return (
    <section className="password-recovery">
      <div className="container">
        <h1>Восстановление пароля</h1>
        {renderContent()}
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          disabled={isLoading}
          onClick={handleBtnClick}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </div>
    </section>
  );
};

export default PasswordRecovery;
