import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { CirclesWithBar } from "react-loader-spinner";

import type { RootState, AppDispatch } from "../../store/store";
import { getCallsFunc } from "../../store/slices/adminCalls";

import DeleteCallModal from "../modals/DeleteCallModal";

interface Props {
  activePage: number;
  setActivePage: (e: number) => void;
}

const AdminCallsTab: React.FC<Props> = ({ activePage, setActivePage }) => {
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [callName, setCallName] = useState<string>("");
  const [callId, setCallId] = useState<number>();

  const dispatch = useDispatch<AppDispatch>();

  const callsList = useSelector((state: RootState) => state.adminCalls.calls);
  const totalPages = useSelector(
    (state: RootState) => state.adminCalls.totalPages
  );
  const isLoading = useSelector(
    (state: RootState) => state.adminCalls.callsFetching
  );

  const showDeleteModal = (id: number, name: string): void => {
    setDeleteModalShow(true);
    setCallId(id);
    setCallName(name);
  };
  const hideDeleteModal = (): void => {
    setDeleteModalShow(false);
    setCallName("");
    setCallId(undefined);
  };

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getCallsFunc(e + 1));
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Имя</b>
            </th>
            <th>
              <b>Почта</b>
            </th>
            <th>
              <b>Телефон</b>
            </th>
            <th>
              <b>Сообщение</b>
            </th>
            <th>
              <b>Дата</b>
            </th>
            {/* eslint-disable-next-line */}
          <th className="admin-table-btns" />
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={7}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          )}
          {callsList &&
            !isLoading &&
            callsList?.map((el) => (
              <tr key={el.id}>
                <td>{el.id || ""}</td>
                <td>{el.name}</td>
                <td>{el.email}</td>
                <td>{el.phone}</td>
                <td>{el.message}</td>
                <td>{el.created_at.split("T")[0]}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={(): void => {
                      showDeleteModal((el?.id as number) || 0, el.name);
                    }}
                  >
                    Удалить
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      <DeleteCallModal
        show={deleteModalShow}
        onHide={hideDeleteModal}
        name={callName}
        id={callId || 0}
        setActivePage={setActivePage}
      />
    </>
  );
};

export default AdminCallsTab;
