export enum UserRoles {
  admin = "admin",
  user = "user",
}
export interface UserInfo {
  first_name: string;
  last_name: string;
  surname: string;
  email: string;
  phone_number: string;
  country: string;
  balance?: string;
  password?: string;
  banned?: number;
  created_at?: string;
  email_verified_at?: string | null;
  id?: number;
  recovery_code?: string | null;
  updated_at?: string;
  role?: UserRoles;
}
