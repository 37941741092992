import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  show: boolean;
  imgUrl: string;
  onHide: () => void;
}

const ImgModal: React.FC<Props> = ({ show, onHide, imgUrl }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Просмотр изображения
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="img-modal">
          <img src={imgUrl} alt="img" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImgModal;
