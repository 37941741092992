import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { CirclesWithBar } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../../store/store";
import { getTransactionsList } from "../../store/slices/userTransactions";

const TransactionsTab: React.FC = () => {
  const [activePage, setActivePage] = useState<number>(0);

  const transactionsList = useSelector(
    (state: RootState) => state.userTransactions.transactionsList
  );
  const isLoading = useSelector(
    (state: RootState) => state.userTransactions.listFetching
  );
  const totalPages = useSelector(
    (state: RootState) => state.userTransactions.totalPages
  );

  const dispatch = useDispatch<AppDispatch>();

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getTransactionsList(e + 1));
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Дата</b>
            </th>
            <th>
              <b>Метод</b>
            </th>
            <th>
              <b>Скриншот</b>
            </th>
            <th>
              <b>Статус</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={4}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            transactionsList &&
            transactionsList?.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.created_at.split("T")[0]}</td>
                <td>{el.method}</td>
                <td>
                  <img
                    src={`https://staking-back.webtoolteam.com/${
                      (el.image as string) || ""
                    }`}
                    alt="img"
                    width={40}
                  />
                </td>
                <td>{el.status}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
    </>
  );
};

export default TransactionsTab;
