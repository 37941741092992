import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { CirclesWithBar, Oval } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import type { RootState, AppDispatch } from "../../store/store";
import { getTransactionsList } from "../../store/slices/adminTransactions";
import { TransactionStatus } from "../../types/transactionsTypes";
import {
  confirmTransactions,
  deleteTransactions,
  setTransactionAmount,
} from "../../services/apiTransactions";
import { RequestError } from "../../types/requestTypes";
import { handleNotifError } from "../../helpers/errorHandlers";

import DeleteTransactionModal from "../modals/DeleteTransactionModal";
import TransactionConfirmModal from "../modals/TransactionConfirmModal";
import ImgModal from "../modals/ImgModal";

const AdminTransactionsTab: React.FC = () => {
  const [activePage, setActivePage] = useState<number>(0);
  const [activeId, setActiveId] = useState<number>(0);
  const [isConfirmFetching, setConfirmFetching] = useState<boolean>(false);
  const [isDeleteFetching, setDeleteFetching] = useState<boolean>(false);
  const [isModalShow, setModalShow] = useState<boolean>(false);
  const [isImgModalShow, setImgModalShow] = useState<boolean>(false);
  const [isTransactionModalShow, setTransactionModalShow] =
    useState<boolean>(false);
  const [activeImg, setActiveImg] = useState<string>("");
  const [activeAmount, setActiveAmount] = useState<string>("");
  const [activeConfirmId, setActiveConfirmId] = useState<number>(0);

  const transactionsList = useSelector(
    (state: RootState) => state.adminTransactions.transactionsList
  );
  const isLoading = useSelector(
    (state: RootState) => state.adminTransactions.listFetching
  );
  const totalPages = useSelector(
    (state: RootState) => state.adminTransactions.totalPages
  );

  const dispatch = useDispatch<AppDispatch>();

  const handlePaginationChange = (e: number): void => {
    setActivePage(e);
    dispatch(getTransactionsList(e + 1));
  };

  const handleConfirm = async (amount: string): Promise<void> => {
    setConfirmFetching(true);
    const result = await confirmTransactions(activeConfirmId);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
    } else {
      setActivePage(0);
      dispatch(getTransactionsList());
      await setTransactionAmount(activeConfirmId, amount);
    }
    setConfirmFetching(false);
    setTransactionModalShow(false);
  };
  const handleDelete = async (): Promise<void> => {
    setDeleteFetching(true);
    const result = await deleteTransactions(activeId);
    if (result.status === "error") {
      handleNotifError(result as RequestError);
    } else {
      setActivePage(0);
      dispatch(getTransactionsList());
    }
    setDeleteFetching(false);
    setModalShow(false);
  };

  const toggleModal = (id?: number): void => {
    setModalShow(!isModalShow);
    setActiveId(id || 0);
  };

  const showImgModal = (url: string): void => {
    setImgModalShow(true);
    setActiveImg(url);
  };
  const hideImgModal = (): void => {
    setImgModalShow(false);
    setActiveImg("");
  };
  const showTransactionModal = (amount: string, idx: number): void => {
    setTransactionModalShow(true);
    setActiveAmount(amount);
    setActiveConfirmId(idx);
  };
  const hideTransactionModal = (): void => {
    setTransactionModalShow(false);
    setActiveAmount("");
    setActiveConfirmId(0);
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>
              <b>ID</b>
            </th>
            <th>
              <b>Пользователь</b>
            </th>
            <th>
              <b>Дата</b>
            </th>
            <th>
              <b>Метод</b>
            </th>
            <th>
              <b>Сумма</b>
            </th>
            <th>
              <b>Хеш</b>
            </th>
            <th>
              <b>Скриншот</b>
            </th>
            <th>
              <b>Статус</b>
            </th>
            {/* eslint-disable-next-line */}
            <th />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={8}>
                <CirclesWithBar
                  height="100"
                  width="100"
                  color="#f1a619"
                  wrapperStyle={{ justifyContent: "center" }}
                  visible
                  ariaLabel="circles-with-bar-loading"
                />
              </td>
            </tr>
          ) : (
            transactionsList &&
            transactionsList?.map((el) => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>
                  {el.first_name} {el.last_name} {el.surname} (ID{el.user_id})
                </td>
                <td>{el.created_at.split("T")[0]}</td>
                <td>{el.method}</td>
                <td>{el.amount || ""}</td>
                <td>{el.hash || ""}</td>
                <td>
                  <div
                    onClick={(): void => {
                      showImgModal(
                        `https://staking-back.webtoolteam.com/${
                          (el.image as string) || ""
                        }`
                      );
                    }}
                  >
                    <img
                      src={`https://staking-back.webtoolteam.com/${
                        (el.image as string) || ""
                      }`}
                      alt="img"
                      width={40}
                    />
                  </div>
                </td>
                <td>{el.status}</td>
                <td>
                  {el.status !== TransactionStatus.paid && (
                    <Button
                      variant="success"
                      className="mr-3"
                      disabled={isConfirmFetching}
                      onClick={(): void => {
                        showTransactionModal(el.amount || "", el.id);
                      }}
                    >
                      {isConfirmFetching ? (
                        <Oval
                          height={30}
                          width={30}
                          color="#ffffff"
                          visible
                          ariaLabel="oval-loading"
                          secondaryColor="#ffffff"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      ) : (
                        "Подтвердить"
                      )}
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    onClick={(): void => {
                      toggleModal(el.id);
                    }}
                  >
                    Удалить
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {totalPages && totalPages > 1 && (
        <div className="pagination-wrap">
          <ReactPaginate
            breakLabel="..."
            nextLabel="след >"
            pageRangeDisplayed={5}
            pageCount={totalPages}
            previousLabel="< пред"
            forcePage={activePage}
            onPageChange={(e): void => {
              handlePaginationChange(e.selected);
            }}
          />
        </div>
      )}
      <DeleteTransactionModal
        show={isModalShow}
        isLoading={isDeleteFetching}
        onHide={toggleModal}
        onSubmit={handleDelete}
      />
      <ImgModal
        show={isImgModalShow}
        imgUrl={activeImg}
        onHide={hideImgModal}
      />
      <TransactionConfirmModal
        show={isTransactionModalShow}
        onHide={hideTransactionModal}
        amount={activeAmount}
        handleConfirm={(amount): void => {
          handleConfirm(amount);
        }}
        isConfirmFetching={isConfirmFetching}
      />
    </>
  );
};

export default AdminTransactionsTab;
