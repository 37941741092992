/* eslint-disable */
import React from "react";

const TermsAndConditions: React.FC = () => (
  <div className="termsWrapper">
    <div className="terms_content">
      <h1>AltcoinsHub Terms of Use</h1>

      <div>
        These AltcoinsHub Terms of Use is entered into between you (hereinafter
        referred to as “you” or “your”) and AltcoinsHub operators (as defined
        below). By accessing, downloading, using or clicking on “I agree” to
        accept any AltcoinsHub Services (as defined below) provided by
        AltcoinsHub (as defined below), you agree that you have read, understood
        and accepted all of the terms and conditions stipulated in these Terms
        of Use (hereinafter referred to as “these Terms”) as well as our Privacy
        Policy at. In addition, when using some features of the Services, you
        may be subject to specific additional terms and conditions applicable to
        those features.
        <br />
        <br />
        Please read the terms carefully as they govern your use of AltcoinsHub
        Services.
        <b>
          THESE TERMS CONTAIN IMPORTANT PROVISIONS INCLUDING AN ARBITRATION
          PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF LEGALLY
          BINDING ARBITRATION.
        </b>
        The terms of the arbitration provision are set forth in Article 10,
        “Resolving Disputes: Forum, Arbitration, Class Action Waiver”,
        hereunder. As with any asset, the values of Digital Currencies (as
        defined below) may fluctuate significantly and there is a substantial
        risk of economic losses when purchasing, selling, holding or investing
        in Digital Currencies and their derivatives.
        <b>
          BY MAKING USE OF AltcoinsHub SERVICES, YOU ACKNOWLEDGE AND AGREE THAT:
          (1) YOU ARE AWARE OF THE RISKS ASSOCIATED WITH TRANSACTIONS OF DIGITAL
          CURRENCIES AND THEIR DERIVATIVES; (2) YOU SHALL ASSUME ALL RISKS
          RELATED TO THE USE OF AltcoinsHub SERVICES AND TRANSACTIONS OF DIGITAL
          CURRENCIES AND THEIR DERIVATIVES; AND (3) AltcoinsHub SHALL NOT BE
          LIABLE FOR ANY SUCH RISKS OR ADVERSE OUTCOMES.
        </b>
        <br />
        <br />
        By accessing, using or attempting to use AltcoinsHub Services in any
        capacity, you acknowledge that you accept and agree to be bound by these
        Terms. If you do not agree, do not access AltcoinsHub or utilize
        AltcoinsHub services.
        <br />
        <br />
        If you would like to know more about the risks associated with investing
        in or trading Digital Assets, you can access our General Risk Warning
        at.
      </div>

      <div>
        <h2 className="blockTitle">I. Definitions</h2>
        <br />

        <div>
          1. <b>AltcoinsHub</b> refers to an ecosystem comprising AltcoinsHub
          websites (whose domain names include but are not limited to, mobile
          applications, clients, applets and other applications that are
          developed to offer AltcoinsHub Services, and includes
          independently-operated platforms, websites and clients within the
          ecosystem (e.g. AltcoinsHub’s Open Platform, AltcoinsHub Launchpad,
          AltcoinsHub Labs, AltcoinsHub Charity, AltcoinsHub DEX, AltcoinsHub X,
          JEX, Trust Wallet, and fiat gateways). In case of any inconsistency
          between relevant terms of use of the above platforms and the contents
          of these Terms, the respective applicable terms of such platforms
          shall prevail.
          <br />
          <br />
          2. <b>AltcoinsHub Accounts</b> refer to the foundational virtual
          accounts, including main accounts and subaccounts, which are opened by
          AltcoinsHub for Users to record on AltcoinsHub their usage of
          AltcoinsHub Services, transactions, asset changes and basic
          information. AltcoinsHub Accounts serve as the basis for Users to
          enjoy and exercise their rights on AltcoinsHub.
          <br />
          <br />
          3. <b>AltcoinsHub Fiat</b> Account means the record of your fiat
          currencies balance (if any), which is enabled through an electronic
          money wallet (or similar account) service that is provided by a Fiat
          Partner.
          <br />
          <br />
          4. <b>AltcoinsHub Operators</b> refer to all parties that run
          AltcoinsHub, including but not limited to legal persons (including
          AltcoinsHub UAB), unincorporated organizations and teams that provide
          AltcoinsHub Services and are responsible for such services. For
          convenience, unless otherwise stated, references to “AltcoinsHub” and
          “we” in these Terms specifically mean AltcoinsHub Operators.{" "}
          <b>
            UNDER THESE TERMS, AltcoinsHub OPERATORS MAY CHANGE AS AltcoinsHub’S
            BUSINESS ADJUSTS, IN WHICH CASE, THE CHANGED OPERATORS SHALL PERFORM
            THEIR OBLIGATIONS UNDER THESE TERMS WITH YOU AND PROVIDE SERVICES TO
            YOU, AND SUCH CHANGE DOES NOT AFFECT YOUR RIGHTS AND INTERESTS UNDER
            THESE TERMS. ADDITIONALLY, THE SCOPE OF AltcoinsHub OPERATORS MAY BE
            EXPANDED DUE TO THE PROVISION OF NEW AltcoinsHub SERVICES, IN WHICH
            CASE, IF YOU CONTINUE TO USE AltcoinsHub SERVICES, IT IS DEEMED THAT
            YOU HAVE AGREED TO JOINTLY EXECUTE THESE TERMS WITH THE NEWLY ADDED
            AltcoinsHub OPERATORS. IN CASE OF A DISPUTE, YOU SHALL DETERMINE THE
            ENTITIES BY WHICH THESE TERMS ARE PERFORMED WITH YOU AND THE
            COUNTERPARTIES OF THE DISPUTE, DEPENDING ON THE SPECIFIC SERVICES
            YOU USE AND THE PARTICULAR ACTIONS THAT AFFECT YOUR RIGHTS OR
            INTERESTS.
          </b>
          <br />
          <br />
          5. <b>AltcoinsHub Services refer</b> to various services provided to
          you by AltcoinsHub that are based on Internet and/or blockchain
          technologies and offered via AltcoinsHub websites, mobile
          applications, clients and other forms (including new ones enabled by
          future technological development). AltcoinsHub Services include but
          are not limited to such AltcoinsHub ecosystem components as Digital
          Asset Trading Platforms, the financing sector, AltcoinsHub Labs,
          AltcoinsHub Academy, AltcoinsHub Charity, AltcoinsHub Info,
          AltcoinsHub Launchpad, AltcoinsHub Research, AltcoinsHub Chain,
          AltcoinsHub X, AltcoinsHub Fiat Gateway, existing services offered by
          Trust Wallet and novel services to be provided by AltcoinsHub.
          <br />
          <br />
          6. <b>AltcoinsHub Platform Rules</b> refer to all rules,
          interpretations, announcements, statements, letters of consent and
          other contents that have been and will be subsequently released by
          AltcoinsHub, as well as all regulations, implementation rules, product
          process descriptions, and announcements published in the Help Center
          or within products or service processes.
          <br />
          <br />
          7. <b>BUSD</b> means AltcoinsHub USD, a US dollar denominated and
          backed stablecoin managed Paxos Trust Company, LLC.
          <br />
          <br />
          8. <b>Collateral Accounts</b> refer to special accounts opened by
          Users on AltcoinsHub to deposit and withdraw collateral (such as
          margins) in accordance with these Terms (including the AltcoinsHub
          Contract Services Agreement and AltcoinsHub Platform Rules), as
          required for contract transactions, leveraged trading and/or currency
          borrowing services.
          <br />
          <br />
          9. <b>Designated Stablecoin</b> means each of USD Coin (USDC), Pax
          Dollar (USDP), TrueUSD (TUSD) and any other Digital Asset AltcoinsHub
          may designate as such from time to time, provided that AltcoinsHub may
          remove any Digital Asset from the scope of this definition at any time
          without prior notice.
          <br />
          <br />
          10. <b>Designated Stablecoin Conversion</b> means, in respect of each
          Designated Stablecoin, the automatic conversion of that Designated
          Stablecoin into BUSD upon deposit or transfer into your AltcoinsHub
          Account or the conversion of BUSD into that Designated Stablecoin in
          connection with a withdrawal request.
          <br />
          <br />
          11. <b>Digital Currencies</b> refer to encrypted or digital tokens or
          cryptocurrencies with a certain value that are based on blockchain and
          cryptography technologies and are issued and managed in a
          decentralized form.
          <br />
          <br />
          12. <b>Digital Assets</b> refer to Digital Currencies, their
          derivatives or other types of digitalized assets with a certain value.
          <br />
          <br />
          13. <b>Fiat Partner</b> means any third party service provider, with
          which AltcoinsHub may partner in connection with any Fiat Services.
          <br />
          <br />
          14. <b>Fiat Services</b> means:
          <br />
          <br />
          (a) crediting your AltcoinsHub Fiat Account with one or more fiat
          currencies either via a bank transfer, or with your debit card or
          credit card;
          <br />
          (b) purchasing Digital Assets at spot prices with one or more fiat
          currencies by using either your AltcoinsHub Fiat Account balance, or
          your debit card or credit card;
          <br />
          (c) selling Digital Assets at spot prices for fiat currencies and
          crediting the corresponding proceeds of sale either to your
          AltcoinsHub Fiat Account, or to your debit card or credit card;
          <br />
          (d) withdrawing one or more fiat currencies from your AltcoinsHub Fiat
          Account either into your bank account, or to your debit card or credit
          card.
          <br />
          <br />
          15. <b>KYC</b> refers to the “know-your-customer” process that
          AltcoinsHub has put in place before entering into a business
          relationship or conducting transactions with its Users. As part of
          this process, AltcoinsHub may do anything that it deems necessary in
          order to identify Users, verify their identity, scrutinize and
          investigate User transactions, or comply with any applicable law or
          regulation.
          <br />
          <br />
          16. <b>Loan/Lending</b> refers to AltcoinsHubs lending of Digital
          Currencies to Users at an interest collected in certain ways (in the
          form of Digital Currencies), including but not limited to the
          leveraged trading and currency lending services currently offered, and
          other forms of loan/lending services to be launched by AltcoinsHub.
          <br />
          <br />
          17. <b>Spot Trading</b> means buying or selling supported Digital
          Assets and/or any supported fiat currency on the exchange for
          immediate settlement.
          <br />
          <br />
          18. <b>Users</b> refer to all individuals, institutions or
          organizations that access, download or use AltcoinsHub or AltcoinsHub
          Services and who meet the criteria and conditions stipulated by
          AltcoinsHub. If there exist other agreements for such entities as
          developers, distributors, market makers, and Digital Currencies
          exchanges, such agreements shall be followed.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">II. General Provisions</h2>
        <br />

        <div>
          1. <b>About These Terms</b>
          <br />
          <br />
          a. <b>Contractual Relationship</b>
          <br />
          <br />
          These Terms constitute a legal agreement and create a binding contract
          between you and AltcoinsHub Operators.
          <br />
          <br />
          b. <b>Supplementary Terms</b>
          <br />
          <br />
          Due to the rapid development of Digital Currencies and AltcoinsHub,
          these Terms between you and AltcoinsHub Operators do not enumerate or
          cover all rights and obligations of each party, and do not guarantee
          full alignment with needs arising from future development. Therefore,
          THE PRIVACY POLICY (), AltcoinsHub PLATFORM RULES, AND ALL OTHER
          AGREEMENTS ENTERED INTO SEPARATELY BETWEEN YOU AND AltcoinsHub ARE
          DEEMED SUPPLEMENTARY TERMS THAT ARE AN INTEGRAL PART OF THESE TERMS
          AND SHALL HAVE THE SAME LEGAL EFFECT. YOUR USE OF AltcoinsHub SERVICES
          IS DEEMED YOUR ACCEPTANCE OF THE ABOVE SUPPLEMENTARY TERMS.
          <br />
          <br />
          c. <b>Changes to These Terms</b>
          <br />
          <br />
          AltcoinsHub reserves the right to change or modify these Terms in its
          discretion at any time. AltcoinsHub will notify such changes by
          updating the terms on its website () and modifying the [Last revised]
          date displayed on this page. ANY AND ALL MODIFICATIONS OR CHANGES TO
          THESE TERMS WILL BECOME EFFECTIVE UPON PUBLICATION ON THE WEBSITE OR
          RELEASE TO USERS. THEREFORE, YOUR CONTINUED USE OF AltcoinsHub
          SERVICES IS DEEMED YOUR ACCEPTANCE OF THE MODIFIED AGREEMENT AND
          RULES. IF YOU DO NOT AGREE TO ANY CHANGES TO THESE TERMS, YOU MUST
          STOP USING AltcoinsHub SERVICES IMMEDIATELY. YOU ARE RECOMMENDED TO
          FREQUENTLY REVIEW THESE TERMS TO ENSURE YOUR UNDERSTANDING OF THE
          TERMS AND CONDITIONS THAT APPLY TO YOUR ACCESS TO AND USE OF
          AltcoinsHub SERVICES.
          <br />
          <br />
          d. <b>Prohibition of Use</b>
          <br />
          <br />
          <b>
            BY ACCESSING AND USING AltcoinsHub SERVICES, YOU REPRESENT AND
            WARRANT THAT YOU HAVE NOT BEEN INCLUDED IN ANY TRADE EMBARGOES OR
            ECONOMIC SANCTIONS LIST (SUCH AS THE UNITED NATIONS SECURITY COUNCIL
            SANCTIONS LIST), THE LIST OF SPECIALLY DESIGNATED NATIONALS
            MAINTAINED BY OFAC (THE OFFICE OF FOREIGN ASSETS CONTROL OF THE U.S.
            DEPARTMENT OF THE TREASURY), OR THE DENIED PERSONS OR ENTITY LIST OF
            THE U.S. DEPARTMENT OF COMMERCE. AltcoinsHub RESERVES THE RIGHT TO
            CHOOSE MARKETS AND JURISDICTIONS TO CONDUCT BUSINESS, AND MAY
            RESTRICT OR REFUSE, IN ITS DISCRETION, THE PROVISION OF AltcoinsHub
            SERVICES IN CERTAIN COUNTRIES OR REGIONS.
          </b>
          <br />
          <br />
          2. <b>About AltcoinsHub</b>
          <br />
          <br />
          As an important part of the AltcoinsHub Ecosystem, AltcoinsHub mainly
          serves as a global online platform for Digital Assets trading, and
          provides Users with a trading platform, financing services, technical
          services and other Digital Assets-related services. As further
          detailed in Article 3 below, Users must register and open an account
          with AltcoinsHub, and deposit Digital Assets into their account prior
          to trading. Users may, subject to the restrictions set forth in these
          Terms, apply for the withdrawal of Digital Assets.
          <br />
          <br />
          Although AltcoinsHub has been committed to maintaining the accuracy of
          the information provided through AltcoinsHub Services, AltcoinsHub
          cannot and does not guarantee its accuracy, applicability,
          reliability, integrity, performance or appropriateness, nor shall
          AltcoinsHub be liable for any loss or damage that may be caused
          directly or indirectly by your use of these contents. The information
          about AltcoinsHub Services may change without notice, and the main
          purpose of providing such information is to help Users make
          independent decisions. AltcoinsHub does not provide investment or
          consulting advice of any kind, and is not responsible for the use or
          interpretation of information on AltcoinsHub or any other
          communication medium. All Users of AltcoinsHub Services must
          understand the risks involved in Digital Assets trading, and are
          recommended to exercise prudence and trade responsibly within their
          own capabilities.
          <br />
          <br />
          3. <b>AltcoinsHub Account Registration and Requirements</b>
          <br />
          <br />
          a. <b>Registration</b>
          <br />
          <br />
          All Users must apply for a AltcoinsHub Account at
          (https://accounts.AltcoinsHub.com/en/register) before using
          AltcoinsHub Services. When you register a AltcoinsHub Account, you
          must provide the information identified in this paragraph 3 or
          otherwise as requested by AltcoinsHub, and accept these Terms, the
          Privacy Policy, and other AltcoinsHub Platform Rules. AltcoinsHub may
          refuse, in its discretion, to open a AltcoinsHub Account for you. You
          agree to provide complete and accurate information when opening a
          AltcoinsHub Account, and agree to timely update any information you
          provide to AltcoinsHub to maintain the integrity and accuracy of the
          information. Each User (including natural person, business or legal
          entity) may maintain only one main account at any given time. However,
          Users can open one or more subaccounts under the main account with the
          consent of AltcoinsHub. For certain AltcoinsHub Services, you may be
          required to set up a specific account independent from your
          AltcoinsHub Account, based on the provisions of these Terms or the
          Supplementary Terms. The registration, use, protection and management
          of such trading accounts are equally governed by the provisions of
          this Section and Section VI, unless otherwise stated in these Terms or
          the Supplementary Terms.
          <br />
          <br />
          b. <b>Eligibility</b>
          <br />
          <br />
          By registering to use a AltcoinsHub Account, you represent and warrant
          that: (i) as an individual, you are at least 18 or are of legal age to
          form a binding contract under applicable laws; (ii) as an individual,
          legal person, or other organization, you have full legal capacity and
          sufficient authorizations to enter into these Terms; (iii) you have
          not been previously suspended or removed from using the AltcoinsHub
          Platform or the AltcoinsHub Services; (iv) you do not have an existing
          AltcoinsHub Account; (v) you are not resident, located in or otherwise
          attempting to access the AltcoinsHub Platform or the AltcoinsHub
          Services from, or otherwise acting on behalf of a person or legal
          entity that is resident or located in, a Restricted Location. For the
          purposes hereof, “Restricted Location” shall include the United
          States, Malaysia, Ontario (Canada), and such other locations as
          designated by AltcoinsHub Operators from time to time as a “Restricted
          Location” for the purposes hereof; (vi) if you act as an employee or
          agent of a legal entity, and enter into these Terms on their behalf,
          you represent and warrant that you have all the necessary rights and
          authorizations to bind such legal entity and to access and use the
          AltcoinsHub Platform and AltcoinsHub Services on behalf of such legal
          entity; and (vii) your use of the AltcoinsHub Platform and the
          AltcoinsHub Services will not violate any and all laws and regulations
          applicable to you or the legal entity on whose behalf you are acting,
          including but not limited to regulations on anti-money laundering,
          anti-corruption, and counter-terrorist financing.
          <br />
          <br />
          Please note that there are legal requirements in various countries
          which may restrict the products and services that AltcoinsHub
          Operators can lawfully provide. Accordingly, some products and
          services and certain functionality within the AltcoinsHub Platform may
          not be available or may be restricted in certain jurisdictions or
          regions or to certain users. You shall be responsible for informing
          yourself about and observing any restrictions and/or requirements
          imposed with respect to the access to and use of the AltcoinsHub
          Platform and the AltcoinsHub Services in each country from which the
          AltcoinsHub Platform and the AltcoinsHub Services are accessed by you
          or on your behalf. AltcoinsHub Operators reserve the right to change,
          modify or impose additional restrictions with respect to the access to
          and use of the AltcoinsHub Platform and/the AltcoinsHub Services from
          time to time at their discretion at any time without prior
          notification.
          <br />
          <br />
          c. <b>User Identity Verification</b>
          <br />
          <br />
          Your registration of an account with AltcoinsHub will be deemed your
          agreement to provide required personal information for identity
          verification. Such information will be used to verify Users identity,
          identify traces of money laundering, terrorist financing, fraud and
          other financial crimes through AltcoinsHub, or for other lawful
          purposes stated by AltcoinsHub. We will collect, use and share such
          information in accordance with our Privacy Policy. In addition to
          providing such information, you agree to allow us to keep a record of
          that information during the period for which your account is active
          and within five (5) years after your account is closed, in compliance
          with global industry standards on data storage. You also authorize us
          to conduct necessary investigations directly or through a third party
          to verify your identity or protect you and/or us from financial
          crimes, such as fraud. The information we require to verify your
          identity may include, but is not limited to, your name, email address,
          contact information, phone number, username, government-issued ID,
          date of birth, and other information collected during account
          registration. When providing the required information, you confirm it
          is true and accurate.{" "}
          <b>
            AFTER REGISTRATION, YOU MUST ENSURE THAT THE INFORMATION IS TRUE,
            COMPLETE, AND TIMELY UPDATED WHEN CHANGED. IF THERE ARE ANY GROUNDS
            FOR BELIEVING THAT ANY OF THE INFORMATION YOU PROVIDED IS INCORRECT,
            FALSE, OUTDATED OR INCOMPLETE, AltcoinsHub RESERVES THE RIGHT TO
            SEND YOU A NOTICE TO DEMAND CORRECTION, DIRECTLY DELETE THE RELEVANT
            INFORMATION, AND, AS THE CASE MAY BE, TERMINATE ALL OR PART OF
            AltcoinsHub SERVICES WE PROVIDE FOR YOU. IF WE ARE UNABLE TO REACH
            YOU WITH THE CONTACT INFORMATION YOU PROVIDED, YOU SHALL BE FULLY
            LIABLE FOR ANY LOSS OR EXPENSE CAUSED TO AltcoinsHub DURING YOUR USE
            OF AltcoinsHub SERVICES. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU
            HAVE THE OBLIGATION TO UPDATE ALL THE INFORMATION IF THERE IS ANY
            CHANGE.BY REGISTERING AN ACCOUNT, YOU HEREBY AUTHORIZE AltcoinsHub
            TO CONDUCT INVESTIGATIONS THAT AltcoinsHub CONSIDERS NECESSARY,
            EITHER DIRECTLY OR THROUGH A THIRD PARTY, TO VERIFY YOUR IDENTITY OR
            PROTECT YOU, OTHER USERS AND/OR AltcoinsHub FROM FRAUD OR OTHER
            FINANCIAL CRIMES, AND TO TAKE NECESSARY ACTIONS BASED ON THE RESULTS
            OF SUCH INVESTIGATIONS. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR
            PERSONAL INFORMATION MAY BE DISCLOSED TO CREDIT BUREAUS AND AGENCIES
            FOR FRAUD PREVENTION OR FINANCIAL CRIME PREVENTION, WHICH MAY
            RESPOND TO OUR INVESTIGATIONS IN FULL.
          </b>
          <br />
          <br />
          d. <b>Account Usage Requirements</b>
          <br />
          <br />
          The AltcoinsHub Account can only be used by the account registrant.
          AltcoinsHub reserves the right to suspend, freeze or cancel the use of
          AltcoinsHub Accounts by persons other than account registrant. If you
          suspect or become aware of any unauthorized use of your username and
          password, you should notify AltcoinsHub immediately. AltcoinsHub
          assumes no liability for any loss or damage arising from the use of
          AltcoinsHub Account by you or any third party with or without your
          authorization.
          <br />
          <br />
          e. <b>Account Security</b>
          <br />
          <br />
          AltcoinsHub has been committed to maintaining the security of User
          entrusted funds, and has implemented industry standard protection for
          AltcoinsHub Services. However, the actions of individual Users may
          pose risks. You shall agree to treat your access credentials (such as
          username and password) as confidential information, and not to
          disclose such information to any third party. You also agree to be
          solely responsible for taking the necessary security measures to
          protect your AltcoinsHub Account and personal information.
          <br />
          <br />
          You should be solely responsible for keeping safe of your AltcoinsHub
          Account and password, and be responsible for all the transactions
          under your AltcoinsHub Account. AltcoinsHub assumes no liability for
          any loss or consequences caused by authorized or unauthorized use of
          your account credentials, including but not limited to information
          disclosure, information release, consent or submission of various
          rules and agreements by clicking on the website, online agreement
          renewal, etc.
          <br />
          <br />
          By creating a AltcoinsHub Account, you hereby agree that:
          <br />
          <br />
          i. you will notify AltcoinsHub immediately if you are aware of any
          unauthorized use of your AltcoinsHub Account and password or any other
          violation of security rules; ii. you will strictly abide by all
          mechanisms or procedures of AltcoinsHub regarding security,
          authentication, trading, charging, and withdrawal; and iii. you will
          take appropriate steps to logout from AltcoinsHub at the end of each
          visit.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">III. AltcoinsHub Services</h2>
        <br />

        <div>
          Upon completion of the registration and identity verification for your
          AltcoinsHub Account, you may use various AltcoinsHub Services,
          including but not limited to, Spot Trading, Fiat Services, contract
          trading, leveraged trading, AltcoinsHub Savings services, staking,
          acquiring market-related data, research and other information released
          by AltcoinsHub, participating in User activities held by AltcoinsHub,
          etc., in accordance with the provisions of these Terms (including
          AltcoinsHub Platform Rules and other individual agreements).
          AltcoinsHub has the right to:
          <br />
          <br />
          ● Provide, modify or terminate, in its discretion, any AltcoinsHub
          Services; and
          <br />
          ● Allow or prohibit some Users use of any AltcoinsHub Services in
          accordance with relevant AltcoinsHub Platform Rules.
          <br />
          <br />
          AltcoinsHub has the exclusive authority to determine which Digital
          Assets are listed on the platform and may add or remove Digital Assets
          from the platform in its sole discretion, from time to time.
          AltcoinsHub may also change the order size available for each Digital
          Asset. In respect of such additions, removals, or amendments,
          AltcoinsHub may, but is not obliged to, notify Users in advance and
          AltcoinsHub shall have no liability to Users in connection with such
          additions, removals or amendments.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">1. Service Usage Guidelines</h2>
        <br />

        <div>
          <b>a. License</b>
          <br />
          <br />
          Provided that you constantly comply with the express terms and
          conditions stated in these Terms, AltcoinsHub grants you a revocable,
          limited, royalty-free, non-exclusive, non-transferable, and
          non-sublicensable license to access and use AltcoinsHub Services
          through your computer or Internet compatible devices for your
          personal/internal purposes. You are prohibited to use AltcoinsHub
          Services for resale or commercial purposes, including transactions on
          behalf of other persons or entities. All the above actions are
          expressly prohibited and constitute a material violation of these
          Terms. The content layout, format, function and access rights
          regarding AltcoinsHub Services should be stipulated in the discretion
          of AltcoinsHub. AltcoinsHub reserves all rights not expressly granted
          in these Terms. Therefore, you are hereby prohibited from using
          AltcoinsHub Services in any way not expressly authorized by these
          Terms. These Terms only grant a limited license to access and use
          AltcoinsHub Services. Therefore, you hereby agree that when you use
          AltcoinsHub Services, AltcoinsHub does not transfer AltcoinsHub
          Services or the ownership or intellectual property rights of any
          AltcoinsHub intellectual property to you or anyone else. All the text,
          graphics, user interfaces, visual interface, photos, sounds, process
          flow diagrams, computer code (including html code), programs,
          software, products, information and documents, as well as the design,
          structure, selection, coordination, expression, look and feel, and
          layout of any content included in the services or provided through
          AltcoinsHub Services, are exclusively owned, controlled and/or
          licensed by AltcoinsHub Operators or its members, parent companies,
          licensors or affiliates. AltcoinsHub owns any feedback, suggestions,
          ideas, or other information or materials (hereinafter collectively
          referred to as “Feedback”) about AltcoinsHub or AltcoinsHub Services
          that you provide through email, AltcoinsHub Services, or other ways.
          You hereby transfer all rights, ownership and interests of the
          Feedback and all related intellectual property rights to AltcoinsHub.
          You have no right and hereby waive any request for acknowledgment or
          compensation based on any Feedback, or any modifications based on any
          Feedback.
          <br />
          <br />
          <b>b. Restriction</b>
          <br />
          <br />
          When you use AltcoinsHub Services, you agree and undertake to comply
          with the following provisions:
          <br />
          <br />
          i. During the use of AltcoinsHub Services, all activities you carry
          out should comply with the requirements of applicable laws and
          regulations, these Terms, and various guidelines of AltcoinsHub;
          <br />
          <br />
          ii. Your use of AltcoinsHub Services should not violate public
          interests, public morals, or the legitimate interests of others,
          including any actions that would interfere with, disrupt, negatively
          affect, or prohibit other Users from using AltcoinsHub Services;
          <br />
          <br />
          iii. You agree not to use the services for market manipulation (such
          as pump and dump schemes, wash trading, self-trading, front running,
          quote stuffing, and spoofing or layering, regardless of whether
          prohibited by law);
          <br />
          <br />
          iv. Without written consent from AltcoinsHub, the following commercial
          uses of AltcoinsHub data are prohibited:
          <br />
          <br />
          1) Trading services that make use of AltcoinsHub quotes or market
          bulletin board information.
          <br />
          <br />
          2) Data feeding or streaming services that make use of any market data
          of AltcoinsHub.
          <br />
          <br />
          3) Any other websites/apps/services that charge for or otherwise
          profit from (including through advertising or referral fees) market
          data obtained from AltcoinsHub.
          <br />
          <br />
          v. Without prior written consent from AltcoinsHub, you may not modify,
          replicate, duplicate, copy, download, store, further transmit,
          disseminate, transfer, disassemble, broadcast, publish, remove or
          alter any copyright statement or label, or license, sub-license, sell,
          mirror, design, rent, lease, private label, grant security interests
          in the properties or any part of the properties, or create their
          derivative works or otherwise take advantage of any part of the
          properties.
          <br />
          <br />
          vi. You may not (i) use any deep linking, web crawlers, bots, spiders
          or other automatic devices, programs, scripts, algorithms or methods,
          or any similar or equivalent manual processes to access, obtain, copy
          or monitor any part of the properties, or replicate or bypass the
          navigational structure or presentation of AltcoinsHub Services in any
          way, in order to obtain or attempt to obtain any materials, documents
          or information in any manner not purposely provided through
          AltcoinsHub Services; (ii) attempt to access any part or function of
          the properties without authorization, or connect to AltcoinsHub
          Services or any AltcoinsHub servers or any other systems or networks
          of any AltcoinsHub Services provided through the services by hacking,
          password mining or any other unlawful or prohibited means; (iii)
          probe, scan or test the vulnerabilities of AltcoinsHub Services or any
          network connected to the properties, or violate any security or
          authentication measures on AltcoinsHub Services or any network
          connected to AltcoinsHub Services; (iv) reverse look-up, track or seek
          to track any information of any other Users or visitors of AltcoinsHub
          Services; (v) take any actions that imposes an unreasonable or
          disproportionately large load on the infrastructure of systems or
          networks of AltcoinsHub Services or AltcoinsHub, or the infrastructure
          of any systems or networks connected to AltcoinsHub services; (vi) use
          any devices, software or routine programs to interfere with the normal
          operation of AltcoinsHub Services or any transactions on AltcoinsHub
          Services, or any other person’s use of AltcoinsHub Services; (vii)
          forge headers, impersonate, or otherwise manipulate identification, to
          disguise your identity or the origin of any messages or transmissions
          you send to AltcoinsHub, or (viii) use AltcoinsHub Services in an
          illegal way.
          <br />
          <br />
          By accessing AltcoinsHub Services, you agree that AltcoinsHub has the
          right to investigate any violation of these Terms, unilaterally
          determine whether you have violated these Terms, and take actions
          under relevant regulations without your consent or prior notice.
          Examples of such actions include, but are not limited to:
          <br />
          <br />
          ● Blocking and closing order requests;
          <br />
          ● Freezing your account;
          <br />
          ● Reporting the incident to the authorities;
          <br />
          ● Publishing the alleged violations and actions that have been taken;
          <br />
          ● Deleting any information you published that are found to be
          violations.
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">2. Spot Trading</h2>
        <br />

        <div>
          By making use of Spot Trading, you acknowledge and agree that you have
          read, understood and accepted the Spot Trading Terms of Use, which
          shall apply to all Spot Trading (as such term is defined in the Spot
          Trading Terms of Use).
        </div>
      </div>

      <div>
        <h2 className="blockTitle">3. Fiat Services</h2>
        <br />

        <div>
          3.1 By accepting these Terms, you acknowledge and agree that:
          <br />
          <br />
          you have read, accepted and will comply with these Terms, the Legal
          Statement, the Privacy Policy, and any other AltcoinsHub Platform
          Rules related to Fiat Services;
          <br />
          <br />
          AltcoinsHub may partner with any Fiat Partners and that, if
          applicable, you will open an account with such Fiat Partners by
          completing the relevant registration and identity verification for
          such account, and by accepting any user agreements, terms and
          conditions, policies and procedures or similar documents of any Fiat
          Partners;
          <br />
          <br />
          any payments expressed in any fiat currencies and which have been made
          in connection with any Fiat Services may not be canceled, recalled or
          refunded, and that any chargeback requests in connection with any Fiat
          Services that involve a Fiat Partner, are subject to that Fiat
          Partner`s policies and procedures.
          <br />
          <br />
          3.2 If:
          <br />
          <br />
          (a) you are resident or located in an EEA jurisdiction or in the
          United Kingdom, then all Fiat Services will be provided to you by
          Bifinity UAB; or
          <br />
          <br />
          (b) you use any Fiat Services, which involve a debit card or credit
          card, then (regardless of your residence or location) suchthe Fiat
          Services will be provided to you by Bifinity UAB,
          <br />
          <br />
          and in each case you will be required to read, accept and comply with
          the Terms of Use and Privacy Policy of Bifinity UAB. Bifinity UAB is a
          Lithuanian private limited company with registration number 305595206,
          which has its registered office at Lvivo g. 25-104, Vilnius,
          Lithuania, and which is registered with the Financial Crime
          Investigation Service (FCIS) in Lithuania as a virtual currency
          depository wallet operator and virtual currency exchange operator in
          accordance with the applicable laws of the Republic of Lithuania.
          Bifinity UAB is not a licensed financial services provider and
          cryptocurrency exchange and depository services provided by Bifinity
          UAB are not regulated by the Bank of Lithuania. Bifinity UAB may work
          in collaboration with regulated third party service providers to offer
          Fiat Services. A full list of Bifinity UAB’s partners can be found
          HERE.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">4. Futures Trading</h2>
        <br />

        <div>
          Unless otherwise specified by AltcoinsHub, to conduct Futures Trading,
          you must conclude with AltcoinsHub a separate AltcoinsHub Futures
          Service Agreement and open a special Collateral Account, following the
          completion of registration and identity verification for your
          AltcoinsHub Account. You acknowledge and agree that:
          <br />
          <br />
          a. You fully understand the high risks of Futures Trading, including
          but not limited to the risk of major fluctuations of Digital Assets in
          Futures Trading, and the risk of exacerbated adverse outcome when
          leverage is used;
          <br />
          <br />
          b. You have sufficient investment knowledge and experience and the
          capacity to take risks arising from Futures Trading, and agree to
          independently assume all the risks arising from the investment of
          Futures Trading;
          <br />
          <br />
          c. Before performing Futures Trading, you have read and understood all
          the contents of the AltcoinsHub Futures Service Agreement and the
          relevant AltcoinsHub Platform Rules, and have consulted relevant
          professionals to make informed decisions on whether and how to
          complete Futures Trading according to their recommendations and your
          own reasonable judgment;
          <br />
          <br />
          d. You agree and authorize AltcoinsHub to take various reasonable
          measures in its discretion (including but not limited to forced
          liquidation and forced position reduction under specific
          circumstances) in accordance with the AltcoinsHub Futures Service
          Agreement and the relevant AltcoinsHub Platform Rules to protect the
          legitimate interests of you, AltcoinsHub and other Users.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">5. Options Trading</h2>
        <br />

        <div>
          Before entering into transactions in Options products through
          AltcoinsHub (“Options Trading”), you acknowledge and agree that:
          <br />
          <br />
          a. You fully understand the high risks of Options Trading, including
          but not limited to the risk of major fluctuations of Digital Assets in
          Options Trading, and the risk of exacerbated adverse outcome when
          leverage is used;
          <br />
          <br />
          b. You have sufficient investment knowledge and experience and the
          capacity to take risks arising from Options Trading, and agree to
          independently assume all the risks arising from the investment of
          Options Trading;
          <br />
          <br />
          c. Before performing Options Trading, you have read and understood,
          and agree to be bound by the terms of, the AltcoinsHub Options Service
          Agreement and all relevant AltcoinsHub Platform Rules, which shall
          supplement these Terms of Use, and have consulted relevant
          professionals to make informed decisions on whether and how to
          complete Options Trading according to their recommendations and your
          own reasonable judgment;
          <br />
          <br />
          d. You agree and authorize AltcoinsHub to take various reasonable
          measures in its discretion (including but not limited to forced
          liquidation and forced position reduction under specific
          circumstances) in accordance with the AltcoinsHub Options Service
          Agreement and all relevant AltcoinsHub Platform Rules to protect the
          legitimate interests of you, AltcoinsHub and other Users.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">6. Margin Trading</h2>
        <br />

        <div>
          By making use of the Margin Services, you acknowledge and agree that
          you have read, understood and accepted the “Margin Service Terms of
          Use”, which shall apply to all Margin Services (as such term is
          defined in the Margin Service Terms of Use).
        </div>
      </div>

      <div>
        <h2 className="blockTitle">7. Lending Services</h2>
        <br />

        <div>
          Unless otherwise provided by AltcoinsHub, to borrow currencies, you
          must conclude with AltcoinsHub a separate Lending Services User
          Agreement and open a special Collateral Account and/or finish other
          relevant procedures, following the completion of registration and
          identity verification for your AltcoinsHub Account. You understand and
          agree that:
          <br />
          <br />
          a. There are considerable risks involved in Lending Services, which
          include without limitation to risks of fluctuation of the borrowed
          Digital Assets’ value, derivative risks and technical risks. You shall
          carefully consider and exercise clear judgment to evaluate your
          financial situation and the aforesaid risks to make any decision on
          using Lending Services, and you shall be responsible for all losses
          arising therefrom;
          <br />
          <br />
          b. you shall cooperate to provide the information and materials
          related to identity verification and Lending Services as required by
          AltcoinsHub, and be solely responsible for taking necessary security
          measures to protect the security of your Collateral Account and
          personal information;
          <br />
          <br />
          c. you shall carefully read relevant AltcoinsHub Platform Rules before
          using Lending Services, and be aware of, understand and observe the
          specific information and rules regarding the operations of Lending
          Services, and you undertake that the use of the assets borrowed shall
          conform to requirements of these Terms and related laws and
          regulations;
          <br />
          <br />
          d. AltcoinsHub has the full right to manage your Collateral Account
          and collateral during the period in which Lending Services are
          offered, and reserves the right, under the circumstances specified in
          the Lending Services User Agreement or these Terms, to implement
          various risk control measures, which include but are not limited to
          forced liquidation. Such steps may cause major losses to you and you
          shall be solely responsible for the outcomes of such measures;
          <br />
          <br />
          e. AltcoinsHub has the right to temporarily or permanently prohibit
          you from using Lending Services when it deems it necessary or
          reasonable, and to the maximum extent permitted by law, without
          liability of any kind to you.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">8. AltcoinsHub Savings Service</h2>
        <br />

        <div>
          AltcoinsHub offers AltcoinsHub Savings, a service to provide Users
          with value-added services for their idle Digital Assets. To use
          AltcoinsHub Savings service, you must conclude with AltcoinsHub a
          separate AltcoinsHub Savings Service User Agreement (
          ru/support/faq/bc5978ba2ac2456cbb91d13860d36134) and open a special
          AltcoinsHub Savings service account, following the completion of
          registration and identity verification for your AltcoinsHub Account.
          When using AltcoinsHub Savings service, you should note that:
          <br />
          <br />
          a. AltcoinsHub Savings assets will be used in cryptocurrency leveraged
          borrowing and other businesses.
          <br />
          <br />
          b. When you use AltcoinsHub Savings service, you will unconditionally
          authorize AltcoinsHub to distribute and grant the leveraged interest
          according to AltcoinsHub Platform Rules.
          <br />
          <br />
          c. You shall abide by relevant laws and regulations to ensure that the
          sources of Digital Assets are legitimate and compliant when using
          AltcoinsHub Savings service.
          <br />
          <br />
          d. When you use AltcoinsHub Savings service, you should fully
          recognize the risks of investing in Digital Assets and operate
          cautiously.
          <br />
          <br />
          e. You agree that all investment operations conducted on AltcoinsHub
          represent your true investment intentions and that unconditionally
          accept the potential risks and benefits of your investment decisions.
          <br />
          <br />
          f. AltcoinsHub reserves the right to suspend or terminate AltcoinsHub
          Savings service. If necessary, AltcoinsHub can suspend and terminate
          AltcoinsHub Savings service at any time.
          <br />
          <br />
          g. Due to network delay, computer system failures and other force
          majeure, which may lead to delay, suspension, termination or deviation
          of execution of AltcoinsHub Savings service, AltcoinsHub will use
          reasonable effort to ensure but not promise that AltcoinsHub Savings
          service execution system runs stably and effectively. AltcoinsHub does
          not take any responsibility if the final execution fails to match your
          expectations due to the above factors.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">9. Staking Programs</h2>
        <br />

        <div>
          AltcoinsHub will from time to time launch Staking Programs for
          specific types of Digital Currencies to reward, as per certain rules,
          users who hold such Digital Currencies in their AltcoinsHub Accounts.
          When participating in Staking Programs, you should note that:
          <br />
          <br />
          a. Unless otherwise stipulated by AltcoinsHub, Staking Programs are
          free of charge and Users may trade during the staking period;
          <br />
          <br />
          b. AltcoinsHub does not guarantee Users’ proceeds under any Staking
          Program;
          <br />
          <br />
          c. AltcoinsHub has the right to initiate or terminate Staking Program
          for any Digital Currencies or modify rules on such programs in its
          sole discretion;
          <br />
          <br />
          d. Users shall ensure that sources of the Digital Currencies they hold
          in AltcoinsHub Accounts are legal and compliant and undertake to
          observe related laws and regulations. Otherwise, AltcoinsHub has the
          right to take necessary steps in accordance with these Terms or
          AltcoinsHub Platform Rules, including, without limitation, freezing
          AltcoinsHub Accounts or deducting the Digital Currencies awarded to
          Users who violate the rules of respective Staking Programs.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">10. AltcoinsHub POS Service Agreement</h2>
        <br />

        <div>
          a. AltcoinsHub.com launched AltcoinsHub POS service for
          AltcoinsHub.com users to gain proceeds through idle cryptocurrency
          assets.
          <br />
          <br />
          b. AltcoinsHub POS assets will be used in cryptocurrency staking to
          gain proceeds and other businesses.
          <br />
          <br />
          c. When you use AltcoinsHub POS service, you will unconditionally
          authorize AltcoinsHub.com to distribute the staking interest according
          to the rules of the platform.
          <br />
          <br />
          d. You shall abide by the relevant laws of the State to ensure that
          the sources of assets are legitimate and compliant when using
          AltcoinsHub POS service.
          <br />
          <br />
          e. When you use AltcoinsHub POS service, you should fully recognize
          the risks of investment in cryptocurrency and operate cautiously.
          <br />
          <br />
          f. You agree that all investment operations conducted on
          AltcoinsHub.com represent your true investment intentions and that
          unconditionally accept the potential risks and benefits of your
          investment decisions.
          <br />
          <br />
          g. AltcoinsHub.com reserves the right to suspend or terminate
          AltcoinsHub POS service. If necessary, AltcoinsHub.com can suspend and
          terminate AltcoinsHub POS service at any time.
          <br />
          <br />
          h. Due to network delay, computer system failures and other force
          majeure, which may lead to delay, suspension or deviation of
          AltcoinsHub POS service execution, AltcoinsHub.com will use
          commercially reasonable effort to ensure but not promise that
          AltcoinsHub POS service execution system run stably and effectively.
          AltcoinsHub.com does not take any responsibility if the final
          execution doesn’t match your expectations due to the above factors.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">11. AltcoinsHub Convert Services</h2>
        <br />

        <div>
          a. Upon completion of the registration and identity verification for
          your AltcoinsHub Account, you may be able to make use of the
          AltcoinsHub Convert services, which enable Users to buy and sell
          Digital Assets based on prices provided by AltcoinsHub (the
          “AltcoinsHub Convert Services”). Pursuant to the AltcoinsHub Convert
          Services, Users may provide AltcoinsHub with a request to convert one
          Digital Asset into another Digital Asset or a particular Fiat currency
          into a Digital Asset (or vice versa), as applicable, based on
          supported trading pairs as available on the Platform (a “Conversion”),
          which AltcoinsHub may, in its sole discretion, elect to accept or
          reject. If accepted, a transaction will be entered into between
          AltcoinsHub and the User subject to the terms as set out in the
          remainder of this Clause.
          <br />
          <br />
          b. To request a Conversion between a supported trading pair through
          the Platform, Users may place (i) a market order, by specifying the
          type and amount of the Digital Asset or fiat currency (each an
          “Asset“) to be converted and the type of the Asset to be received
          pursuant to such Conversion (a “Convert Market Order“); and/or (ii) a
          limit order, by specifying the type and amount of the Asset to be
          converted and the type of Asset to be received pursuant to such
          Conversion, the limit price at which such Conversion is to take place
          and the date upon which the limit order is to expire (a “Convert Limit
          Order”). For the purposes of the AltcoinsHub Convert Services
          reference to “Platform” shall be to the digital platform made
          accessible via any website, page, feature, or content owned or
          operated by AltcoinsHub, any mobile application developed or provided
          by AltcoinsHub or any AltcoinsHub API Connection (as defined below)
          made available to Users or third-party applications relying on such
          AltcoinsHub API Connection or by such other means as AltcoinsHub may
          prescribe from time to time for the use of AltcoinsHub Convert
          Services.
          <br />
          <br />
          c. If a User places a Convert Market Order, AltcoinsHub may (but is
          not obliged to) provide a non-binding, indicative and discretionary
          quote for the conversion (a “Convert Quote”), which the User may
          accept or decline within such period of time as AltcoinsHub may in its
          sole discretion specify. It is the User’s responsibility to check the
          competitiveness of the price and decide whether to accept the Convert
          Quote or not within the specified time. User’s acceptance of the
          Convert Quote will result in the submission of a trade request to
          AltcoinsHub for AltcoinsHub to process the requested Conversion at the
          price provided in the Convert Quote (the “User Trade Request”).
          Submission of a User Trade Request authorizes AltcoinsHub to
          temporarily lock the requisite amount of the User’s Assets that are to
          be converted until the Conversion is completed (subject to
          AltcoinsHub’s right to reject any such User Trade Request as detailed
          further below).
          <br />
          <br />
          d. Users acceptance of a Convert Quote and submission of a User Trade
          Request does not obligate AltcoinsHub to process the requested
          Conversion on behalf of the User. AltcoinsHub may either accept or
          reject the User Trade Request at its sole discretion, depending on
          market conditions and other factors at the relevant time.
          <br />
          <br />
          e. If AltcoinsHub accept a User Trade Request, AltcoinsHub will
          guarantee the price of the requested Conversion (regardless of any
          price movement since the Convert Quote was provided) as well as the
          amounts of the relevant Assets that are to be Converted and provided
          to User pursuant to such Conversion. AltcoinsHub will settle the
          requested Conversion accordingly by debiting the User’s AltcoinsHub
          Account of the agreed amount of Assets to be converted and crediting
          the User’s AltcoinsHub Account with the agreed amount of Assets to be
          received pursuant to such Conversion. In most cases, the settlement
          process will complete within 10 seconds from the acceptance by
          AltcoinsHub of the relevant User Trade Request, however AltcoinsHub
          does not guarantee the settlement of the Conversion within this
          timeframe and in some instances the settlement process may take
          several hours or even longer, depending on various factors, including,
          without limitation, market conditions, technical issues, etc. Once a
          User Trade Request has been accepted by AltcoinsHub, , the User will
          not be permitted to cancel the requested Conversion or to transfer,
          withdraw, or otherwise trade the Assets that are the subject of such
          Conversion. AltcoinsHub shall not be held responsible for potential
          loss or opportunity cost in connection with any price change of the
          Asset subject to the Conversion during this settlement period.
          <br />
          <br />
          f. In respect of Convert Limit Orders, AltcoinsHub may accept or
          reject any Convert Limit Order in its sole discretion. If AltcoinsHub
          accepts a Convert Limit Order, AltcoinsHub does not guarantee that
          such Convert Limit Order will be filled , even if the market price of
          the relevant Asset subject to the Conversion hits or crosses the limit
          price set by the User pursuant to such Convert Limit Order. This is
          due to the fact that Convert Limit Orders are executed subject to
          market conditions and liquidity. AltcoinsHub shall not be held
          responsible for any potential loss or opportunity cost due to the
          failure to execute any Convert Limit Order in such case. If any
          Convert Market Order is executed, the provisions detailed in
          sub-clause (e) above with respect to the settlement of the relevant
          Conversion shall apply.
          <br />
          <br />
          g. AltcoinsHub has the exclusive authority to determine which Assets
          are listed on the Platform and are available for the purposes of any
          Conversion and may add or remove Assets and/or particular trading
          pairs from the Platform in its sole discretion, from time to time
          without prior notice. AltcoinsHub may also change the order size
          available for a Conversion with respect to a particular Asset. In
          respect of any such additions, removals, or changes, AltcoinsHub may,
          but is not obliged to, notify Users in advance and AltcoinsHub shall
          have no liability to Users in connection with such additions, removals
          or changes.
          <br />
          <br />
          h. It is recommended that Users conduct independent research into
          different Digital Assets prior to using AltcoinsHub Convert Services
          and that Users take steps to ensure they understand the risks
          associated with the trading of Digital Assets. Users bear full
          responsibility for ensuring that the AltcoinsHub Convert Services are
          suitable for them, taking into account their own financial objectives
          and circumstances, and shall be solely responsible for any profit or
          loss sustained in connection with their use of the AltcoinsHub Convert
          Services. AltcoinsHub does not make any representation of guarantee as
          to the outcome to be received by the trading of Digital Assets whether
          pursuant to the AltcoinsHub Convert Services or otherwise.
          <br />
          <br />
          If a User is accessing the AltcoinsHub Convert Services pursuant to an
          API connection provided to the User by AltcoinsHub (a “AltcoinsHub API
          Connection”), the User acknowledges and agrees as follows:
          <br />
          <br />
          i. AltcoinsHub does not currently support the submission of Convert
          Limit Orders through any AltcoinsHub API Connection. Accordingly, a
          AltcoinsHub API Connection may only be used for the purposes of
          submitting Convert Market Orders;
          <br />
          <br />
          ii. AltcoinsHub may change the endpoints of any AltcoinsHub API
          Connection from time to time and/or may change the settings of any
          AltcoinsHub API Connection, including to impose any particular trading
          limits and/or minimum order amounts with respect to specific Users, in
          each case at any time for any reason in its sole discretion and shall
          not be obliged to provide any prior notice to User of any such change;
          <br />
          <br />
          iii. AltcoinsHub may restrict or terminate a AltcoinsHub API
          Connection at any time for any reason in its sole discretion, for
          example, including (but not limited to) where AltcoinsHub suspects
          that the User is using the AltcoinsHub API Connection for the purposes
          of price arbitrage, market manipulation or is otherwise exploiting
          pricing provided by AltcoinsHub via the Binace API Connection for the
          purposes of gaining a market advantage, and AltcoinsHub is not obliged
          to provide any prior notice to User of any such restriction or
          termination or any reason therefor;
          <br />
          <br />
          iv. a AltcoinsHub API Connection is provided “as is” and is used by a
          User entirely at its own risk. AltcoinsHub shall not be liable for any
          error, malfunction, or unavailability of any AltcoinsHub API
          Connection or any losses incurred by a User or any third party in
          connection with the use of (or inability to use) any AltcoinsHub API
          Connection; and
          <br />
          <br />
          v. if User is redistributing any prices provided by AltcoinsHub
          through a AltcoinsHub API Connection, whether in relation to the
          AltcoinsHub Convert Services or otherwise, AltcoinsHub shall not be a
          party to, nor have any obligation with respect to, any transaction
          entered into by the User with any third party (including any customer
          of the User) on the basis of a price provided by AltcoinsHub. User
          shall be solely responsible for its transactions and relationships
          with third parties (including its own customers), including without
          limitation any obligations or duties arising under applicable law or
          regulation. To the extent that User applies any mark-up or spread to
          prices received from AltcoinsHub for the purposes of trading with its
          own customers, User shall be solely responsible for determining the
          amount of and appropriateness of any such mark-up or spread and
          AltcoinsHub shall have no involvement with, or liability in relation
          to, any such decision or other decision regarding transactions between
          the User and any customer of User or other third party. Unless
          otherwise agreed by AltcoinsHub in writing, User shall not identify
          AltcoinsHub as the source of any prices provided to User to any third
          party (including User’s own customers) nor use the name “AltcoinsHub”
          or any logo or symbol of AltcoinsHub in its own marketing,
          communications with third parties, websites or for any other purpose.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">12. AltcoinsHub Leveraged Tokens</h2>
        <br />

        <div>
          Before transacting in AltcoinsHub Leveraged Tokens, you acknowledge
          and agree that you have read, understood and accepted each of the
          AltcoinsHub Leveraged Tokens Risk Disclosure Statement and the
          AltcoinsHub Leveraged Tokens Agreement, which shall apply to all
          subscriptions to, redemptions of and transactions in AltcoinsHub
          Leveraged Tokens (as such term is defined in the AltcoinsHub Leveraged
          Tokens Agreement).
        </div>
      </div>

      <div>
        <h2 className="blockTitle">13. BUSD Conversion</h2>
        <br />

        <div>
          <u>A. Deposits</u>
          <br />
          <br />
          Unless you opt out of the Designated Stablecoin Conversion, when you
          deposit or receive a Designated Stablecoin into your AltcoinsHub
          Account, the amount received will automatically be converted into an
          equivalent amount of BUSD at a rate of 1 to 1.
          <br />
          <br />
          <u>B. Withdrawals</u> Unless you have opted out of the Designated
          Stablecoin Conversion, if you have a balance in your AltcoinsHub
          Account denominated in BUSD, you may give Instructions to withdraw
          such balance from your AltcoinsHub Account either in BUSD or in any of
          the Designated Stablecoins. Where you choose to withdraw a Designated
          Stablecoin, you will receive an amount equal to your balance in BUSD,
          converted into the relevant Designated Stablecoin at a rate of 1 to 1,
          minus any applicable withdrawal and transaction fees.
          <br />
          <br />
          <u>C. Exceptions</u>
          <br />
          <br />
          AltcoinsHub reserves the right to suspend or terminate the Designated
          Stablecoin Conversion for any or all Designated Stablecoins at any
          time without prior notice. Where necessary due to market conditions
          affecting one or more Designated Stablecoins, AltcoinsHub may adjust
          the conversion rate used for the Designated Stablecoin Conversion.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">14. AltcoinsHub OTC</h2>
        <br />

        <div>
          If agreed with AltcoinsHub, you may be permitted to receive certain
          over-the-counter (OTC) services with respect to bilateral transactions
          in Digital Assets, which services shall be subject to the AltcoinsHub
          OTC Terms. You agree to be bound by the AltcoinsHub OTC Terms with
          respect to all OTC Services provided to you through Agreed
          Communication Channels (as each term is defined in the AltcoinsHub OTC
          Terms).
        </div>
      </div>

      <div>
        <h2 className="blockTitle">IV. Liabilities</h2>
        <br />

        <div>
          1. Disclaimer of Warranties
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, AltcoinsHub
          SERVICES, AltcoinsHub MATERIALS AND ANY PRODUCT, SERVICE OR OTHER ITEM
          PROVIDED BY OR ON BEHALF OF AltcoinsHub ARE OFFERED ON AN “AS IS” AND
          “AS AVAILABLE” BASIS, AND AltcoinsHub EXPRESSLY DISCLAIMS, AND YOU
          WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OR
          WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR
          USAGE IN TRADE. WITHOUT LIMITING THE FOREGOING, AltcoinsHub DOES NOT
          REPRESENT OR WARRANT THAT THE SITE, AltcoinsHub SERVICES OR
          AltcoinsHub MATERIALS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
          ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          AltcoinsHub DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED,
          ACCEPTED, RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS,
          AGREEMENTS AND RULES SET FORTH IN THESE TERMS, YOU HEREBY ACKNOWLEDGE
          AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR
          AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND
          ACCESS OF AltcoinsHub SERVICES. WITHOUT LIMITING THE FOREGOING, YOU
          HEREBY UNDERSTAND AND AGREE THAT AltcoinsHub WILL NOT BE LIABLE FOR
          ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO: (A) ANY
          INACCURACY, DEFECT OR OMISSION OF DIGITAL ASSETS PRICE DATA, (B) ANY
          ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C) INTERRUPTION IN
          ANY SUCH DATA, (D) REGULAR OR UNSCHEDULED MAINTENANCE CARRIED OUT BY
          AltcoinsHub AND SERVICE INTERRUPTION AND CHANGE RESULTING FROM SUCH
          MAINTENANCE, (E) ANY DAMAGES INCURRED BY OTHER USERS’ ACTIONS,
          OMISSIONS OR VIOLATION OF THESE TERMS, (F) ANY DAMAGE CAUSED BY
          ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS WITHOUT AUTHORIZED
          BY AltcoinsHub; AND (G) OTHER EXEMPTIONS MENTIONED IN DISCLAIMERS AND
          PLATFORM RULES ISSUED BY AltcoinsHub.
          <br />
          <br />
          THE DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF
          AND TO THE EXTENT IT IS PROHIBITED BY APPLICABLE LAW OF THE
          JURISDICTION IN WHICH YOU RESIDE.
          <br />
          <br />
          2. Disclaimer of Damages and Limitation of Liability
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          AltcoinsHub, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS,
          MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
          REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY
          INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR
          DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
          DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER
          BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT OF AltcoinsHub SERVICES,
          ANY PERFORMANCE OR NON-PERFORMANCE OF AltcoinsHub SERVICES, OR ANY
          OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF
          AltcoinsHub AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE,
          STRICT LIABILITY OR OTHER THEORY EVEN IF AltcoinsHub HAS BEEN ADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL
          JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF
          AltcoinsHub’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR
          INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU.
          <br />
          <br />
          NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF
          AltcoinsHub, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS,
          MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
          REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF SERVICES
          OFFERED BY OR ON BEHALF OF AltcoinsHub AND ITS AFFILIATES, ANY
          PERFORMANCE OR NON-PERFORMANCE OF AltcoinsHub SERVICES, OR ANY OTHER
          PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT, STATUTE,
          STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID
          BY YOU TO AltcoinsHub UNDER THESE TERMS IN THE TWELVE-MONTH PERIOD
          IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR
          LIABILITY.
          <br />
          <br />
          3. Indemnification
          <br />
          <br />
          You agree to indemnify and hold harmless AltcoinsHub Operators, their
          affiliates, contractors, licensors, and their respective directors,
          officers, employees and agents from and against any claims, actions,
          proceedings, investigations, demands, suits, costs, expenses and
          damages (including attorneys’ fees, fines or penalties imposed by any
          regulatory authority) arising out of or related to (i) your use of, or
          conduct in connection with, AltcoinsHub Services, (ii) your breach or
          our enforcement of these Terms, or (iii) your violation of any
          applicable law, regulation, or rights of any third party during your
          use of AltcoinsHub Services. If you are obligated to indemnify
          AltcoinsHub Operators, their affiliates, contractors, licensors, and
          their respective directors, officers, employees or agents pursuant to
          these Terms, AltcoinsHub will have the right, in its sole discretion,
          to control any action or proceeding and to determine whether
          AltcoinsHub wishes to settle, and if so, on what terms.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">IV. Liabilities</h2>
        <br />

        <div>
          <b>1. Disclaimer of Warranties</b>
          <br />
          <br />
          <b>
            TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, AltcoinsHub
            SERVICES, AltcoinsHub MATERIALS AND ANY PRODUCT, SERVICE OR OTHER
            ITEM PROVIDED BY OR ON BEHALF OF AltcoinsHub ARE OFFERED ON AN “AS
            IS” AND “AS AVAILABLE” BASIS, AND AltcoinsHub EXPRESSLY DISCLAIMS,
            AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR
            NON-INFRINGEMENT OR WARRANTIES ARISING FROM COURSE OF PERFORMANCE,
            COURSE OF DEALING OR USAGE IN TRADE. WITHOUT LIMITING THE FOREGOING,
            AltcoinsHub DOES NOT REPRESENT OR WARRANT THAT THE SITE, AltcoinsHub
            SERVICES OR AltcoinsHub MATERIALS ARE ACCURATE, COMPLETE, RELIABLE,
            CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            AltcoinsHub DOES NOT GUARANTEE THAT ANY ORDER WILL BE EXECUTED,
            ACCEPTED, RECORDED OR REMAIN OPEN. EXCEPT FOR THE EXPRESS
            STATEMENTS, AGREEMENTS AND RULES SET FORTH IN THESE TERMS, YOU
            HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER
            STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO
            YOUR USE AND ACCESS OF AltcoinsHub SERVICES. WITHOUT LIMITING THE
            FOREGOING, YOU HEREBY UNDERSTAND AND AGREE THAT AltcoinsHub WILL NOT
            BE LIABLE FOR ANY LOSSES OR DAMAGES ARISING OUT OF OR RELATING TO:
            (A) ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL ASSETS PRICE DATA,
            (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C)
            INTERRUPTION IN ANY SUCH DATA, (D) REGULAR OR UNSCHEDULED
            MAINTENANCE CARRIED OUT BY AltcoinsHub AND SERVICE INTERRUPTION AND
            CHANGE RESULTING FROM SUCH MAINTENANCE, (E) ANY DAMAGES INCURRED BY
            OTHER USERS’ ACTIONS, OMISSIONS OR VIOLATION OF THESE TERMS, (F) ANY
            DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER THIRD PARTIES OR ACTIONS
            WITHOUT AUTHORIZED BY AltcoinsHub; AND (G) OTHER EXEMPTIONS
            MENTIONED IN DISCLAIMERS AND PLATFORM RULES ISSUED BY AltcoinsHub.
          </b>
          <br />
          <br />
          <b>
            THE DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY
            IF AND TO THE EXTENT IT IS PROHIBITED BY APPLICABLE LAW OF THE
            JURISDICTION IN WHICH YOU RESIDE.
          </b>
          <br />
          <br />
          <b>2. Disclaimer of Damages and Limitation of Liability</b>
          <br />
          <br />
          <b>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
            AltcoinsHub, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS,
            MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
            REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY
            INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR
            DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION,
            DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER
            BUSINESSES OR FINANCIAL BENEFITS) ARISING OUT OF AltcoinsHub
            SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF AltcoinsHub
            SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR
            ON BEHALF OF AltcoinsHub AND ITS AFFILIATES, WHETHER UNDER CONTRACT,
            STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF AltcoinsHub HAS
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT
            OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF
            AltcoinsHub’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR
            INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
            THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </b>
          <br />
          <br />
          <b>
            NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF
            AltcoinsHub, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS,
            MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
            REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF SERVICES
            OFFERED BY OR ON BEHALF OF AltcoinsHub AND ITS AFFILIATES, ANY
            PERFORMANCE OR NON-PERFORMANCE OF AltcoinsHub SERVICES, OR ANY OTHER
            PRODUCT, SERVICE OR OTHER ITEM, WHETHER UNDER CONTRACT, STATUTE,
            STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID
            BY YOU TO AltcoinsHub UNDER THESE TERMS IN THE TWELVE-MONTH PERIOD
            IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR
            LIABILITY.
          </b>
          <br />
          <br />
          <b>3. Indemnification</b>
          <br />
          <br />
          You agree to indemnify and hold harmless AltcoinsHub Operators, their
          affiliates, contractors, licensors, and their respective directors,
          officers, employees and agents from and against any claims, actions,
          proceedings, investigations, demands, suits, costs, expenses and
          damages (including attorneys’ fees, fines or penalties imposed by any
          regulatory authority) arising out of or related to (i) your use of, or
          conduct in connection with, AltcoinsHub Services, (ii) your breach or
          our enforcement of these Terms, or (iii) your violation of any
          applicable law, regulation, or rights of any third party during your
          use of AltcoinsHub Services. If you are obligated to indemnify
          AltcoinsHub Operators, their affiliates, contractors, licensors, and
          their respective directors, officers, employees or agents pursuant to
          these Terms, AltcoinsHub will have the right, in its sole discretion,
          to control any action or proceeding and to determine whether
          AltcoinsHub wishes to settle, and if so, on what terms.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">V. Announcements</h2>
        <br />

        <div>
          Please be aware that all official announcements, news, promotions,
          competitions and airdrops will be listed on.{" "}
          <b>
            USERS UNDERTAKE TO REFER TO THESE MATERIALS REGULARLY AND PROMPTLY.
            AltcoinsHub WILL NOT BE HELD LIABLE OR RESPONSIBLE IN ANY MANNER OF
            COMPENSATION SHOULD USERS INCUR PERSONAL LOSSES ARISING FROM
            IGNORANCE OR NEGLIGENCE OF THE ANNOUNCEMENTS.
          </b>
        </div>
      </div>

      <div>
        <h2 className="blockTitle">VI. Termination of Agreement</h2>
        <br />

        <div>
          <b>1. Suspension of AltcoinsHub Accounts</b>
          <br />
          <br />
          You agree that AltcoinsHub shall have the right to immediately suspend
          your AltcoinsHub Account (and any accounts beneficially owned by
          related entities or affiliates), freeze or lock the Digital Assets or
          funds in all such accounts, and suspend your access to AltcoinsHub for
          any reason including if AltcoinsHub suspects any such accounts to be
          in violation of these Terms, our Privacy Policy, or any applicable
          laws and regulations. You agree that AltcoinsHub shall not be liable
          to you for any permanent or temporary modification of your AltcoinsHub
          Account, or suspension or termination of your access to all or any
          portion of AltcoinsHub Services. AltcoinsHub shall reserve the right
          to keep and use the transaction data or other information related to
          such AltcoinsHub Accounts. The above account controls may also be
          applied in the following cases:
          <br />
          <br />
          ● The AltcoinsHub Account is subject to a governmental proceeding,
          criminal investigation or other pending litigation;
          <br />
          ● We detect unusual activities in the AltcoinsHub Account;
          <br />
          ● We detect unauthorized access to the AltcoinsHub Account;
          <br />
          ● We are required to do so by a court order or command by a
          regulatory/government authority.
          <br />
          <br />
          <b>2. Cancellation of AltcoinsHub Accounts</b>
          <br />
          <br />
          In case of any of the following events, AltcoinsHub shall have the
          right to directly terminate these Terms by cancelling your AltcoinsHub
          Account, and shall enjoy the right but not the obligation to
          permanently freeze (cancel) the authorizations of your AltcoinsHub
          Account on AltcoinsHub and withdraw the corresponding AltcoinsHub
          Account thereof:
          <br />
          <br />
          ● after AltcoinsHub terminates services to you;
          <br />
          ● you allegedly register or register in any other person’s name as a
          AltcoinsHub User again, directly or indirectly;
          <br />
          ● the information that you have provided is untruthful, inaccurate,
          outdated or incomplete;
          <br />
          ● when these Terms are amended, you state your unwillingness to accept
          the amended Terms by applying for cancellation of your AltcoinsHub
          Account or by other means;
          <br />
          ● you request that AltcoinsHub Services be terminated; and
          <br />
          ● any other circumstances where AltcoinsHub deems it should terminate
          AltcoinsHub Services.
          <br />
          <br />
          Should your AltcoinsHub Account be terminated, the account and
          transactional information that meet data retention standards will be
          securely stored for 5 years. In addition, if a transaction is
          unfinished during the account termination process, AltcoinsHub shall
          have the right to notify your counterparty of the situation at that
          time. You acknowledge that a user-initiated account exit (right to
          erasure under GDPR or other equivalent regulations) will also be
          subjected to the termination protocol stated above.
          <br />
          <br />
          If AltcoinsHub is informed that any Digital Assets or funds held in
          your AltcoinsHub Account are stolen or otherwise are not lawfully
          possessed by you, AltcoinsHub may, but has no obligation to, place an
          administrative hold on the affected funds and your AltcoinsHub
          Account. If AltcoinsHub does lay down an administrative hold on some
          or all of your funds or AltcoinsHub Account, AltcoinsHub may continue
          such hold until such time as the dispute has been resolved and
          evidence of the resolution acceptable to AltcoinsHub has been provided
          to AltcoinsHub in a form acceptable to AltcoinsHub. AltcoinsHub will
          not involve itself in any such dispute or the resolution of the
          dispute. You agree that AltcoinsHub will have no liability or
          responsibility for any such hold, or for your inability to withdraw
          Digital Assets or funds or execute trades during the period of any
          such hold.
          <br />
          <br />
          <b>3. Remaining Funds After AltcoinsHub Account Termination</b>
          <br />
          <br />
          Except as set forth in paragraph 4 below, once a AltcoinsHub Account
          is closed/withdrawn, all remaining account balance (which includes
          charges and liabilities owed to AltcoinsHub) will be payable
          immediately to AltcoinsHub. Upon payment of all outstanding charges to
          AltcoinsHub (if any), Users will have 5 business days to withdraw all
          Digital Assets or funds from the account.
          <br />
          <br />
          <b>
            4. Remaining Funds After AltcoinsHub Account Termination Due to
            Fraud, Violation of Law, or Violation of These Terms
          </b>
          <br />
          <br />
          AltcoinsHub maintains full custody of the Digital Assets, funds and
          User data/information which may be turned over to governmental
          authorities in the event of AltcoinsHub Accounts’ suspension/closure
          arising from fraud investigations, investigations of violation of law
          or violation of these Terms.
          <br />
          <br />
          <b>5. Dormant Accounts</b>
          <br />
          <br />
          Notwithstanding any provision of this Section VI, AltcoinsHub may
          provide a written notice requiring you to close all of your open
          positions and withdraw all of your Digital Assets from your
          AltcoinsHub Account within 30 days of the notice. In the event that
          you fail to do so, AltcoinsHub may in its absolute discretion and
          without prior notice to you:
          <br />
          <br />
          (a) deem your AltcoinsHub account as a dormant account;
          <br />
          <br />
          (b) close any open positions in any AltcoinsHub products;
          <br />
          <br />
          (c) convert the Digital Assets to a different type of Digital Asset
          (e.g., from BTC to BUSD). For the avoidance of doubt, none of the
          AltcoinsHub Operators shall be liable for any loss of profit, tax
          obligations or any other loss, damage or expense incurred by you
          resulting from such conversion;
          <br />
          <br />
          (d) transfer such dormant account (including any Digital Assets
          contained therein) to an affiliate of the AltcoinsHub Operators, any
          third-party custodian or an isolated wallet where it is deemed
          reasonably necessary by AltcoinsHub to do so. In the event that such
          transfer has taken place, you have the right to retrieve your digital
          assets from subject to satisfying AltcoinsHub’s verification
          requirements, including completing KYC;
          <br />
          <br />
          (e) charge a dormant account fee to cover the cost of maintaining the
          assets by the AltcoinsHub Operators, its affiliates or any third-party
          and such fee shall be withdrawn directly from the dormant account on a
          monthly basis; and
          <br />
          <br />
          (f) close a dormant account at any time, and AltcoinsHub will not be
          liable for any loss, damage or expense incurred by you as a result of
          the closure of a dormant account unless there was fraud or willful
          default by AltcoinsHub. Any assets in these dormant accounts will be
          transferred in accordance to paragraph 5(d) above. After a dormant
          account is closed, it cannot be reactivated by you (i.e. you will need
          to register a new AltcoinsHub account if you wish to continue to use
          AltcoinsHub Services).
        </div>
      </div>

      <div>
        <h2 className="blockTitle">VII. No Financial Advice</h2>
        <br />

        <div>
          AltcoinsHub is not your broker, intermediary, agent, or advisor and
          has no fiduciary relationship or obligation to you in connection with
          any trades or other decisions or activities effected by you using
          AltcoinsHub Services. No communication or information provided to you
          by AltcoinsHub is intended as, or shall be considered or construed as,
          investment advice, financial advice, trading advice, or any other sort
          of advice. Unless otherwise specified in these Terms, all trades are
          executed automatically, based on the parameters of your order
          instructions and in accordance with posted trade execution procedures,
          and you are solely responsible for determining whether any investment,
          investment strategy or related transaction is appropriate for you
          according to your personal investment objectives, financial
          circumstances and risk tolerance, and you shall be solely responsible
          for any loss or liability therefrom. You should consult legal or tax
          professionals regarding your specific situation. AltcoinsHub does not
          recommend that any Digital Asset should be bought, earned, sold, or
          held by you. Before making the decision to buy, sell or hold any
          Digital Asset, you should conduct your own due diligence and consult
          your financial advisors prior to making any investment decision.
          AltcoinsHub will not be held responsible for the decisions you make to
          buy, sell, or hold Digital Asset based on the information provided by
          AltcoinsHub.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">VIII. Compliance with Local Laws</h2>
        <br />

        <div>
          It is Users’ responsibility to abide by local laws in relation to the
          legal usage of AltcoinsHub Services in their local jurisdiction as
          well as other laws and regulations applicable to Users. Users must
          also factor, to the extent of their local laws all aspects of
          taxation, the withholding, collection, reporting and remittance to
          their appropriate tax authorities.{" "}
          <b>
            ALL USERS OF AltcoinsHub SERVICES ACKNOWLEDGE AND DECLARE THAT THEIR
            FUNDS COME FROM LEGITIMATE SOURCES AND DO NOT ORIGINATE FROM ILLEGAL
            ACTIVITIES; USERS AGREE THAT AltcoinsHub WILL REQUIRE THEM TO
            PROVIDE OR OTHERWISE COLLECT THE NECESSARY INFORMATION AND MATERIALS
            AS PER RELEVANT LAWS OR GOVERNMENT ORDERS TO VERIFY THE LEGALITY OF
            THE SOURCES AND USE OF THEIR FUNDS.
          </b>{" "}
          AltcoinsHub maintains a stance of cooperation with law enforcement
          authorities globally and will not hesitate to seize, freeze, terminate
          Users’ accounts and funds which are flagged out or investigated by
          legal mandate.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">IX. Privacy Policy</h2>
        <br />

        <div>
          Access to AltcoinsHub Services will require the submission of certain
          personally identifiable information. Please review AltcoinsHub’s
          Privacy Policy at for a summary of AltcoinsHub’s guidelines regarding
          the collection and use of personally identifiable information.
        </div>
      </div>

      <div>
        <h2 className="blockTitle">
          X. Resolving Disputes: Forum, Arbitration, Class Action Waiver
        </h2>
        <br />

        <div>
          PLEASE READ THIS SECTION CAREFULLY, AS IT INVOLVES A WAIVER OF CERTAIN
          RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING AS A CLASS ACTION.
          <br />
          <br />
          <b>1. Notice of Claim and Dispute Resolution Period.</b> Please
          contact AltcoinsHub first! AltcoinsHub wants to address your concerns
          without resorting to formal legal proceedings, if possible. If you
          have a dispute with AltcoinsHub, then you should contact AltcoinsHub
          and a ticket number will be assigned. AltcoinsHub will attempt to
          resolve your dispute internally as soon as possible. The parties agree
          to negotiate in good faith to resolve the dispute (which discussions
          shall remain confidential and be subject to applicable rules
          protecting settlement discussions from use as evidence in any legal
          proceeding).
          <br />
          <br />
          In the event the dispute cannot be resolved satisfactorily, and you
          wish to assert a legal claim against AltcoinsHub, then you agree to
          set forth the basis of such claim in writing in a “Notice of Claim,”
          as a form of prior notice to AltcoinsHub. The Notice of Claim must (1)
          describe the nature and basis of the claim or dispute, (2) set forth
          the specific relief sought, (3) provide the original ticket number,
          and (4) include your AltcoinsHub account email. The Notice of Claim
          should be submitted to an email address or hyperlink provided in your
          correspondence with AltcoinsHub. After you have provided the Notice of
          Claim to AltcoinsHub, the dispute referenced in the Notice of Claim
          may be submitted by either AltcoinsHub or you to arbitration in
          accordance with paragraph 2 of this Section, below. For the avoidance
          of doubt, the submission of a dispute to AltcoinsHub for resolution
          internally and the delivery of a Notice of Claim to AltcoinsHub are
          prerequisites to commencement of an arbitration proceeding (or any
          other legal proceeding). During the arbitration, the amount of any
          settlement offer made by you or AltcoinsHub shall not be disclosed to
          the arbitrator.
          <br />
          <br />
          <b>2. Agreement to Arbitrate and Governing Law.</b> You and
          AltcoinsHub Operators agree that, subject to paragraph 1 above, any
          dispute, claim, or controversy between you and AltcoinsHub (and/or
          AltcoinsHub Operators) arising in connection with or relating in any
          way to these Terms or to your relationship with AltcoinsHub (and/or
          AltcoinsHub Operators) as a user of AltcoinsHub Services (whether
          based in contract, tort, statute, fraud, misrepresentation, or any
          other legal theory, and whether the claims arise during or after the
          termination of these Terms) will be determined by mandatory final and
          binding individual (not class) arbitration, except as set forth below
          under Exceptions to Agreement to Arbitrate. You and AltcoinsHub
          Operators further agree that the arbitrator shall have the exclusive
          power to rule on his or her own jurisdiction, including without
          limitation any objections with respect to the existence, scope or
          validity of the Agreement to Arbitrate, or to the arbitrability of any
          claim or counterclaim. Arbitration is more informal than a lawsuit in
          court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF
          AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery
          than in court. The arbitrator must follow this agreement and can award
          the same damages and relief as a court (including, if applicable,
          attorney fees), except that the arbitrator may not award declaratory
          or injunctive relief in favour of anyone but the parties to the
          arbitration. The arbitration provisions set forth in this Section will
          survive termination of these Terms. Arbitration Rules. The arbitration
          shall be subject to the HKIAC Administered Arbitration Rules (HKIAC.
          Rules) in force when the Notice of Arbitration is submitted, as
          modified by this Section X. The arbitration will be administered by
          the Hong Kong International Arbitration Centre (HKIAC). Unless the
          parties agree otherwise, there shall be only one arbitrator appointed
          in accordance with the HKIAC Rules. Any arbitration will be conducted
          in the English language. Regardless of the manner in which the
          arbitration is conducted, the arbitrator shall issue a reasoned
          written decision sufficient to explain the essential findings and
          conclusions on which the decision and award, if any, are based.
          JUDGMENT ON ANY ARBITRAL AWARD MAY BE GIVEN IN ANY COURT HAVING
          JURISDICTION OVER THE PARTY (OR OVER THE ASSETS OF THE PARTY) AGAINST
          WHOM SUCH AN AWARD IS RENDERED. Time for Filing: ANY ARBITRATION
          AGAINST AltcoinsHub OPERATORS MUST BE COMMENCED BY FILING A REQUEST
          FOR ARBITRATION WITHIN ONE (1) YEAR, AFTER THE DATE THE PARTY
          ASSERTING THE CLAIM FIRST KNOWS OR REASONABLY SHOULD KNOW OF THE ACT,
          OMISSION OR DEFAULT GIVING RISE TO THE CLAIM; AND THERE SHALL BE NO
          RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME
          PERIOD. THIS ONE YEAR LIMITATION PERIOD IS INCLUSIVE OF THE INTERNAL
          DISPUTE RESOLUTION PROCEDURE SET FORTH IN PARAGRAPH 1 OF THIS SECTION,
          ABOVE. THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT
          ASSERTED WITHIN THAT TIME PERIOD. If applicable law prohibits a
          one-year limitation period for asserting claims, any claim must be
          asserted within the shortest time period permitted by applicable law.
          Process; Notice: The party who intends to seek arbitration after the
          expiration of the Dispute Resolution Period set forth in paragraph 1,
          above, must submit a request to the HKIAC in accordance with the HKIAC
          Rules. If we request arbitration against you, we will give you notice
          at the email address or mailing address you have provided. You agree
          that any notice sent to this email or mailing address shall be deemed
          effective for all purposes, including without limitation to
          determinations of adequacy of service. It is your obligation to ensure
          that the email address and/or mailing address on file with AltcoinsHub
          is up-to-date and accurate. Seat of Arbitration: The seat of the
          arbitration shall be Hong Kong. Place of Hearing: The location of any
          in-person arbitration hearing shall be Hong Kong, unless otherwise
          agreed to by the parties. Governing Law: These Terms (including this
          arbitration agreement) shall be governed by, and construed in
          accordance with, the laws of Hong Kong. Confidentiality. The parties
          agree that the arbitration shall be kept confidential. The existence
          of the arbitration, any nonpublic information provided in the
          arbitration, and any submissions, orders or awards made in the
          arbitration (together, the “Confidential Information”) shall not be
          disclosed to any non-party except the tribunal, the HKIAC, the
          parties, their counsel, experts, witnesses, accountants and auditors,
          insurers and reinsurers, and any other person necessary to the conduct
          of the arbitration. Notwithstanding the foregoing, a party may
          disclose Confidential Information to the extent that disclosure may be
          required to fulfill a legal duty, protect or pursue a legal right, or
          enforce or challenge an award in bona fide legal proceedings. This
          confidentiality provision shall survive termination of these Terms and
          of any arbitration brought pursuant to these Terms.
          <br />
          <br />
          <b>3. Class Action Waiver.</b> You and AltcoinsHub agree that any
          claims relating to these Terms or to your relationship with
          AltcoinsHub as a user of AltcoinsHub Services (whether based in
          contract, tort, statute, fraud, misrepresentation, or any other legal
          theory, and whether the claims arise during or after the termination
          of these Terms) shall be brought against the other party in an
          arbitration on an individual basis only and not as a plaintiff or
          class member in a purported class or representative action. You and
          AltcoinsHub further agree to waive any right for such claims to be
          brought, heard, or arbitrated as a class, collective, representative,
          or private attorney general action, to the extent permissible by
          applicable law. Combining or consolidating individual arbitrations
          into a single arbitration is not permitted without the consent of all
          parties, including AltcoinsHub.
          <br />
          <br />
          <b>4. Modifications.</b> AltcoinsHub reserves the right to update,
          modify, revise, suspend, or make any future changes to Section X
          regarding the parties’ Agreement to Arbitrate, subject to applicable
          law. You hereby consent and agree that it is your responsibility to
          ensure that your understanding of this Section is up to date. Subject
          to the applicable law, your continued use of your AltcoinsHub account
          shall be deemed to be your acceptance of any modifications to Section
          X regarding the parties’ Agreement to Arbitrate. You agree that if you
          object to the modifications to Section X, AltcoinsHub may block access
          to your account pending closure of your account. In such
          circumstances, the Terms of Use prior to modification shall remain in
          full force and effect pending closure of your account.
          <br />
          <br />
          <b>5. Severability.</b> If any portion of these Terms are adjudged to
          be invalid or unenforceable for any reason or to any extent, the
          remainder of these Terms will remain valid and enforceable and the
          invalid or unenforceable portion will be given effect to the greatest
          extent permitted by law. pending closure of your account.
          <br />
          <br />
        </div>
      </div>

      <div>
        <h2 className="blockTitle">XI. Miscellaneous</h2>
        <br />

        <div>
          <b>1. Independent Parties.</b> AltcoinsHub is an independent
          contractor but not an agent of you in the performance of these Terms.
          These Terms shall not be interpreted as facts or evidence of an
          association, joint venture, partnership, or franchise between the
          parties.
          <br />
          <br />
          <b>2. Entire Agreement.</b> These Terms constitute the entire
          agreement between the parties regarding use of AltcoinsHub Services
          and will supersede all prior written or oral agreements between the
          parties. No usage of trade or other regular practice or method of
          dealing between the parties will be used to modify, interpret,
          supplement, or alter the terms herein.
          <br />
          <br />
          <b>3. Interpretation and Revision.</b> AltcoinsHub reserves the right
          to alter, revise, modify, and/or change these Terms at any time. All
          changes will take effect immediately upon being published on
          AltcoinsHub websites. It is your responsibility to regularly check
          relevant pages on our websites/applications to confirm the latest
          version of these Terms. If you do not agree to any such modifications,
          your only remedy is to terminate your usage of AltcoinsHub Services
          and cancel your account. You agree that, unless otherwise expressly
          provided in these Terms, AltcoinsHub will not be responsible for any
          modification or termination of AltcoinsHub Services by you or any
          third party, or suspension or termination of your access to
          AltcoinsHub Services.
          <br />
          <br />
          <b>4. Language & Translations:</b> These Terms may, at AltcoinsHub’s
          sole and absolute discretion, be translated into a language other than
          the English language. You agree that any such translation shall only
          be for your convenience and the English text shall prevail in the
          event of any ambiguity, discrepancy or omission as between the English
          text and any translated text.
          <br />
          <br />
          <b>5. Force Majeure.</b> AltcoinsHub will not be liable for any delay
          or failure to perform as required by these Terms because of any cause
          or condition beyond AltcoinsHub’s reasonable control.
          <br />
          <br />
          <b>6. Severability.</b> If any portion of these Terms is held invalid
          or unenforceable, such invalidity or enforceability will not affect
          the other provisions of these Terms, which will remain in full force
          and effect, and the invalid or unenforceable portion will be given
          effect to the greatest extent possible.
          <br />
          <br />
          <b>7. Assignment.</b> You may not assign or transfer any right to use
          AltcoinsHub Services or any of your rights or obligations under these
          Terms without prior written consent from AltcoinsHub, including any
          right or obligation related to the enforcement of laws or the change
          of control. AltcoinsHub may assign or transfer any or all of its
          rights or obligations under these Terms, in whole or in part, without
          notice or obtaining your consent or approval.
          <br />
          <br />
          <b>8. Waiver.</b> The failure of one party to require performance of
          any provision will not affect that party’s right to require
          performance at any time thereafter. At the same time, the waiver of
          one party to seek recovery for the other party’s violation of these
          Terms or any provision of applicable terms shall not constitute a
          waiver by that party of any subsequent breach or violation by the
          other party or of the provision itself.
          <br />
          <br />
          <b>9. Third-Party Website Disclaimer.</b> Any links to third-party
          websites from AltcoinsHub Services does not imply endorsement by
          AltcoinsHub of any product, service, information or disclaimer
          presented therein, nor does AltcoinsHub guarantee the accuracy of the
          information contained on them. If you suffer loss from using such
          third-party product and service, AltcoinsHub will not be liable for
          such loss. In addition, since AltcoinsHub has no control over the
          terms of use or privacy policies of third-party websites, you should
          read and understand those policies carefully.
          <br />
          <br />
          <b>10. Matters Related to Apple Inc.</b> If you use any device
          manufactured by Apple Inc. to participate in any commercial activities
          or reward programs through AltcoinsHub Services, such activities and
          programs are provided by AltcoinsHub and are not associated with Apple
          Inc. in any manner.
          <br />
          <br />
          <b>11. Contact Information.</b> For more information on AltcoinsHub,
          you may refer to the company and license information found on
          AltcoinsHub websites. If you have questions regarding these Terms,
          please feel free to contact AltcoinsHub for clarification via our
          Customer Support team at.
        </div>
      </div>
    </div>
  </div>
);

export default TermsAndConditions;
